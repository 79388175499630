import axios from "axios";


export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";


export async function login(username, password) {
  const data = {
    username: username,
    password: password,
  };

  const app_version = "1.0.0";
  let loginResponse = {
    status: false,
    message: "",
    isLoading: true,
    access_token: "",
    userData: null,
    shipUser: null,
    moduleLists: [],
    data: null,
  };

  //const url = `${process.env.REACT_APP_API_PUBLIC_URL}api/OAuth/UserLoginWeb`;
  const url = `${process.env.REACT_APP_API_PUBLIC_URL}api/OAuth/IMSLogin`;

  try {
    const res = await axios.post(url, data);
    const { status, data: responseData, message, code, info } = res.data;

    if (code === 200) {
      loginResponse.access_token = responseData.token;
      loginResponse.message = message;
      loginResponse.status = true;
      loginResponse.userData = info;

      localStorage.setItem('access_token', responseData.token);
      localStorage.setItem('userData', JSON.stringify(info));
    } else {

      loginResponse.message = message || "Invalid Username or Password";
      loginResponse.status = false;
    }
  } catch (error) {
    console.error('Login failed:', error);

   
    if (error.response) {
      loginResponse.message = `Server Error: ${error.response.status} - ${error.response.statusText}`;
    } else if (error.request) {
      loginResponse.message = "No response received from the server.";
    } else if (error.code === 'ECONNABORTED') {
      loginResponse.message = "The request timed out. Please try again later.";
    } else if (!navigator.onLine) {
      loginResponse.message = "You are offline. Please check your internet connection and try again.";
    } else {
      loginResponse.message = "An error occurred while trying to log in. Please try again later.";
    }

    loginResponse.status = false;
  }
  loginResponse.isLoading = false;

  return loginResponse;
}


export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function getEmployeData() {
  // Authorization head should be fulfilled in interceptor.
  let userData = localStorage.getItem("userData");
  let employeeData = JSON.parse(userData);
  return employeeData;
}

export function getVesselId() {
  let data = getEmployeData();
  let intVesselId = null;
  if (typeof data !== "undefined" && data != null) {
    intVesselId = data.intVesselId;
  }
  if (typeof intVesselId !== "undefined" && intVesselId != null)
    return intVesselId;
  return "";
}

export function getCargoId() {
  let data = getEmployeData();
  let intCargoId = null;
  if (typeof data !== "undefined" && data != null) {
    intCargoId = data.intCargoId;
  }
  if (typeof intCargoId !== "undefined" && intCargoId != null)
    return intCargoId;
  return "";
}

export function getEmployeeId() {
  let data = getEmployeData();
  if (typeof data !== "undefined" && data != null) return data.intEmployeeId;
}
