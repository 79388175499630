import * as Types from "../../../types/SecondarySalesTypes";

const initialState = {
    secondaryApprovedList: [],
    asmSecondaryList: [],
    asmapprovedDetails: [],
    secondaryApprovedDetails: [],
    submitData: [],
    cumalative: [],
    totalQuantityCum: 0,
    totalPriceCum: 0,
    biscuteQty: 0,
    biscutePrice: 0,
    cakeQty: 0,
    cakePrice: 0,
    cookisQty: 0,
    cookisPrice: 0,
    dateWiseReport: [],
    dateSys: [],
    areaInfo: [],
    distrinfo: [],
    soInfo: [],
    dateCount: 0,
    breadBurnPivot:[],
    distributorPivot:[],
    primaryDemandSku:[],
    totalDQty:null,
    totalValue : null,
    MonthlybreadBurnPivot: [],
    MonthlybreadBurnPivotLoading: false
    
};
const BreadBurnReportReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        
        case Types.GET_BRAED_BURN_CUMALATIVE:
       
            let sumSoPrice = 0;
            let soquantity = 0;
            let sobiscuteQty = 0;
            let sobiscutePrice = 0;
            let socakeQty = 0;
            let socakePrice = 0;
            let socookisQty = 0;
            let socookisPrice = 0;
            for (let i = 0; i < action.payload.data.length; i++) {
                soquantity += action.payload.data[i].totalQuantity;
                sumSoPrice += action.payload.data[i].totalPrice;
                sobiscuteQty += action.payload.data[i].biscuteQty;
                sobiscutePrice += action.payload.data[i].biscutePrice;
                socakeQty += action.payload.data[i].cakeQty;
                socakePrice += action.payload.data[i].cakePrice;
                socookisQty += action.payload.data[i].cookisQty;
                socookisPrice += action.payload.data[i].cookisPrice;
            }
            return {
                ...state,
                cumalative: action.payload.data,
                totalQuantityCum: soquantity,
                totalPriceCum: sumSoPrice,
                biscuteQty: sobiscuteQty,
                biscutePrice: sobiscutePrice,
                cakeQty: socakeQty,
                cakePrice: socakePrice,
                cookisQty: socookisQty,
                cookisPrice: socookisPrice,
            };

        case Types.GET_SALES_CLEAN:
            return {
                ...state,
                cumalative: [],
                totalQuantityCum: 0,
                totalPriceCum: 0,
                biscuteQty: 0,
                biscutePrice: 0,
                cakeQty: 0,
                cakePrice: 0,
                cookisQty: 0,
                cookisPrice: 0,

            };
        case Types.GET_BREDBURN_PIVOT_REPORT:
            return {
                ...state,
                breadBurnPivot:action.payload.data
                

            };
        case Types.GET_BREDBURN_PIVOT_REPORT_MONTHLY:
            return {
                ...state,
                MonthlybreadBurnPivot:action.payload.data,
                MonthlybreadBurnPivotLoading: action.payload.isLoading,
                

            };
        case Types.GET_DISTRIBUTOR_STOCK_PIVOT:
            
            return {
                ...state,
                distributorPivot:action.payload.data
            };
        case Types.GET_PRIMARY_DEMAND_SKU_WISE:
            return {
                ...state,
                primaryDemandSku:action.payload.data
            };
        case Types.GET_PRIMARY_DEMAND_CUM:
            return {
                ...state,
                primaryDemandSku:action.payload.data?.masterReports,
                totalDQty:action.payload.data.totalQty,
                totalValue:action.payload.data.totalValue
            };
        default:
            break;
    }
    return newState;
};

export default BreadBurnReportReducer;
