import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  EditDateGet,
  getCustomerBalance,
  getSalesList,
  handleDemandApprovedAll,
  handleDemandSingleApprove,
  salesDelete,
  SalesOrderReportWithBalance,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import RemoteSalesDetails from "./RemoteSalesDetails";
import { InputHandle } from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";

import {
  getAreaListByDivision,
  getAreaListByDivisionEntry,
  getDivisionListByZone,
  getDivisionListByZoneEntry,
  getTerritory,
  getTerritoryEntry,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import SimpleSalesType from "../SalesType/SimpleSalesType";
import { GetImsTrackingSystem } from "../../_redux/actions/SecondarySales/SecondaryAction";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { FaArrowRight, FaSave, FaSearch } from "react-icons/fa";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import RemoteSalesEditWithBalance from "./RemoteSalesEditWithBalance";

const RemoteSalesListWithBalance = () => {

  const history = useHistory();
  const isLoading = useSelector((state) => state.remoteSales.demandlistWithBalanceLoading);
  const demandlist = useSelector((state) => state.remoteSales.demandlistWithBalance);
  console.log('demandlist', demandlist)

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  console.log('selectZone', selectZone)

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);


  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {

    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    initialData();
  }, []);



  const initialData = async () => {
    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);

  };

  const confirmDelete = (id) => {
    console.log('id', id);
    dispatch(salesDelete(id, selectArea));
    dispatch(GetImsTrackingSystem("remoteSales/list", "delete", "mac", id));

  };



  const handleDeleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };



  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [selectGeoType, setSelectGeoType] = useState(2);


  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };



  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);

  const [itemDetailShow1, setItemDetailShow1] = useState(false);
  const [itemID1, setItemID1] = useState(null);
  const [itemifo1, setItemifo1] = useState(null);



  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };


  const itemDetailEdit = (item) => {
    setItemifo1(item);
    setItemID1(item.orderId);
    setItemDetailShow1(true);
  };




  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const gotoEditPage = (item) => {
    dispatch(EditDateGet(item));
    const url = `/remoteSales/edit-balance/${item.orderId}/${item.cusName}/${item.cusId}/${selectZone ?? 0}/${selectDivision ?? 0}/${selectArea ?? 0}/${selectTerritory ?? 0}`;
    window.open(url, '_blank');
  };

  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  console.log('selectedRows', selectedRows)

  const handleCheckboxChange = (dmg) => {
    const updatedSelectedRows = selectedRows.some((selectedDmg) => selectedDmg.orderId === dmg.orderId)
      ? selectedRows.filter((selectedDmg) => selectedDmg.orderId !== dmg.orderId)
      : [...selectedRows, { orderId: dmg.orderId }];
    setSelectedRows(updatedSelectedRows);
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    const allOrderIds = demandlist.filter(item => (item?.netBalance - item.totalAmount) >= 0).map((dmg) => ({ orderId: dmg.orderId }));
    setSelectedRows(selectAll ? [] : allOrderIds);
  };

  const ItemSingleApproved = (item) => {
    confirmAlert({
      title: "Confirm To Approved",
      // message: `Are you sure to Confirm,Pleaes Confirm Your ALL SO Sales..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => approvedReport(item)
        },
        {
          label: "No",
        },
      ],
    });
  }

  const approvedReport = (item) => {
    dispatch(handleDemandSingleApprove(item, selectZone,
      selectDivision,
      selectArea,
      selectTerritory));
  }
  const approvedReportAll = () => {
    dispatch(handleDemandApprovedAll(
      selectedRows,
      selectZone,
      selectDivision,
      selectArea,
      selectTerritory
    ))
  }

  const handleClick = () => {
    window.open('/demand/approved-list', '_blank');
  };


  const ItemAllApproved = (item) => {
    confirmAlert({
      title: "Confirm To Approved Selected",
  
      buttons: [
        {
          label: "Yes",


          onClick: () => approvedReportAll(item)
        },
        {
          label: "No",
        },
      ],
    });
  }




  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">

        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Sales Demand Approve</h3>
            </div>
          </div>

          <SimpleSalesType
            distribType={distribType}
            salesType={salesType}
            getSalesValue={getSalesValueType}
          />
        </div>

        <hr></hr>

        <div className="form-group row p-4">
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={zoneDropdownData()}
                value={defultZone}
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setDefaultZone(option);
                  setdefultDivision(null);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                defaultValue={defultZone}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={divisionDropdownData()}
                value={defultDivision}
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setdefultDivision(option);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                defaultValue={defultDivision}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={areaDropdownData()}
                value={defaultArea}
                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setdefaultArea(option);
                  setdefultTerritory(null);
                  setValue("territory", "");
                }}
                defaultValue={defaultArea}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                  setdefultTerritory(option)


                }}
                defaultValue={defultTerritory}
              />
            </Form.Group>
          </div>

          <div className="col-lg-12 mt-3 ml-5 mb-2">
            <Button size="sm" onClick={() =>
              dispatch(SalesOrderReportWithBalance(
                selectZone,
                selectDivision,
                selectArea,
                selectTerritory
              ))
            }>
              <FaSearch /> Show Report
            </Button>


            <Button size="sm" className="ml-1 bg-info"
              onClick={() => ItemAllApproved()}

            >
              <FaSave /> Approved All {'->'} {selectedRows?.length ?? 0}
            </Button>

            <Button
              className="ml-6 bg-secondary border-0 d-inline"
              size="sm"
              onClick={handleClick}
            >
              Go To Approved Report <FaArrowRight />
            </Button>


          </div>




        </div>

        <div className="row" id="id">
          {isLoading && <LoadingSpinner text="Loading Items List..." />}

          <div className="table-responsive pl-5 pr-5 pb-5">
            {/* Check if demandlist is an array */}
            {Array.isArray(demandlist) && demandlist.length > 0 && (
              <>

                <table className="printtablenew1 ">
                  {demandlist.filter(item => (item?.netBalance - item.totalAmount) < 0).length > 0 && (
                    <thead>
                      <tr>
                        <th></th>
                        <th scope="col">SL</th>
                        <th scope="col">ORDER CODE</th>
                        <th scope="col">CUSTOMER ID</th>
                        <th scope="col">CUSTOMER NAME</th>
                        <th scope="col" className="text-center">AVAILABLE NET BALANCE</th>
                        <th scope="col" className="text-center">SALES TYPE</th>
                        <th scope="col" className="text-center">QUANTITY</th>
                        <th scope="col" className="text-center">TOTAL VALUE</th>
                        <th scope="col" className="text-center">BALANCE STATUS</th>
                        <th style={{ width: '150px' }} className="text-center" scope="col">ACTION</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    <tr className="backcolor1">
                      <th></th>
                      <th colSpan="10" className="text-center" style={{ color: 'black', backgroundColor: '#00C2C4' }}> NEGATIVE BALANCE </th>
                    </tr>
                    {demandlist.filter(item => (item?.netBalance - item.totalAmount) < 0).map((item, index) => (
                      <tr key={index}>
                        <td></td>
                        <td>{index + 1}</td>
                        <td>{item.orderCode}</td>
                        <td>{item.cusId}</td>
                        <td>{item.cusName}</td>
                        <td className="text-right">{numberFormatDecemal(item?.netBalance)}</td>
                        <td className="text-center">{item.salesType === 'D' ? 'Damage' : 'General'}</td>
                        <td className="text-center">{numberFormatDecemal(item.totalQuantity)}</td>
                        <td className="text-right">{numberFormatDecemal(item.totalAmount)}</td>
                        <td className="text-right">
                          <span style={{
                            color: ((item?.netBalance) - (item.totalAmount)) < 0 ? '#FF0000' : '#2D892D',
                          }}>
                            {numberFormatDecemal(parseFloat(item?.netBalance - item.totalAmount).toFixed(2))}
                          </span>
                        </td>
                        <td style={{ width: '150px' }} className="text-center">
                          <Link onClick={() => itemDetail(item)} title="Details">
                            <i className="far fa-eye editIcon item-list-icon"></i>
                          </Link>

                          <Link onClick={() => itemDetailEdit(item)} title="Edit">
                            <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                          </Link>



                          <a href="#" onClick={() => handleDeleteItem(item.orderId)} title="Delete">
                            <i className="fas fa-trash-alt editIcon item-list-icon ml-4"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                    <tr className="backcolor1">
                      <th>
                        <input
                          type="checkbox"
                          onChange={handleSelectAllChange}
                          checked={selectAll}
                        />
                      </th>
                      <th colSpan="10" className="text-center" style={{ color: 'black', backgroundColor: '#00C2C4' }}> POSITIVE BALANCE </th>
                    </tr>

                    {demandlist.filter(item => (item?.netBalance - item.totalAmount) >= 0).map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange(item)}
                            checked={selectedRows.some((dmg) => dmg.orderId === item.orderId)}
                          />
                        </td>
                        <td>{index + 1}</td>
                        <td>{item.orderCode}</td>
                        <td>{item.cusId}</td>
                        <td>{item.cusName}</td>
                        <td className="text-right">{numberFormatDecemal(item?.netBalance)}</td>
                        <td className="text-center">{item.salesType === 'D' ? 'Damage' : 'General'}</td>
                        <td className="text-center">{numberFormatDecemal(item.totalQuantity)}</td>
                        <td className="text-right">{numberFormatDecemal(item.totalAmount)}</td>
                        <td className="text-right">
                          <span style={{
                            color: ((item?.netBalance) - (item.totalAmount)) < 0 ? '#FF0000' : '#2D892D',
                          }}>
                            {numberFormatDecemal(parseFloat(item?.netBalance - item.totalAmount).toFixed(2))}
                          </span>
                        </td>
                        <td style={{ width: '150px' }} className="text-center">
                          <Link onClick={() => ItemSingleApproved(item)} title="Approved">
                            <i className="fa fa-check editIcon item-list-icon"></i>
                          </Link>
                          <Link onClick={() => itemDetail(item)} title="Details">
                            <i className="far fa-eye editIcon item-list-icon"></i>
                          </Link>

                          <Link onClick={() => itemDetailEdit(item)} title="Edit">
                            <i className="far fa-edit editIcon item-list-icon ml-4"></i>
                          </Link>

                          <a href="#" onClick={() => handleDeleteItem(item.orderId)} title="Delete">
                            <i className="fas fa-trash-alt editIcon item-list-icon ml-4"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>


              </>
            )}

          </div>
        </div>



        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <RemoteSalesDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>

        <SimpleModal
          size="xl"
          show={itemDetailShow1}
          handleClose={() => setItemDetailShow1(false)}
          handleShow={() => setItemDetailShow1(true)}
        >
          <RemoteSalesEditWithBalance
            handleClose={() => setItemDetailShow1(false)}
            itemID1={itemID1}
            itemDetailnIfo1={itemifo1}
            selectedZone={selectZone}
            selectedDivision={selectDivision}
            selectedArea={selectArea}
            selectedTerritory={selectTerritory}
          />
        </SimpleModal>


      </div>
    </>
  );
};

export default RemoteSalesListWithBalance;
