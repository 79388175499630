import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import {
  productUpdateInputQuantity,
  salesUpdate,
  getUpdateSalesGroupProductList,
  getCustomerSalesGroupInfo,
  GetNewCustomerBalanceByID,
  EditDateGet,
} from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";

const RemoteSalesEditWithBalance = ({ itemDetailnIfo1, selectedZone, selectedDivision, selectedArea, selectedTerritory }) => {

  console.log('itemDetailnIfo1 :>> ', itemDetailnIfo1);
  console.log('selectedZone :>> ', selectedZone);
  console.log('selectedDivision :>> ', selectedDivision);
  console.log('selectedArea :>> ', selectedArea);
  console.log('selectedTerritory :>> ', selectedTerritory);


  const dispatch = useDispatch();
  const history = useHistory();
  const { id, name, code, zone, division, area, territory } = useParams();

  const { handleSubmit } = useForm();

  const productUpdateStatus = useSelector((state) => state.remoteSales.productUpdateStatus);
  const [disabled, setDisabled] = useState(false);
  const salesUpdateData = useSelector((state) => state.remoteSales.salesUpdateData);
  console.log('salesUpdateData :>> ', salesUpdateData);

  const productCost = useSelector((state) => state.remoteSales.productCost);
  const countProduct = useSelector((state) => state.remoteSales.productQuantity);

  const NewCustomerBalance = useSelector((state) => state.remoteSales.balanceData);
  const balanceLoaderNew = useSelector((state) => state.remoteSales.balanceLoaderNew);


  // useEffect(() => {
  //   if (productUpdateStatus) {
  //      setTimeout(() => {history.go(0)}, 2000);
  //   }
  // }, [productUpdateStatus]);


  useEffect(() => {
    dispatch(EditDateGet(itemDetailnIfo1));
    dispatch(getUpdateSalesGroupProductList(itemDetailnIfo1?.orderId, itemDetailnIfo1?.salesGroupId));
  }, []);


  const changeProductQuantity = (name, value) => {
    dispatch(productUpdateInputQuantity(name, value));
  };

  const onSubmit = async (e) => {
    setDisabled(true);
    dispatch(salesUpdate(itemDetailnIfo1?.orderId, salesUpdateData, selectedZone, selectedDivision, selectedArea, selectedTerritory));

  };

  return (
    <>
      <form
        className="form form-label-right"
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
        autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 card-top-border p-3">
          <div >
            <h3 className="mb-0 pb-0"> Sales Update</h3>
          </div>
          <hr></hr>
          <div className="form-group row">
            <div className="invoice">
              <div className="invoice-header">
                <div className="invoice-to">
                  <address className="m-b-5">
                    <strong className="text-inverse">Distributor Name : {itemDetailnIfo1?.cusName}</strong>
                    <br />
                    Distributor Code : {itemDetailnIfo1?.cusCode}
                    <br />
                    <br />
                    Invoice : {itemDetailnIfo1?.orderCode}
                    <br />

                  </address>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* <div className="container mb-3 containerFixed">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget one">
                <div className="widget__left" >

                  <p className="balanceleft">Available Balance</p>
                  <p className="balanceleft">Rest Balance</p>
                </div>
                <div className="widget__right">
                  {balanceLoaderNew ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-warning" />
                    </div>
                  ) : (
                    <div>
                      <h4 className="balanceleft">৳ {NewCustomerBalance?.generaL_BALANCE}</h4>
                      <h4 className="balanceleft">৳ {NewCustomerBalance?.generaL_BALANCE}</h4>
                    </div>

                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget two">
                <div className="widget__left">
                  <p>Credit Limit</p>
                </div>
                <div className="widget__right">
                  {balanceLoaderNew ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-warning" />
                    </div>
                  ) : (
                    <h4>৳ {NewCustomerBalance?.crediT_LIMIT}</h4>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget three">
                <div className="widget__left">
                  <p>Total Product Cost</p>
                </div>
                <div className="widget__right">
                  <h4>{productCost.toFixed(2)}</h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="paid__widget four">
                <div className="widget__left">
                  <p>Total Quantity</p>
                </div>
                <div className="widget__right">
                  <h4>{countProduct}</h4>
                </div>
              </div>
            </div>
          </div>
        </div> */}


        <div className="container-fluid mb-3  containerFixed" style={{ padding: "0 15px" }}>
          <div className="row" style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>

            <div className="col-xl-6 col-lg-6 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#f5e4e4", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
              <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#007bff", fontSize: "14px", lineHeight: "10px" }}>BALANCE</h5>

              <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Available Balance</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}> {numberFormatDecemal(parseFloat(itemDetailnIfo1?.generalBalance).toFixed(2))}</span>
              </div>
              <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Credit Balance</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{itemDetailnIfo1?.creditLimit}</span>
              </div>
              <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Pending Balance</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{itemDetailnIfo1?.pendingBalance}</span>
              </div>


              <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Net Available Balance</span>
                <span
                  style={{
                    float: "right",
                    fontSize: "13px",
                    color: (() => {

                      const totalSum = ((itemDetailnIfo1?.generalBalance + itemDetailnIfo1?.creditLimit - itemDetailnIfo1.pendingBalance))

                      return totalSum < 0 ? 'red' : '#050f26';
                      
                    })(),
                    fontWeight: '500',
                  }}
                >
                  {(() => {

                    const totalSum = (parseFloat(itemDetailnIfo1?.generalBalance + itemDetailnIfo1?.creditLimit - itemDetailnIfo1.pendingBalance).toFixed(2))


                    return numberFormatDecemal((totalSum));
                  })()}
                </span>


              </div>

              <span className="float-right warning-msg-entry-page">( N.B : Without Damage Balance )</span>
            </div>


            <div className="col-xl-6 col-lg-6 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#e9f7f7", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
              <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#28a745", fontSize: "14px", lineHeight: "10px" }}>DEMAND</h5>

              <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Quantity</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{isNaN(parseFloat(countProduct)) ? '0.00' : numberFormatDecemal(parseFloat(countProduct).toFixed(2))}</span>
              </div>
              <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Product Value</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{isNaN(parseFloat(productCost)) ? '0.00' : numberFormatDecemal(parseFloat(productCost).toFixed(2))}</span>
              </div>
              <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>-</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>-</span>
              </div>

              <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Demand Value</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                  {isNaN(parseFloat(productCost)) ? '0.00' : numberFormatDecemal(parseFloat(productCost).toFixed(2))}
                </span>
              </div>
            </div>

          </div>
        </div>


        <div className="card card-custom gutter-b pl-5 pr-5 mb-5">
          {salesUpdateData && salesUpdateData.length > 0 ? (
            <div className="react-bootstrap-table table-responsive mt-8">
              <table className="table table table-head-custom table-vertical-center">
                <thead>
                  <th>SL</th>
                  <th>Item ID</th>
                  <th>Item Name</th>
                  {/* <th>Price</th> */}
                  <th>Quantity</th>
                  {/* <th>Total Price</th> */}
                </thead>
                <tbody>
                  {salesUpdateData && salesUpdateData.map((item, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{item.itemID}</td>
                      <td>{item.itemName}</td>
                      {/* <td>{item.price}</td> */}
                      <td>
                        <Form.Group>
                          <Form.Control
                            autocomplete="off"
                            className="formHeight"
                            type="number"
                            placeholder="Quantity"
                            name="itemName"
                            value={item.itemQuentity}
                            onChange={(e) =>
                              changeProductQuantity(item, e.target.value)
                            }
                          />
                        </Form.Group>
                      </td>
                      {/* <td>{item.totalPrice}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-lg-12 mt-5">
                <button
                  disabled={disabled}
                  type="submit"
                  className="btn btn-primary 
                btn-sm float-right text-center 
                custome-addnew-btn item-add-save mb-5"
                >
                  Update
                </button>

              </div>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default RemoteSalesEditWithBalance;
