export const CHANGE_HANDLE_DATE_MANAGE_FOR_DASHBOARD = "CHANGE_HANDLE_DATE_MANAGE_FOR_DASHBOARD";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const ABL_MONTH_WISE_DATA_FOR_DASHBOARD = "ABL_MONTH_WISE_DATA_FOR_DASHBOARD";
export const CHANGE_PRODUCT_INPUT_REPORT = "CHANGE_PRODUCT_INPUT_REPORT";
export const GET_CATEGORY_TYPE = "GET_CATEGORY_TYPE";
export const GET_PRODUCT_TYPE = "GET_PRODUCT_TYPE";
export const GET_NSM_TYPE = "GET_NSM_TYPE";
export const GET_DSM_TYPE = "GET_DSM_TYPE";
export const GET_RM_TYPE = "GET_RM_TYPE";
export const GET_TSM_TYPE = "GET_TSM_TYPE";
export const ABL_SALES_SINCE_INCEPTION_FOR_DASHBOARD = "ABL_SALES_SINCE_INCEPTION_FOR_DASHBOARD";
export const GET_LINE_TYPE = "GET_LINE_TYPE";
export const GET_PRODUCT_GROUP_TYPE = "GET_PRODUCT_GROUP_TYPE";
export const GET_DAMAGE_DATA = "GET_DAMAGE_DATA";
export const GET_LOCATION_WISE_DATA = "GET_LOCATION_WISE_DATA";
export const CLEAR_LIST = "CLEAR_LIST";
export const GET_PRODUCTION_LINE = "GET_PRODUCTION_LINE";
export const CHANGE_PRODUCT_INPUT = "CHANGE_PRODUCT_INPUT";
export const GET_LOCATION_WISE_DATA_FOR_DONUT = "GET_LOCATION_WISE_DATA_FOR_DONUT";
export const GET_PRODUCTION_LINE_BY_PRODUCTION = "GET_PRODUCTION_LINE_BY_PRODUCTION";
export const GET_PRODUCT_LIST_BY_GROUP = "GET_PRODUCT_LIST_BY_GROUP";
export const GET_WORKING_DAY_LIST = "GET_WORKING_DAY_LIST";
export const GET_PREVIOUS_WORKING_DAY_LIST = "GET_PREVIOUS_WORKING_DAY_LIST";
export const GET_ACHIEVEMENT_STATUS = "GET_ACHIEVEMENT_STATUS";
export const GET_IMS_NOTIFICATION = "GET_IMS_NOTIFICATION";
export const GET_ALL_PRODUCT_LIST = "GET_ALL_PRODUCT_LIST";