import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { GetAllPendingProductList } from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";

const EntryPageAvailableBalanceDetails = ({ BalanceData, roleType }) => {


  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  return (
    <>

      <div className="container-fluid mb-3 mt-1 containerFixed" style={{ padding: "0 15px" }}>
        <div className="row" style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>

          <div className="col-xl-12 col-lg-12 col-md-12" style={{ flex: "1", minWidth: "250px", backgroundColor: "#f5e4e4", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
            <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#007bff", fontSize: "14px", lineHeight: "15px" }}><u>BALANCE CALCULATION</u></h5>

            <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
              <span style={{ fontWeight: "bold", fontSize: "13px" }}>Opening Balance</span>
              <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                <span className="success-msg-entry-page">(+)</span>
                {
                  (() => {
                    const openingBalance = isNaN(parseFloat(BalanceData?.openingBalance)) ? 0.00 : parseFloat(BalanceData?.openingBalance);
                    return numberFormatDecemal(parseFloat(openingBalance).toFixed(2));
                  })()
                }
              </span>
            </div>

            <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
              <span style={{ fontWeight: "bold", fontSize: "13px" }}>Bank Receive</span>
              <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}><span className="success-msg-entry-page">(+)</span>{isNaN(parseFloat(BalanceData?.bankReceive)) ? '0.00' : numberFormatDecemal(parseFloat(BalanceData?.bankReceive).toFixed(2))}</span>
            </div>

            <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
              <span style={{ fontWeight: "bold", fontSize: "13px" }}>Adjust Amount</span>
              <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}><span className="success-msg-entry-page">(+)</span>{isNaN(parseFloat(BalanceData?.adjustAmount)) ? '0.00' : numberFormatDecemal(parseFloat(BalanceData?.adjustAmount).toFixed(2))}</span>
            </div>

            {roleType !== 10 && (
              <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                <span style={{ fontWeight: "bold", fontSize: "13px" }}>Credit Limit</span>
                <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}><span className="success-msg-entry-page">(+)</span>{isNaN(parseFloat(BalanceData?.creditLimit)) ? '0.00' : numberFormatDecemal(parseFloat(BalanceData?.creditLimit).toFixed(2))}</span>
              </div>
            )}



            <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", marginBottom: "8px", lineHeight: "10px" }}>
              <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total</span>
              <span
                style={{
                  float: "right",
                  fontSize: "13px",
                  color: (() => {

                    const openingBalance = isNaN(parseFloat(BalanceData?.openingBalance)) ? 0.00 : parseFloat(BalanceData?.openingBalance);

                    const BankReceive = isNaN(parseFloat(BalanceData?.bankReceive)) ? 0.00 : parseFloat(BalanceData?.bankReceive);

                    const AdjustAmount = isNaN(parseFloat(BalanceData?.adjustAmount)) ? 0.00 : parseFloat(BalanceData?.adjustAmount);

                    const creditLimit = roleType !== 10 && !isNaN(parseFloat(BalanceData?.creditLimit))
                      ? parseFloat(BalanceData?.creditLimit)
                      : 0;


                    const totalSum = openingBalance + BankReceive + AdjustAmount + creditLimit;


                    return totalSum < 0 ? 'red' : '#050f26';
                  })(),
                  fontWeight: '500',
                }}
              >
                {(() => {
                  const openingBalance = isNaN(parseFloat(BalanceData?.openingBalance)) ? 0.00 : parseFloat(BalanceData?.openingBalance);

                  const BankReceive = isNaN(parseFloat(BalanceData?.bankReceive)) ? 0.00 : parseFloat(BalanceData?.bankReceive);

                  const AdjustAmount = isNaN(parseFloat(BalanceData?.adjustAmount)) ? 0.00 : parseFloat(BalanceData?.adjustAmount);

                  const totalSum = openingBalance + BankReceive + AdjustAmount;

                  return numberFormatDecemal(totalSum.toFixed(2));
                })()}
              </span>



            </div>

            <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
              <span style={{ fontWeight: "bold", fontSize: "13px" }}>Delivery Amount</span>
              <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}><span className="success-msg-entry-page">(-)</span>{isNaN(parseFloat(BalanceData?.deliveryAmount)) ? '0.00' : numberFormatDecemal(parseFloat(BalanceData?.deliveryAmount).toFixed(2))}</span>
            </div>

            <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", marginBottom: "8px", lineHeight: "10px" }}>
              <span style={{ fontWeight: "bold", fontSize: "14px", color:"#e0a80b" }}>Ledger With Credit Limit</span>
              <span
                style={{
                  float: "right",
                  fontSize: "14px",
                  color: (() => {

                    const openingBalance = isNaN(parseFloat(BalanceData?.openingBalance)) ? 0.00 : parseFloat(BalanceData?.openingBalance);

                    const BankReceive = isNaN(parseFloat(BalanceData?.bankReceive)) ? 0.00 : parseFloat(BalanceData?.bankReceive);

                    const AdjustAmount = isNaN(parseFloat(BalanceData?.adjustAmount)) ? 0.00 : parseFloat(BalanceData?.adjustAmount);

                    const creditLimit = roleType !== 10 && !isNaN(parseFloat(BalanceData?.creditLimit))
                      ? parseFloat(BalanceData?.creditLimit)
                      : 0;

                    const DeliveryAmount = isNaN(parseFloat(BalanceData?.deliveryAmount)) ? 0.00 : parseFloat(BalanceData?.deliveryAmount);


                    const totalSum = openingBalance + BankReceive + AdjustAmount + creditLimit - DeliveryAmount;


                    return totalSum < 0 ? 'red' : '#e0a80b';
                  })(),
                  fontWeight: '500',
                }}
              >
                {(() => {
                  const openingBalance = isNaN(parseFloat(BalanceData?.openingBalance)) ? 0.00 : parseFloat(BalanceData?.openingBalance);

                  const BankReceive = isNaN(parseFloat(BalanceData?.bankReceive)) ? 0.00 : parseFloat(BalanceData?.bankReceive);

                  const AdjustAmount = isNaN(parseFloat(BalanceData?.adjustAmount)) ? 0.00 : parseFloat(BalanceData?.adjustAmount);

                  const creditLimit = roleType !== 10 && !isNaN(parseFloat(BalanceData?.creditLimit))
                    ? parseFloat(BalanceData?.creditLimit)
                    : 0;

                  const DeliveryAmount = isNaN(parseFloat(BalanceData?.deliveryAmount)) ? 0.00 : parseFloat(BalanceData?.deliveryAmount);


                  const totalSum = openingBalance + BankReceive + AdjustAmount + creditLimit - DeliveryAmount;

                  return numberFormatDecemal(totalSum.toFixed(2));
                })()}
              </span>



            </div>


            <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
              <span style={{ fontWeight: "bold", fontSize: "13px" }}>Pending Amount</span>
              <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}><span className="success-msg-entry-page">(-)</span>{isNaN(parseFloat(BalanceData?.pendingAmount)) ? '0.00' : numberFormatDecemal(parseFloat(BalanceData?.pendingAmount).toFixed(2))}</span>
            </div>

            <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
              <span style={{ fontWeight: "bold", fontSize: "14px", color:"#3fab68" }}>Available Balance For Demand</span>
              <span
                style={{
                  float: "right",
                  fontSize: "14px",
                  color: (() => {

                    const openingBalance = isNaN(parseFloat(BalanceData?.openingBalance)) ? 0.00 : parseFloat(BalanceData?.openingBalance);

                    const BankReceive = isNaN(parseFloat(BalanceData?.bankReceive)) ? 0.00 : parseFloat(BalanceData?.bankReceive);

                    const AdjustAmount = isNaN(parseFloat(BalanceData?.adjustAmount)) ? 0.00 : parseFloat(BalanceData?.adjustAmount);

                    const creditLimit = roleType !== 10 && !isNaN(parseFloat(BalanceData?.creditLimit))
                      ? parseFloat(BalanceData?.creditLimit)
                      : 0;

                    const DeliveryAmount = isNaN(parseFloat(BalanceData?.deliveryAmount)) ? 0.00 : parseFloat(BalanceData?.deliveryAmount);

                    const PendingAmount = isNaN(parseFloat(BalanceData?.pendingAmount)) ? 0.00 : parseFloat(BalanceData?.pendingAmount);



                    const totalSum = openingBalance + BankReceive + AdjustAmount + creditLimit - DeliveryAmount - PendingAmount;


                    return totalSum < 0 ? 'red' : '#3fab68';
                  })(),
                  fontWeight: '500',
                  fontSize: "14px",
                }}
              >
                {(() => {
                  const openingBalance = isNaN(parseFloat(BalanceData?.openingBalance)) ? 0.00 : parseFloat(BalanceData?.openingBalance);

                  const BankReceive = isNaN(parseFloat(BalanceData?.bankReceive)) ? 0.00 : parseFloat(BalanceData?.bankReceive);

                  const AdjustAmount = isNaN(parseFloat(BalanceData?.adjustAmount)) ? 0.00 : parseFloat(BalanceData?.adjustAmount);

                  const creditLimit = roleType !== 10 && !isNaN(parseFloat(BalanceData?.creditLimit))
                    ? parseFloat(BalanceData?.creditLimit)
                    : 0;

                  const DeliveryAmount = isNaN(parseFloat(BalanceData?.deliveryAmount)) ? 0.00 : parseFloat(BalanceData?.deliveryAmount);

                  const PendingAmount = isNaN(parseFloat(BalanceData?.pendingAmount)) ? 0.00 : parseFloat(BalanceData?.pendingAmount);



                  const totalSum = openingBalance + BankReceive + AdjustAmount + creditLimit - DeliveryAmount - PendingAmount;



                  return numberFormatDecemal(totalSum.toFixed(2));
                })()}
              </span>

              

            </div>
            <span className="float-right warning-msg-entry-page"><strong>( Without Damage Balance )</strong></span>








          </div>



        </div>
      </div>





    </>
  );
};

export default EntryPageAvailableBalanceDetails;
