import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { GetAllPendingProductList } from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";

const EntryPageAvailableBalanceDetails = ({ BalanceData, roleType }) => {


  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  return (
    <>

      <div className="card card-custom gutter-b pl-5 pr-5 card-top-border p-1 ">

        <div className="row">
          <div className="col-md-9">
            <div className="mt-2 p-2">

              <h3 className="mb-0 pb-0">Balance Calculation</h3>
            </div>
          </div>



        </div>

        <hr></hr>


        <span><strong>{BalanceData?.distID}</strong></span>
        <span><strong>{BalanceData?.disName}</strong></span><br></br>

        <span><strong>Opening BAlance :</strong> {BalanceData?.openingBalance}</span>
        <span><strong>Bank Receive :</strong> {BalanceData?.bankReceive}</span>
        <span><strong>Adjust Amount :</strong> {BalanceData?.adjustAmount}</span>
        {
          roleType !== 10 &&
          <span><strong>Credit Limit :</strong> {BalanceData?.creditLimit}</span>
        }

        <span><strong>Delivery Amount :</strong> {BalanceData?.deliveryAmount}</span>

        <span>
          <strong>
            Formula :
            Opening + Bank Receive + Adjust Amount
            {roleType !== 10 && " + Credit " }
            + Delivery
          </strong>
        </span>

        <span>
          <strong>
            {parseFloat(
              (BalanceData?.openingBalance
                + BalanceData?.bankReceive
                + BalanceData?.adjustAmount
                + (roleType !== 10 ? BalanceData?.creditLimit : 0)
                - BalanceData?.deliveryAmount)
            ).toFixed(2)}
          </strong>
        </span>


      </div>





    </>
  );
};

export default EntryPageAvailableBalanceDetails;
