import Axios from "axios";
import * as Types from "../../types/SecondarySalesTypes";

export const getDeliveryReportByDistributor = (fromDate, toDate, zoneId, divisionId, areaId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    let url = "";
    url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimaryDeliveryCumalitiveByDistributor?`;
    if (zoneId !== null) {
        url += `&ZoneID=${zoneId}`
    }
    if (divisionId !== null) {

        url += `&DivisionID=${divisionId}`
    }
    if (areaId !== null) {
        url += `&AreaID=${areaId}`
    }
    if (fromDate !== null) {

        url += `&FDate=${fromDate}`
    }
    if (toDate !== null) {

        url += `&TDate=${toDate}`
    }
   
    await Axios.get(url).then((res) => {
        
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;

    });
    dispatch({ type: Types.GET_DELIVERY_REPORT_BY_DISTRIBUTOR, payload: responseList });
};

export const getDeliverySkuProduct = (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0, territoryId=0, type=2) => async (dispatch) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_DELIVERY_SKU_REPORT, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimaryDeliveryDistributorwithProduct?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Fdate=${fromDate}&Tdate=${toDate}&Type=${type}`;
    await Axios.get(url).then((res) => {
       
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_DELIVERY_SKU_REPORT, payload: responseList });
};
export const getPrimaryDeliverySkuWiseReport = (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0, territoryId=0, type=2) => async (dispatch) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_PRIMARY_SKUWISE_REPORT, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimaryDeliverySkuWiseReport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Fdate=${fromDate}&Tdate=${toDate}&Type=${type}`;
    await Axios.get(url).then((res) => {
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_PRIMARY_SKUWISE_REPORT, payload: responseList });
};
export const getPrimaryLiftingAllZone = (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0, territoryId=0, type=2) => async (dispatch) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_DELIVERY_SKU_REPORT, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimaryDeliveryLifingAllZone?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Fdate=${fromDate}&Tdate=${toDate}&Type=${type}`;
    await Axios.get(url).then((res) => {
       console.log('res :>> ', res);
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_DELIVERY_SKU_REPORT, payload: responseList });
};

export const getPrimarySalesDemandAllZone = (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0, territoryId=0, type=2) => async (dispatch) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_SALES_SKU_REPORT_ALL_ZONE, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimarySalesAllZone?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Fdate=${fromDate}&Tdate=${toDate}&Type=${type}`;
    await Axios.get(url).then((res) => {
       console.log('res :>> ', res);
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_SALES_SKU_REPORT_ALL_ZONE, payload: responseList });
};

export const getPrimarySalesDemandAllZoneExcel = async (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0, territoryId=0, type=2) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimarySalesAllZone?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Fdate=${fromDate}&Tdate=${toDate}&Type=${type}`;
    await Axios.get(url).then((res) => {
        console.log('allzoneres', res)
        responseList.data = res.data.data?.allReport == null ? [] : res.data.data?.allReport;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    return responseList;
};




export const GetPrimarySalesDeliveryAllZone = (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0, territoryId=0, type=1, disId=0) => async (dispatch) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_DELIVERY_SKU_REPORT, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimarySalesAllZoneWithDelivery?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Fdate=${fromDate}&Tdate=${toDate}&Type=${type}&DistibID=${disId}`;
    await Axios.get(url).then((res) => {
       console.log('res :>> ', res);
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_DELIVERY_SKU_REPORT, payload: responseList });
};

export const getDeliverySkuBYDeliveryDateProduct = ( zoneId = 0, divisionId = 0, areaId = 0,confromDate,conToDate,delFromDate,delToDate,type=22, SGeoType) => async (dispatch) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_DELIVERY_SKU_REPORT, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimaryDeliveryDistributorwithProductbydemandConfirmAndDelivery?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&F_DCdate=${confromDate}&T_DCdate=${conToDate}&F_Ddate=${delFromDate}&T_Ddate=${delToDate}&Type=${type}&SGeoType=${SGeoType}`;
    await Axios.get(url).then((res) => {
     
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_DELIVERY_SKU_REPORT, payload: responseList });
};

export const getSubDepoDelivery = ( zoneId = 0, divisionId = 0, areaId = 0,Territory=0,confromDate,conToDate,delFromDate,delToDate,type=2) => async (dispatch) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_DELIVERY_SKU_REPORT, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimaryDeliveryDistributorwithProductbydemandConfirmAndDeliverySubSalesOrder?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${Territory}&F_DCdate=${confromDate}&T_DCdate=${conToDate}&Type=2`;
    await Axios.get(url).then((res) => {
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_DELIVERY_SKU_REPORT, payload: responseList });
};


export const getSecondaryStock = (fromDate, zoneId = 0, divisionId = 0, areaId = 0,territoryId=0,repType=1,SGeoType,pageNo,pageLimit) => async (dispatch) => {

    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    // dispatch({ type: Types.GET_DISTRIBUTOR_STOCK, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDistribwiseStockReport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Mdate=${fromDate}&Type=${repType}&SGeoType=${SGeoType}&pageNo=${pageNo}&pageLimit=${pageLimit}`;
    
    await Axios.get(url).then((res) => {
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_DISTRIBUTOR_STOCK, payload: responseList });
};
export const getSecondaryStockForExcel = async (fromDate, zoneId = 0, divisionId = 0, areaId = 0,TerritoryId =0,repType=3) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDistribwiseStockReport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${TerritoryId}&Mdate=${fromDate}&Type=${repType}`;
    await Axios.get(url).then((res) => {
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    return responseList;
};



export const getSecondarysoProductForExcel = async(fromDate,toDate,zoneId=0,divisionId=0,areaId=0,terrioryId =0,SGeoType) =>  {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
    
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSoWiseProductFlatReport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${terrioryId}&Fdate=${fromDate}&Tdate=${toDate}&SGeoType=${SGeoType}`;
     
    await Axios.get(url).then((res) => {
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    return responseList;
  
  };

//   export const GetSOWiseIMSReportValueForExcel = async(fromDate,toDate,zoneId=0,divisionId=0,areaId=0,terrioryId =0,SGeoType) =>  {
//     let responseList = {
//       isProductLoading: true,
//       data: {},
//       status: true,
//     };
    
//      let  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSecondaryCumalitiveByso?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${terrioryId}&FDate=${fromDate}&TDate=${toDate}&SGeoType=${SGeoType}`;
     
//     await Axios.get(url).then((res) => {
//       responseList.data = res.data.data == null?[]:res.data.data;
//       responseList.status = false;
//       responseList.isProductLoading = false;
//     });
//     return responseList;
  
//   };

export const GetSOWiseIMSReportValueForExcel = async (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0, territoryId = 0, SGeoType) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSecondaryCumalitiveByso?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&FDate=${fromDate}&TDate=${toDate}&SGeoType=${SGeoType}`;

    try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 1300000);

        const res = await Axios.get(url, { signal: controller.signal });
        clearTimeout(timeoutId);

        responseList.data = res.data.data || [];
        responseList.status = false;
    } catch (error) {
        console.error('Error fetching IMS report value for Excel:', error);
        responseList.status = true;
        responseList.data = [];
        responseList.error = error.message || 'Failed to fetch data';
    } finally {
        responseList.isProductLoading = false;
    }

    return responseList;
};

  export const getDistributorWiseProductSkuDetailsForExcel = async(fromDate,toDate,zoneId=0,divisionId=0,areaId=0,terrioryId =0,SGeoType,reportType) =>  {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };
    
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDistributorWiseProductSkuDetails?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${terrioryId}&Fdate=${fromDate}&Tdate=${toDate}&SGeoType=${SGeoType}&ReportType=${reportType}`;
     
    await Axios.get(url).then((res) => {
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    return responseList;
  
  };
  export const getPrimaryDemandStatus = (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0, territoryId=0, productID=0) => async (dispatch) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    let url = "";
     url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimaryDemandStatus?`

     

     if (fromDate !== null) {
        url += `Fdate=${fromDate}`
    }
    if (toDate !== null) {

        url += `&Tdate=${toDate}`
    }
    if (zoneId !== null) {
        url += `&ZoneID=${zoneId}`
    }
    if (divisionId !== null) {

        url += `&DivisionID=${divisionId}`
    }
    if (areaId !== null) {

        url += `&AreaID=${areaId}`
    }
    if (territoryId !== null) {

        url += `&Territory=${territoryId}`
    }
    if (productID !== null) {

        url += `&productID=${productID}`
    }
  
    await Axios.get(url).then((res) => {
        console.log('ef-res', res);
       
        responseList.data = res.data.data == null ? [] : res.data.data;
        console.log(' responseList.data',  responseList.data);
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_PRIMARY_DEMAND_STATUS, payload: responseList });
};
export const InputHandle = (name, value) => (dispatch) => {
    const formData = {
      name: name,
      value: value,
    };
    dispatch({ type: Types.CHANGE_DELIVERY_INPUT, payload: formData });
  };

  export const getNewProductStockForDownloadExcel = ( fromDate, zoneId = 0, divisionId = 0, areaId = 0,TerritoryId =0,repType=3) => async (dispatch) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_STOCK_REPORT_NEW, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDistribwiseStockReport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${TerritoryId}&Mdate=${fromDate}&Type=${repType}`;
    await Axios.get(url).then((res) => {
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_STOCK_REPORT_NEW, payload: responseList });
};

export const getSecondaryStockForShowModified = (fromDate, zoneId = 0, divisionId = 0, areaId = 0,TerritoryId =0,repType=3,Dispoint=0) => async (dispatch) => {

    let responseList = {
        isStockLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_STOCK_REPORT_MODIFIED, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDistribwiseStockReport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${TerritoryId}&Mdate=${fromDate}&Type=${repType}&Dispoint=${Dispoint}`;
    await Axios.get(url).then((res) => {
        responseList.data = res.data.data == null?[]:res.data.data;
        responseList.status = false;
        responseList.isStockLoading = false;
    });
    dispatch({ type: Types.GET_STOCK_REPORT_MODIFIED, payload: responseList });
};

export const getPrimaryLiftingAllZoneProductWise = (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0, territoryId=0, type=1) => async (dispatch) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_DELIVERY_SKU_REPORT_PRODUCT_WISE, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimaryDeliveryLifingAllZoneProductWise?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Fdate=${fromDate}&Tdate=${toDate}&Type=${type}`;
    await Axios.get(url).then((res) => {
       console.log('res :>> ', res);
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_DELIVERY_SKU_REPORT_PRODUCT_WISE, payload: responseList });
};

export const GetPrimaryDeliveryLifingAllZoneForDownload = async (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0, territoryId=0, type=2) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimaryDeliveryLifingAllZoneForDownload?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Fdate=${fromDate}&Tdate=${toDate}&Type=${type}`;
    await Axios.get(url).then((res) => {
        console.log('res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    return responseList;
};

export const GetPrimaryDeliverySalesAllZoneForDownload = async (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0, territoryId=0, type=2) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPrimarySalesAllZoneWithDeliveryDownload?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Fdate=${fromDate}&Tdate=${toDate}&Type=${type}`;
    await Axios.get(url).then((res) => {
        console.log('res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    return responseList;
};


