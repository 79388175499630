import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DistributorReportsFilterBrWithAllData from "../../../information/components/DistributorReports/DistributorReportsFilterBrWithAllData";
import DistributorReportBrDataTableWithAllData from "./DistributorReportBrDataTableWithAllData";


const DistributorReportsBrContainerWithAllData = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-2  card-top-border">
        <DistributorReportsFilterBrWithAllData />
        <DistributorReportBrDataTableWithAllData />

      </div>

    </>
  );
};

export default DistributorReportsBrContainerWithAllData;
