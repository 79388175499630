import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetImsMenuTrackingSystem } from "../../_redux/actions/SecondarySales/SecondaryAction";
import { GetAchievementStatus } from "./DashboardCartAction";
import DashboardDemo from "./DashboardDemo";
import LotiGift from "./LotiGift";
import NewYear from "./NewYear";

const DashboardChartContainer = () => {
  const achievementStatus = useSelector((state) => state.dashboardcart.achievementStatus);
  const [showCard, setShowCard] = useState(() => {
    const savedState = localStorage.getItem('showCard');
    return savedState === null ? true : JSON.parse(savedState);
  });

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchAchievementStatus = async () => {
      await dispatch(GetAchievementStatus());
      setLoading(false);
    };
    fetchAchievementStatus();
  }, [dispatch]);

  const handleCloseCard = () => {
    setShowCard(false);
    localStorage.setItem('showCard', JSON.stringify(false));
  };

  // Inline styles for the card's parent container
  const containerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%', // Adjusted to center horizontally
    transform: 'translate(-50%, -50%)',
    zIndex: 1050, // Ensure the card is above other content
  };

  // CSS keyframes animation for sliding effect
  const keyframes = `
    @keyframes slideInUp {
        from {
            transform: translateY(100%);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
  `;

  // Styles for the card
  const cardStyle = {
    backgroundColor: '#ffffff',
    borderRadius: '0.5rem',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    border: '1px solid #ddd',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  };

  const cardHeaderStyle = {
    color: '#ffffff',
  };

  useEffect(() => {
    getLocation();
  }, [])

  const getLocation = () => {
    if ("geolocation" in navigator) {

      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

       

        const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&addressdetails=1`;

        fetch(url)
          .then(response => {
            if (!response.ok) {

              dispatch(GetImsMenuTrackingSystem("IMS Dashboard", "Login", "mac", "0", "0", "0", "Location Not Allowed"));
              throw new Error('Network response was not ok');

            }
            return response.json();
          })
          .then(data => {
            console.log('Location Data:', data?.display_name);

            dispatch(GetImsMenuTrackingSystem("IMS Dashboard", "Login", "mac", "0", latitude, longitude, data?.display_name));
          })
          .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
          });
      }, (error) => {
        console.error("Error getting geolocation:", error);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  

  return (
    <div className="container-fluid">
      <DashboardDemo />
      {/* achievementStatus && !loading && */}
      { showCard && (
        <div style={containerStyle}>
          <style>
            {keyframes}
          </style>
          <div style={cardStyle}>
            <div style={cardHeaderStyle}>
              <button
                onClick={handleCloseCard}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: '#FF6347', // Change color to a noticeable one
                  fontSize: '1.9rem', // Slightly larger font size
                  cursor: 'pointer',
                  float: 'right',
                  transition: 'color 0.3s ease', // Smooth color transition
                }}
                onMouseEnter={(e) => (e.target.style.color = '#FF4500')} // Darker shade on hover
                onMouseLeave={(e) => (e.target.style.color = '#FF6347')} // Original color
                aria-label="Close" // Accessibility
              >
                &times;
              </button>
              <NewYear />
              {/* <LotiCongratulation /> */}
            </div>
            <span className="pl-4 pr-4 text-center text-bold congratulations_names">{achievementStatus?.acheivementName}</span>
            <span className="pl-4 pr-4 pb-10 text-center congratulations">{achievementStatus?.acheivementDescription}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardChartContainer;
