import Axios from "axios";
import { showToast } from "../../../../../master/utils/ToastHelper";
import * as Types from "../../types/SecondarySalesTypes";

export const getSkuWiseTargetAction = (fromDate, toDate, zoneId, divisionId, areaId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
        
    };
    dispatch({ type: Types.GET_SKU_WISE_TARGET, payload: responseList });
   
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetTargetSalesAcheivmentreport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Fdate=${fromDate}&Tdate=${toDate}&Type=3`;
    await Axios.get(url).then((res) => {
        
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_SKU_WISE_TARGET, payload: responseList });
};
export const getSubLedger = (fromDate, toDate, zoneId, divisionId, areaId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    // let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetTargetSalesAcheivmentreport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Fdate=${fromDate}&Tdate=${toDate}&Type=1`;
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductliftingReportPivot?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Fdate=${fromDate}&Tdate=${toDate}&Type=2`;
    await Axios.get(url).then((res) => {
       
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
    });
    dispatch({ type: Types.GET_CUSTOEMR_LEDGER, payload: responseList });
};
export const getSkuDemandWiseTargetAction = (fromDate, toDate, zoneId, divisionId, areaId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_SKU_WISE_TARGET, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetTargetSalesAcheivmentreport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Fdate=${fromDate}&Tdate=${toDate}&Type=4`;
    
    await Axios.get(url).then((res) => {
       
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_SKU_WISE_TARGET, payload: responseList });
};
export const GetTargetVsAchDispointWise = (fromDate, toDate, zoneId, divisionId, areaId,territory) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_TARGET_ACV_DISPOINT, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}PrimaryTargetVsAchivementDistribPointWise?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territory}&Fdate=${fromDate}&Tdate=${toDate}`;
    await Axios.get(url).then((res) => {
       console.log('snacks res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_TARGET_ACV_DISPOINT, payload: responseList });
};
export const GetTargetVsAchDispointWisePrimarySales = (fromDate, toDate, zoneId, divisionId, areaId, territory, dispoint) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_TARGET_ACV_DISPOINT_SALES_WISE, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}PrimarySalesTargetVsAchivementDistribPointWise?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territory}&DispointId=${dispoint}&Fdate=${fromDate}&Tdate=${toDate}`;
    await Axios.get(url).then((res) => {
       
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_TARGET_ACV_DISPOINT_SALES_WISE, payload: responseList });
};
export const GetTargetVsAchProductWise = (fromDate, toDate, zoneId, divisionId, areaId,territory) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_TARGET_ACV_PRODUCT_WISE, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}PrimaryTargetVsAchivementProductWise?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territory}&Fdate=${fromDate}&Tdate=${toDate}`;
    await Axios.get(url).then((res) => {
       
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_TARGET_ACV_PRODUCT_WISE, payload: responseList });
};
export const getSampleList = (fromDate, toDate, zoneId, divisionId, areaId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetTargetSalesAcheivmentreport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Fdate=${fromDate}&Tdate=${toDate}&Type=2`;
    await Axios.get(url).then((res) => {
        
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
    });
    dispatch({ type: Types.GET_SKU_WISE_TARGET, payload: responseList });
};

export const cleanTargetSalesData = () => async (dispatch) => {
    dispatch({ type: Types.GET_TARTGET_SALES_CLEAN, payload: null });
};
export const getBreadBurnPivot = (fromDate, toDate, zoneId, divisionId, areaId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductliftingReportPivot?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Fdate=${fromDate}&Tdate=${toDate}&Type=2`;
    await Axios.get(url).then((res) => {
       
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
    });
    dispatch({ type: Types.GET_BREDBURN_PIVOT_REPORT, payload: responseList });
};
export const getDistributorStock = (fromDate, zoneId=0, divisionId=0, areaId=0, territoryId=0) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    try {
        let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/ControlPanel/GetStockReportproductwiseflat?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&TerritoryId=${territoryId}&Mdate=${fromDate}&Type=2`;
        await Axios.get(url).then((res) => {
            responseList.data = res.data.data == null ? [] : res.data.data;
            responseList.status = false;
        });
        dispatch({ type: Types.GET_DISTRIBUTOR_STOCK_PIVOT, payload: responseList });
    } catch (error) {
        
    }
};
export const getPrimaryDemandSkuWise = (fromDate,tDate, zoneId=0, divisionId=0, areaId=0, territoryId=0) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    try {
        let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetAllPrimaryDemandSku?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Fdate=${fromDate}&Tdate=${tDate}`;
       
        await Axios.get(url).then((res) => {
          
            responseList.data = res.data.data == null ? [] : res.data.data;
            responseList.status = false;
        });
        dispatch({ type: Types.GET_PRIMARY_DEMAND_SKU_WISE, payload: responseList });
    } catch (error) {
      
    }
};

export const getPrimaryDemandSku = (fromDate,toDate,zoneId=0,divisionId=0,areaId=0, territoryId=0,distributionPoint=0) => async (dispatch) => {
    let responseList = {
      isProductLoading: true,
      data: {},
      status: true,
    };

    // dispatch({ type: Types.GET_PRIMARY_DEMAND_CUM, payload: responseList });
     let  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetAllPrimaryDemandSku?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territoryId}&Fdate=${fromDate}&Tdate=${toDate}`;
    await Axios.get(url).then((res) => {
 
      responseList.data = res.data.data == null?[]:res.data.data;
      responseList.status = false;
      responseList.isProductLoading = false;
    });
    dispatch({ type: Types.GET_PRIMARY_DEMAND_CUM, payload: responseList });
  };

export const getAsmAchivementReport = (fromDate, toDate, zoneId, divisionId, areaId,TerritoryId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_SKU_WISE_TARGET, payload: responseList });

    
    //let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetTargetSalesAcheivmentreport?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&territoryId=${TerritoryId}&&Fdate=${fromDate}&Tdate=${toDate}&Type=5`;
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSSTargetVSAcheivmentTsmWise?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${TerritoryId}&&Fdate=${fromDate}&Tdate=${toDate}&Type=5`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_SKU_WISE_TARGET, payload: responseList });
};
export const updateLockTimeData = async (fromDate,todate) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSalesTemp/ApplicationLockUpdate?locktime=${fromDate}&MaxLockTime=${todate}`;
    
    try {
        await Axios.put(url).then((res) => {
            
            responseList.data = res.data.data == null ? [] : res.data.data;
            responseList.status = false;
            showToast("success", `${res.data.message}`);
        })
      
    } catch (error) {
        
    }
 
   
};

export const GetTargetVsAchDispointWiseForDownload = async (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0,territory = 0) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}PrimaryTargetVsAchivementDistribPointWise?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territory}&Fdate=${fromDate}&Tdate=${toDate}`;
    await Axios.get(url).then((res) => {
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    return responseList;
};
export const GetPrimarySalesTargetVsAchivementDistribPointWiseForDownload = async (fromDate, toDate, zoneId = 0, divisionId = 0, areaId = 0,territory = 0) => {
    let responseList = {
        isProductLoading: true,
        data: {},
        status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}PrimarySalesTargetVsAchivementDistribPointWiseDownload?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Territory=${territory}&Fdate=${fromDate}&Tdate=${toDate}`;
    await Axios.get(url).then((res) => {
        console.log('resnewefaj', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isProductLoading = false;
    });
    return responseList;
};

export const ZoneSalesReport = (fromDate, toDate, type) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_ZONE_DATA_TARVSACHV, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}ZoneSalesReport?fromDate=${fromDate}&toDate=${toDate}&type=${type}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_ZONE_DATA_TARVSACHV, payload: responseList });
};

export const setLimitedDivisionReports = (limit) => (dispatch, getState) => {
    const { divisionReports } = getState().target;
  
    // Slice the divisionReports array to get only the first 'limit' items
    const limitedData = divisionReports.slice(0, limit);

    dispatch({ type: Types.SET_LIMITED_DIVISION_REPORTS, payload: limitedData });
  };

  export const setLimitedRegionReports = (limit) => (dispatch, getState) => {
    const { areaReports } = getState().target;
  
    // Slice the divisionReports array to get only the first 'limit' items
    const limitedData = areaReports.slice(0, limit);

    dispatch({ type: Types.SET_LIMITED_REGION_REPORTS, payload: limitedData });
  };
  export const setLimitedTerritoryReports = (limit) => (dispatch, getState) => {
    const { territoryReports } = getState().target;
  
    // Slice the divisionReports array to get only the first 'limit' items
    const limitedData = territoryReports.slice(0, limit);

    dispatch({ type: Types.SET_LIMITED_TERRITORY_REPORTS, payload: limitedData });
  };

  export const setBottomTerritoryReports = (limit) => (dispatch, getState) => {
    const { territoryReports } = getState().target;
  
    // Get the last 'limit' items from the territoryReports array
    const bottomData = territoryReports.slice(-limit);
  
    dispatch({ type: Types.SET_BOTTOM_TERRITORY_REPORTS, payload: bottomData });
  };
  export const setBottom10RMReports = (limit) => (dispatch, getState) => {
    const { areaReports } = getState().target;
  
    // Get the last 'limit' items from the areaReports array
    const bottomData = areaReports.slice(-limit);
    console.log('bottomData1', bottomData)

    dispatch({ type: Types.SET_BOTTOM_RM_REPORTS, payload: bottomData });
  };
  export const setBottom3DSMReports = (limit) => (dispatch, getState) => {
    const { divisionReports } = getState().target;
  
    // Get the last 'limit' items from the areaReports array
    const bottomData = divisionReports.slice(-limit);
    console.log('bottomData1', bottomData)

    dispatch({ type: Types.SET_BOTTOM_3_DSM_REPORTS, payload: bottomData });
  };

export const divisionSalesReport = (fromDate, toDate,type) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_DIVISION_DATA_TARVSACHV, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}DivisionSalesReport?fromDate=${fromDate}&toDate=${toDate}&type=${type}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_DIVISION_DATA_TARVSACHV, payload: responseList });
};
export const divisionSalesReportBakemans = (fromDate, toDate,type) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_DIVISION_DATA_TARVSACHV, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}BakemansDivisionSalesReport?fromDate=${fromDate}&toDate=${toDate}&type=${type}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_DIVISION_DATA_TARVSACHV, payload: responseList });
};

export const areaSalesReport = (fromDate, toDate,type) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_AREA_DATA_TARVSACHV, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}AreaSalesReport?fromDate=${fromDate}&toDate=${toDate}&type=${type}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_AREA_DATA_TARVSACHV, payload: responseList });
};
export const areaSalesReportBakemans = (fromDate, toDate,type) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_AREA_DATA_TARVSACHV, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}BakemansAreaSalesReport?fromDate=${fromDate}&toDate=${toDate}&type=${type}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_AREA_DATA_TARVSACHV, payload: responseList });
};

export const territorySalesReport = (fromDate, toDate,type) => async (dispatch) => {
    
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_TERRITORY_DATA_TARVSACHV, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}TerritorySalesReport?fromDate=${fromDate}&toDate=${toDate}&type=${type}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_TERRITORY_DATA_TARVSACHV, payload: responseList });
};

export const territorySalesReportBakemans = (fromDate, toDate,type) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_TERRITORY_DATA_TARVSACHV, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}TerritorySalesReportBackmans?fromDate=${fromDate}&toDate=${toDate}&type=${type}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_TERRITORY_DATA_TARVSACHV, payload: responseList });
};


export const GetProductSalesReportPivot = (fromDate, toDate, zoneId, divisionId, areaId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductSalesReportPivot?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Fdate=${fromDate}&Tdate=${toDate}&Type=2`;
    await Axios.get(url).then((res) => {
       
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
    });
    dispatch({ type: Types.GET_BREDBURN_PIVOT_REPORT, payload: responseList });
};
export const GetProductSalesReportPivotProductDistribuorWise = (fromDate, toDate, zoneId, divisionId, areaId,terri, distibId, prodId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_BREDBURN_PIVOT_REPORT, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductSalesReportPivotProductDistribuorWise?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Fdate=${fromDate}&Tdate=${toDate}&Type=2&distibId=${distibId}&prodId=${prodId}`;
    await Axios.get(url).then((res) => {
       
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
    });
    dispatch({ type: Types.GET_BREDBURN_PIVOT_REPORT, payload: responseList });
};
export const GetMonthlyProductSalesReportPivot = (fromDate, toDate, zoneId, divisionId, areaId,terri, distibId, prodId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_BREDBURN_PIVOT_REPORT_MONTHLY, payload: responseList });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetMonthlyProductSalesReportPivot?ZoneID=${zoneId}&DivisionID=${divisionId}&AreaID=${areaId}&Fdate=${fromDate}&Tdate=${toDate}&Type=2&distibId=${distibId}&prodId=${prodId}`;
    await Axios.get(url).then((res) => {
       console.log('pvt-res :>> ', res);
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.isLoading = false;
        responseList.status = false;
    });
    dispatch({ type: Types.GET_BREDBURN_PIVOT_REPORT_MONTHLY, payload: responseList });
};

export const TerritorySalesReportActiveInActive = (fromDate, toDate,type,terriID) => async (dispatch) => {
    
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_TERRITORY_DATA_ACTIVE_INACTIVE, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}TerritorySalesReportActiveInActive?fromDate=${fromDate}&toDate=${toDate}&type=${type}&territoryId=${terriID}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_TERRITORY_DATA_ACTIVE_INACTIVE, payload: responseList });
};
export const RegionSalesReportActiveInActive = (fromDate, toDate,type,regionid) => async (dispatch) => {
    
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_TERRITORY_DATA_ACTIVE_INACTIVE, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}RegionSalesReportActiveInActive?fromDate=${fromDate}&toDate=${toDate}&type=${type}&regionid=${regionid}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_TERRITORY_DATA_ACTIVE_INACTIVE, payload: responseList });
};
export const DivisionSalesReportActiveInActive = (fromDate, toDate,type,division) => async (dispatch) => {
    
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_TERRITORY_DATA_ACTIVE_INACTIVE, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}DivisionSalesReportActiveInActive?fromDate=${fromDate}&toDate=${toDate}&type=${type}&division=${division}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_TERRITORY_DATA_ACTIVE_INACTIVE, payload: responseList });
};
export const ZoneSalesReportActiveInActive = (fromDate, toDate,type,zone) => async (dispatch) => {
    
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_TERRITORY_DATA_ACTIVE_INACTIVE, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}ZoneSalesReportActiveInActive?fromDate=${fromDate}&toDate=${toDate}&type=${type}&zone=${zone}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_TERRITORY_DATA_ACTIVE_INACTIVE, payload: responseList });
};

export const TotalWorkingDayAndNeedImsCalculation = (fromDate, toDate, type) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_TOTAL_WORKING_DAY_NEED_IMS, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}TotalWorkingDayAndNeedImsCalculation?fromDate=${fromDate}&toDate=${toDate}&type=${type}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_TOTAL_WORKING_DAY_NEED_IMS, payload: responseList });
};
export const GetAllSoValueAcvr = (fromDate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_ALL_SO_VALUE_ACHVR, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetAllSoValueAcvr?FDate=${fromDate}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_ALL_SO_VALUE_ACHVR, payload: responseList });
};


export const GetCategoryWiseIMSDivision = (fromDate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_IMS_CATWISE_DIVISION_DATA, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetCategoryWiseIMSDivision?FDate=${fromDate}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_IMS_CATWISE_DIVISION_DATA, payload: responseList });
};


export const GetIMSMenuTrackingList = (fromDate, empid) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({ type: Types.GET_IMS_MENU_TRACKING, payload: responseList });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetIMSMenuTrackingList?Fdate=${fromDate}&empId=${empid}`;
    await Axios.get(url).then((res) => {
       console.log('ef res', res)
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.status = false;
        responseList.isLoading = false;
    });
    dispatch({ type: Types.GET_IMS_MENU_TRACKING, payload: responseList });
};

