import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { Form, Button, Row, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { FaSearch, FaSave } from "react-icons/fa";
import { GetEmployeeInformationWithoutAdmin } from "../../../../app/modules/Auth/_redux/menu-permission/authMenuPermissionAction";
import { getRoleListData } from "../_redux/actions/RolePermissionManagementAction";
import { RemoteAppUpdate, RemoteAppUpdatePassword, RemoteAppUpdateUserName, UpdateEmployeeStatus, UpdateEmployeeType } from "../../../Sales/information/_redux/actions/RemoteSales/RemoteSalesAction";

const EmployeeInformation = () => {

    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const [Enroll, setEnroll] = useState(null);
    const [status, setStatus] = useState(null);
    const [empType, setEmpType] = useState(null);
    const [role, setRole] = useState(null);
    const [username, setUserName] = useState(null);
    const [password, setPassword] = useState(null);
    const roleListOption = useSelector((state) => state.roleReducer.roleListOption);

    const empStatus = [
        { value: 1, label: "Active" },
        { value: 2, label: "Inactive" },
    ];

    const emptype = [
        { value: 1, label: "Biscuit" },
        { value: 3, label: "Bread-Bun" },
    ];

    const employeeInformation = useSelector(
        (state) => state.menu.employeeInformation
    );

    useEffect(() => {
        dispatch(getRoleListData());
    }, []);

    const showEmployee = () => {
        dispatch(GetEmployeeInformationWithoutAdmin(Enroll));
    };

    const updateRole = () => {
        dispatch(RemoteAppUpdate(Enroll, role));
    };

    const updateEmployeeStatus = () => {
        dispatch(UpdateEmployeeStatus(Enroll, status));
    };

    const updateEmployeeType = () => {
        dispatch(UpdateEmployeeType(Enroll, empType));
    };

    const updateUserName = () => {
        dispatch(RemoteAppUpdateUserName(Enroll, username));
    };

    const updatePassword = () => {
        dispatch(RemoteAppUpdatePassword(Enroll, password));
    };

    return (
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)' }}>
            <div  style={{ backgroundColor: '#007bff', padding: '10px', borderRadius: '5px', marginBottom: '15px' }}>
                <h5 style={{ color: 'white' }}>Employee Information</h5>
            </div>

            <Form>
                {/* ENROLL SEARCH */}
                <Row className="mb-4">
                    <Col md={8}>
                        <Form.Group controlId="formGridState">
                            <Form.Label>ENROLL :</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="TYPE ENROLL"
                                onChange={(e) => { setEnroll(e.target.value) }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4} className="d-flex justify-content-center align-items-center">
                        <Button 
                            variant="primary" 
                            style={{ width: '100%' }} 
                            onClick={showEmployee}
                        >
                            <FaSearch /> SEARCH
                        </Button>
                    </Col>
                </Row>

                {/* Employee Information */}
                {employeeInformation && (
                    <>
                        <div className="section-header" style={{ backgroundColor: '#17a2b8', padding: '10px', textAlign: 'center', marginBottom: '20px', borderRadius: '5px' }}>
                            <h5 style={{ color: 'white' }}>Employee Details</h5>
                        </div>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="formGridState">
                                    <Form.Label>ENROLL:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={employeeInformation?.empEnroll}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formGridState">
                                    <Form.Label>NAME:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={employeeInformation?.empName}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="formGridState">
                                    <Form.Label>USER NAME:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={employeeInformation?.empUserName}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formGridState">
                                    <Form.Label>PASSWORD:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={employeeInformation?.empPassword}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="formGridState">
                                    <Form.Label>ROLE:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={employeeInformation?.empRole}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formGridState">
                                    <Form.Label>ROLE NAME:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={employeeInformation?.empRoleName}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="formGridState">
                                    <Form.Label>EMP TYPE:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={employeeInformation?.empType}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formGridState">
                                    <Form.Label>STATUS:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={employeeInformation?.empStatus}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                )}

                {/* Update User Role */}
                <div className="section-header" style={{ backgroundColor: '#17a2b8', padding: '10px', textAlign: 'center', marginBottom: '20px', borderRadius: '5px' }}>
                    <h5 style={{ color: 'white' }}>Update User Role</h5>
                </div>
                <Row className="mb-4 d-flex justify-content-center align-items-center">
                    <Col md={8}>
                        <Form.Label>Role</Form.Label>
                        <RHFInput
                            as={<Select options={roleListOption} />}
                            rules={{ required: false }}
                            name="role_id"
                            register={register}
                            onChange={(option) => { setRole(option.value) }}
                            setValue={setValue}
                        />
                    </Col>
                    <Col md={4} className="d-flex justify-content-center align-items-center">
                        <Button
                            variant="primary"
                            style={{ width: '100%' }}
                            onClick={updateRole}
                        >
                            <FaSave /> Update Role
                        </Button>
                    </Col>
                </Row>

                {/* Update User Status */}
                <div className="section-header" style={{ backgroundColor: '#17a2b8', padding: '10px', textAlign: 'center', marginBottom: '20px', borderRadius: '5px' }}>
                    <h5 style={{ color: 'white' }}>Update User Status</h5>
                </div>
                <Row className="mb-4 d-flex justify-content-center align-items-center">
                    <Col md={8}>
                        <Form.Label>Active/InActive</Form.Label>
                        <RHFInput
                            as={<Select options={empStatus} />}
                            rules={{ required: false }}
                            name="status"
                            register={register}
                            onChange={(option) => { setStatus(option.value) }}
                            setValue={setValue}
                        />
                    </Col>
                    <Col md={4} className="d-flex justify-content-center align-items-center">
                        <Button
                            variant="primary"
                            style={{ width: '100%' }}
                            onClick={updateEmployeeStatus}
                        >
                            <FaSave /> Update Status
                        </Button>
                    </Col>
                </Row>

                {/* Update Employee Type */}
                <div className="section-header" style={{ backgroundColor: '#17a2b8', padding: '10px', textAlign: 'center', marginBottom: '20px', borderRadius: '5px' }}>
                    <h5 style={{ color: 'white' }}>Update User Type (Biscuit, Bread-Bun)</h5>
                </div>
                <Row className="mb-4 d-flex justify-content-center align-items-center">
                    <Col md={8}>
                        <Form.Label>Employee Type</Form.Label>
                        <RHFInput
                            as={<Select options={emptype} />}
                            rules={{ required: false }}
                            name="emp_type"
                            register={register}
                            onChange={(option) => { setEmpType(option.value) }}
                            setValue={setValue}
                        />
                    </Col>
                    <Col md={4} className="d-flex justify-content-center align-items-center">
                        <Button
                            variant="primary"
                            style={{ width: '100%' }}
                            onClick={updateEmployeeType}
                        >
                            <FaSave /> Update Type
                        </Button>
                    </Col>
                </Row>

                {/* Update User Name */}
                <div className="section-header" style={{ backgroundColor: '#28a745', padding: '10px', textAlign: 'center', marginBottom: '20px', borderRadius: '5px' }}>
                    <h5 style={{ color: 'white' }}>Update User Name</h5>
                </div>
                <Row className="mb-4 d-flex justify-content-center align-items-center">
                    <Col md={8}>
                        <Form.Group controlId="formGridState">
                            <Form.Label>Username:</Form.Label>
                            <Form.Control
                                type="text"
                                value={username}
                                onChange={(e) => { setUserName(e.target.value) }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4} className="d-flex justify-content-center align-items-center">
                        <Button
                            variant="success"
                            style={{ width: '100%' }}
                            onClick={updateUserName}
                        >
                            <FaSave /> Update Username
                        </Button>
                    </Col>
                </Row>

                {/* Update Password */}
                <div className="section-header" style={{ backgroundColor: '#28a745', padding: '10px', textAlign: 'center', marginBottom: '20px', borderRadius: '5px' }}>
                    <h5 style={{ color: 'white' }}>Update Password</h5>
                </div>
                <Row className="mb-4 d-flex justify-content-center align-items-center">
                    <Col md={8}>
                        <Form.Group controlId="formGridState">
                            <Form.Label>Password:</Form.Label>
                            <Form.Control
                                type="text"
                                value={password}
                                onChange={(e) => { setPassword(e.target.value) }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4} className="d-flex justify-content-center align-items-center">
                        <Button
                            variant="success"
                            style={{ width: '100%' }}
                            onClick={updatePassword}
                        >
                            <FaSave /> Update Password
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default EmployeeInformation;
