import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import Select from "react-select";
import {
  getAreaListByDivisionEntry,
  getDivisionListByZoneEntry,
  getTerritoryEntry,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { cleanSecondarySalesData, GetDateWiseSoImsReportValue, GetSoWiseImsReportValue, GetTopSellingSku } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { dateTimeConvertLongString, numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { FaDownload } from "react-icons/fa";
import { GetSOWiseIMSReportValueForExcel } from "../../_redux/actions/Delivery/DeliveryAction";
import * as XLSX from "xlsx/xlsx.mjs";
import { currentdate } from "../../../../master/PrintDwopdown/DateHelper";

const DateWiseSoImsReportValueV2 = () => {
  const history = useHistory();

  const isLoading = useSelector((state) => state.secondarySales.DateWiseSoSalesLoading);
  const DateWiseSoSales = useSelector((state) => state.secondarySales.DateWiseSoSales);
  console.log('DateWiseSoSales', DateWiseSoSales)


  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(0);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [defultZone, setDefaultZone] = useState({});

  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const componentRef = useRef();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user?.employeeType);
    setDistribType(user?.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {
    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('res data', res);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, selectGeoType } = res.data;

        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  }

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  let ref = React.createRef();
  const inputField = React.useRef(null);


  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const handleChangeTodate = (input) => {
    setToDate(input);
  };
  const handleDateFilter = (option) => {
    handleChangeTodate(option);
  }

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const showValidation = () => {
    if (startDate == null || startDate == "") {
      alert("Please Select From Date");
      return false;
    }
    else if (toDate == null || toDate == "") {
      alert("Please select To Date");
      return false;
    }
    else {
      return true;
    }
  }

  const showReport = () => {
    const validation = showValidation();
    if (validation) {
      dispatch(GetDateWiseSoImsReportValue(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectGeoType));

    }
  }

  const getSalesValueType = (name, value) => {
    setSalesType(value);
    setSelectGeoType(value);
  }

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const hanleExcelClick = async () => {
    setLoading(true);
    setError(null);

    try {
      let excelClick = await GetSOWiseIMSReportValueForExcel(
        startDate,
        toDate,
        selectZone,
        selectDivision,
        selectArea,
        selectTerritory,
        2
      );

      const current = currentdate();

      let Heading = [[
        'ZONE ID',
        'ZONE NAME',
        'DIVISION ID',
        'DIVISION NAME',
        'REGION ID',
        'REGION NAME',
        'TERRITORY ID',
        'TERRITORY NAME',
        'JOINING DATE',
        'GROUP NAME',
        'SO ID',
        'SO NAME',
        'SO POINT ID',
        'SO POINT NAME',
        'NUMBER OF MEMO LINE',
        'TOTAL MEMO QTY',
        'TOTAL VISITED OUTLET',
        'TOTAL ORDER VALUE',
        'BISCUIT QTY',
        'BISCUIT VALUE',
        'CAKE QTY',
        'CAKE VALUE',
        'COOKIES QTY',
        'COOKIES VALUE',
        'WAFER QTY',
        'WAFER VALUE',
        'SANCKS QTY',
        'SANCKS VALUE',
        'TOTAL VALUE',
        'TOTAL CTN(QTY)',
        'TOTAL VALUE'
      ]];

      //Had to create a new workbook and then add the header
      const wb = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(worksheet, Heading);

      //Starting in the second row to avoid overriding and skipping headers
      XLSX.utils.sheet_add_json(worksheet, excelClick.data?.DateWiseSoSales, { origin: 'A2', skipHeader: true });
      const check = XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
      XLSX.writeFile(wb, 'So Wise IMS Report (Value)' + "-" + startDate + "To" + toDate + "-" + "Report Date" + "-" + current + '.xlsx');
    } catch (error) {
      console.error("Error:", error);
      setError("Try Again..!");

    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-lg-8">
            <div className="mt-2 p-2">
              {/* <span className="button-010">So wise IMS Report(Value)</span> */}
              {/* <h3 className="mb-0 pb-0">So Wise IMS Report(Value)</h3> */}
              <span className="mb-0 pb-0 pageheadingText">SO DATE WISE IMS REPORT(VALUE)</span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2 p-2 float-right ">
              {/* <Button
                size="sm"
                onClick={hanleExcelClick}
                disabled={loading}
                className={loading ? 'bg-danger' : ''}
              >
                <FaDownload /> {loading ? 'Loading...' : 'Download'}
              </Button>



              {error && <div>Error: {error}</div>} */}
              <DownloadDropdown excelname='So Date Wise IMS Report (Value)' />
            </div>
          </div>
          {/* <div className="col-lg-3">
            <div className="mt-2 p-2 ml-4">
              <SimpleSgeoType
                getSalesValue={getSalesValueType}
                sGeoType={selectGeoType}
              />
            </div>
          </div> */}
        </div>

        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">From Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">To Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={(event) => {
                  handleDateFilter(event.target.value)


                }}

              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={zoneDropdownData()}
                value={defultZone}
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setDefaultZone(option);
                  setdefultDivision(null);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                defaultValue={defultZone}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={divisionDropdownData()}
                value={defultDivision}
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setdefultDivision(option);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                defaultValue={defultDivision}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={areaDropdownData()}
                value={defaultArea}
                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setdefaultArea(option);
                  setdefultTerritory(null);
                  setValue("territory", "");
                }}
                defaultValue={defaultArea}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                onChange={(option) => {

                  setSelectTerritory(option?.value);
                  setdefultTerritory(option)
                }}
                defaultValue={defultTerritory}
              />
            </Form.Group>
          </div>


          <div className="col mt-5 ml-5">
            {/* <button className="button-621" onClick={() => showReport()} >  Show Report</button> */}
            <Button size="sm" onClick={() => showReport()}>Show Report</Button>
          </div>

        </div>

        <div className="row" id="id" ref={inputField}>
          {isLoading && <LoadingSpinner text="Loading Items List..." />}

          <div className="react-bootstrap-table table-responsive border-0 pl-5">
            <div
              className="stickyTable"
              style={{ height: DateWiseSoSales?.length > 0 ? 800 + "px" : 0 }}
            >
              <table
                className="table table-head-custom table-vertical-center item-add-table borderTableims"
                id="table-to-xls"
                ref={componentRef}
              >
                <thead>
                  <tr>
                    {/* Static Columns */}
                    <th scope="col">ZONE NAME</th>
                    <th scope="col">DIVISION NAME</th>
                    <th scope="col">AREA NAME</th>
                    <th scope="col">TERRITORY NAME</th>
                    <th scope="col">JOINING DATE</th>
                    <th scope="col">SO ID</th>
                    <th scope="col">SO NAME</th>
                    <th scope="col">GROUP NAME</th>
                    <th scope="col">SO POINT ID</th>

                    <th scope="col">SO POINT</th>

                    {/* Dynamically create columns for each date */}
                    {DateWiseSoSales &&
                      DateWiseSoSales[0] &&
                      Object.keys(DateWiseSoSales[0])
                        .filter(
                          (key) =>
                            key !== "ZoneName" &&
                            key !== "DivisionName" &&
                            key !== "AreaName" &&
                            key !== "TerritoryName" &&
                            key !== "JoiningDate" &&
                            key !== "SalesGroupName" &&
                            key !== "SoId" &&
                            key !== "SalesPersonName" &&
                            key !== "DelPointID" &&

                            key !== "DeliveryPointName"
                        )
                        .map((date, idx) => (
                          <th key={idx}>{date}</th>
                        ))}

                    {/* Single 'Total' column */}
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {DateWiseSoSales?.length > 0 &&
                    DateWiseSoSales.map((item, index) => {
                      // Calculate the total sales amount for this row
                      let totalSalesAmount = 0;

                      // Collect sales data for all dates and calculate the total sales amount
                      const salesDataForDates = Object.keys(item)
                        .filter(
                          (key) =>
                            key !== "ZoneName" &&
                            key !== "DivisionName" &&
                            key !== "AreaName" &&
                            key !== "TerritoryName" &&
                            key !== "JoiningDate" &&
                            key !== "SalesGroupName" &&
                            key !== "SoId" &&
                            key !== "SalesPersonName" &&
                            key !== "DelPointID" &&

                            key !== "DeliveryPointName"
                        )
                        .map((date) => {
                          const salesData = item[date] || { totalSalesAmount: 0 };
                          totalSalesAmount += parseFloat(salesData.totalSalesAmount);
                          return salesData;
                        });

                      return (
                        <tr key={index}>
                          {/* Static columns */}
                          <td>{item.ZoneName}</td>
                          <td>{item.DivisionName}</td>
                          <td>{item.AreaName}</td>
                          <td>{item.TerritoryName}</td>
                          <td>{dateTimeConvertLongString(item.JoiningDate)}</td>
                          <td>{item.SoId}</td>
                          <td>{item.SalesPersonName || "Vacant"}</td>
                          <td>{item.SalesGroupName}</td>
                          <td>{item.DelPointID}</td>

                          <td>{item.DeliveryPointName}</td>

                          {/* Dynamically create the sales amounts for each date */}
                          {salesDataForDates.map((salesData, idx) => (
                            <td key={idx}>
                              {numberFormatDecemal(parseFloat(salesData.totalSalesAmount).toFixed(2))}
                            </td>
                          ))}

                          {/* Total sales amount across all dates */}
                          <td>{numberFormatDecemal(parseFloat(totalSalesAmount).toFixed(2))}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {!isLoading && DateWiseSoSales?.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry! Sales List Not Found.
              </div>
            )}
          </div>
        </div>




      </div>
    </>
  );
};

export default DateWiseSoImsReportValueV2;
