import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Col, Button, Table } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import createPlotlyComponent from 'react-plotly.js/factory';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";

import { cleanSecondarySalesData } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { GetMonthlyProductSalesReportPivot } from "../../_redux/actions/Report/ReportAction";
import { getDistributorList } from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { GetAllProductList, GetCategoryType } from "../Dashboard/DashboardCartAction";
import { FaSearch } from 'react-icons/fa';

const MonthlyPrimarySalesReportPvtProductandDistributorWise = () => {

  const productReport = useSelector((state) => state.bradBurn.MonthlybreadBurnPivot);
  const isLoading = useSelector((state) => state.bradBurn.MonthlybreadBurnPivotLoading);
  const distributorList = useSelector((state) => state.remoteSales.distributorList);
  const AllProductList = useSelector((state) => state.dashboardcart.AllProductList);
  const categorytype = useSelector((state) => state.dashboardcart.ddcategorytype);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);
  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectType, setSelectType] = useState(null);
  const componentRef = useRef();
  const [selectCategory, setSelectCategoryType] = useState(null);


  const { register, setValue } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());

    dispatch(GetCategoryType());
    initialData();
  }, []);

  const initialData = async () => {
    let zoneData = await getZoneList();
    Setzone(zoneData.data);

  };

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const typeDropdownData = () => {
    let options = [
      {
        value: 1,
        label: 'Quantity'
      },
      {
        value: 2,
        label: 'Price'
      },
    ];

    return options;
  };

  const inputField = React.useRef(null);

  const handleChange = (e, name) => {
    const dateDetails = { ...skuDate };
    dateDetails[name] = e.target.value;
    setSkuDate(dateDetails);
  };


  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory, idDistributor, idSku) => {
    let conFromDate = skuDate.fromDate + 'T' + skuDate.fromTime;
    let conToDate = skuDate.todate + 'T' + skuDate.toTime;
    dispatch(GetMonthlyProductSalesReportPivot(conFromDate, conToDate, idZone, idDivision, idArea, idTerritory, idDistributor, idSku));
  }


  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);
  const [selectDistributor, setSelectDistributor] = useState(null);
  const [selectSKU, setSelectSKU] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [skuDate, setSkuDate] = useState({
    fromDate: null,
    fromTime: "00:00",
    todate: null,
    toTime: "23:59"
  });

  const showReport = () => {

    handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectDistributor, selectSKU);

  }

  const formatMonth = (monthYear) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [month, year] = monthYear.split("-"); 
    const monthName = monthNames[parseInt(month) - 1]; 
    return `${monthName}-${year}`;
  };

  const formatDataForMonths = (data) => {
    return data.map(item => {
     
      const monthlySalesQty = item.monthlySalesQty;
      const monthlySalesValue = item.monthlySalesValue;
      const months = Object.keys(monthlySalesQty);

      
      const formattedRow = months.reduce((acc, month) => {
        acc[`month_${month}`] = {
          qty: monthlySalesQty[month] || 0,
          value: monthlySalesValue[month] || 0,
        };
        return acc;
      }, { ...item });

      return formattedRow;
    });
  };

  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
    if (productReport?.length > 0) {
      const formatted = formatDataForMonths(productReport);
      setFormattedData(formatted);
    }
  }, [productReport]);



  return (
    <>
      <div className="container-fluid">
        <div className="card card-custom card-top-border">
          <div className="row mt-5">
            <div className="col-md-9">
              <div className="mt-2 p-2">

                <h3 className="mb-0 pb-0">Monthly Primary Sales Product Report (Product & Distributor Wise)</h3>
              </div>
            </div>
            <div className="col-lg-3 ">
              <DownloadDropdown excelname='Primary Sales Product Report (Product & Distributor Wise)' />
            </div>

          </div>
          <hr></hr>
          <div className="container-fluid">
            <div className="form-group row">
              <div className="col-md-3">
                <Form.Group as={Col} controlId="formGridState">
                  <label className="form-label">From Date</label>
                  <Form.Control
                    type="date"
                    placeholder="Enter Deadweight"
                    name="fromDate"
                    value={`${skuDate.fromDate}`}
                    className="fromStyle"
                    onChange={(e) => handleChange(e, 'fromDate')}
                  />
                </Form.Group>
              </div>

              <div className="col-md-3">
                <Form.Group as={Col} controlId="formGridState">
                  <label className="form-label">To Date</label>
                  <Form.Control
                    type="date"
                    placeholder="Enter Todate"
                    name="todate"
                    className="fromStyle"
                    value={`${skuDate.todate}`}
                    onChange={(e) => handleChange(e, 'todate')}
                  />
                </Form.Group>
              </div>



              <div className="col-md-3">
                <Form.Group as={Col} controlId="formGridState">
                  <label className="form-label">Select Type</label>
                  <RHFInput
                    className="formSelect pt-0"
                    as={<Select options={typeDropdownData()} />}
                    rules={{ required: false }}
                    name="typeData"
                    register={register}

                    value=""
                    isClearable
                    onChange={(option) => {
                      setSelectType(option?.value)


                    }}
                    setValue={setValue}
                  />
                </Form.Group>
              </div>

              <div className="col-md-3">
                <Form.Group as={Col} controlId="formGridState">
                  <label className="form-label">Select Zone</label>
                  <RHFInput
                    className="formSelect pt-0"
                    as={<Select options={zoneDropdownData()} />}
                    rules={{ required: false }}
                    name="zoneData"
                    register={register}

                    value=""
                    isClearable
                    onChange={(option) => {
                      setSelectZone(option?.value);
                      divisionList(option?.value);


                      setValue("divisionData", "");
                      setValue("areaData", "");
                      setValue("territory", "");


                    }}
                    setValue={setValue}
                  />
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group as={Col} controlId="formGridState">
                  <label className="form-label">Select Division</label>
                  <RHFInput
                    className="formSelect pt-0"
                    as={<Select options={divisionDropdownData()} />}
                    rules={{ required: false }}
                    name="divisionData"
                    register={register}
                    value=""
                    isClearable

                    onChange={(option) => {
                      getAreaData(option?.value);
                      setSelectDivision(option?.value);
                      setValue("areaData", "");
                      setValue("territory", "");

                    }}
                    setValue={setValue}
                  />
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group as={Col} controlId="formGridState">
                  <label className="form-label">Select Area/Region</label>
                  <RHFInput
                    className="formSelect pt-0"
                    as={<Select options={areaDropdownData()} />}
                    rules={{ required: false }}
                    name="areaData"
                    register={register}
                    value=""
                    isClearable

                    onChange={(option) => {
                      getTerritoryData(option?.value)
                      setSelectArea(option?.value);
                      setValue("territory", "");

                    }}
                    setValue={setValue}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-3">
                <Form.Group as={Col} controlId="formGridState">
                  <label className="form-label">Select Territory</label>
                  <RHFInput
                    className="formSelect pt-0"
                    as={<Select options={territoryDropdownData()} />}
                    rules={{ required: false }}
                    name="territory"
                    register={register}
                    value=""
                    isClearable
                    onChange={(option) => {
                      setSelectTerritory(option?.value);
                      dispatch(getDistributorList(option.value, salesType));

                    }}
                    setValue={setValue}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-3">
                <Form.Group as={Col} controlId="formGridState">
                  <label className="form-label">Select Distributor</label>
                  <RHFInput
                    as={<Select options={distributorList} />}
                    rules={{ required: false }}
                    name="intDistributorId"
                    register={register}
                    value=""
                    isClearable
                    onChange={(option) => {
                      setSelectDistributor(option?.value);
                    }}
                    setValue={setValue}
                  />
                </Form.Group>

              </div>
              <div className="col-lg-3">
                <Form.Group as={Col} controlId="formGridState">
                  <label className="form-label">Select Category</label>
                  <RHFInput
                    className="formSelect pt-0 "
                    as={<Select options={categorytype} />}
                    rules={{ required: false }}
                    name="categoryType"
                    register={register}
                    placeholder="Select Category type"
                    isClearable
                    onChange={(option) => {

                      setSelectCategoryType(option?.value);

                      dispatch(GetAllProductList(option?.value));

                    }}
                    setValue={setValue}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-5">
                <Form.Group as={Col} controlId="formGridState">
                  <label className="form-label">Select SKU</label>
                  <RHFInput
                    as={<Select options={AllProductList} />}
                    rules={{ required: false }}
                    name="SKU"
                    register={register}
                    value=""
                    isClearable
                    onChange={(option) => {
                      setSelectSKU(option?.value);
                    }}
                    setValue={setValue}
                  />
                </Form.Group>

              </div>

              <div className="col mt-6 mb-6">
                <Button className="btn" onClick={() => showReport()}><FaSearch /> Show Report</Button>
                
              </div>
            </div>
          </div>

        </div>
        <div>


          <div  id="id" ref={inputField}>

            <div className="table-responsive pl-5 pr-5 pb-5">
              <div>
                {isLoading ? (
                  <div className="loading-indicator">Loading...</div>
                ) : (
                  <>
                    {productReport?.length > 0 && (
                      <table className="pvt-product-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th >Zone</th>
                            <th >Division</th>
                            <th >Area</th>
                            <th > Territory</th>
                            <th >Distributor Point</th>
                            <th >Distributor Code</th>
                            <th >Distributor Name</th>
                            {formattedData[0] &&
                              Object.keys(formattedData[0])
                                .filter((key) => key.startsWith("month_"))
                                .map((monthKey, idx) => (
                                  <th  key={idx}>{formatMonth(monthKey.split("_")[1])}</th>
                                ))}
                            <th >{selectType === 1 ? "Total Qty" : "Total Value"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formattedData.map((row, index) => (
                            <tr key={index}>
                              <td>{row.zone}</td>
                              <td>{row.division}</td>
                              <td>{row.area}</td>
                              <td>{row.territoryName}</td>
                              <td>{row.distributorPoint}</td>
                              <td className="text-center">{row.distributorCode}</td>
                              <td>{row.distributorName}</td>

                              {Object.keys(row)
                                .filter((key) => key.startsWith("month_"))
                                .map((monthKey, idx) => {
                                  const { qty, value } = row[monthKey];
                                  return (
                                    <td key={idx}>
                                      {selectType === 1 ? qty : value}
                                    </td>
                                  );
                                })}

                              <td>{selectType === 1 ? row.productQty : row.productValue}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </>
                )}
              </div>
            </div>

          </div>


        </div>
      </div>
    </>
  );
};

export default MonthlyPrimarySalesReportPvtProductandDistributorWise;
