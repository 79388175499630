import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Axios from "axios";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SessionTimeout from "../Global/SessionTimeout";

const App = ({ store, persistor, basename }) => {
  const location = useLocation();

  const trackUserActivity = async () => {
    const activity = {
      IsActive: true,
      Duration: 30,
      LastActiveTime: new Date().toISOString(),
    };

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_API_PUBLIC_URL}TrackActivityAsync`,
        activity
      );

      if (response.data.message === "Activity tracked successfully.") {
        console.log("Activity tracked successfully.");
      } else {
        console.error("Error tracking activity.");
      }
    } catch (error) {
      console.error("Error tracking activity:", error);
    }
  };

  useEffect(() => {
    trackUserActivity();
    const activityInterval = setInterval(() => {
      trackUserActivity();
    }, 30000);

    return () => {
      clearInterval(activityInterval);
    };
  }, [location]);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        <MaterialThemeProvider>
          <I18nProvider>
            <Routes />
          </I18nProvider>
        </MaterialThemeProvider>
      </PersistGate>
      <ToastContainer />
      <SessionTimeout />
    </Provider>
  );
};

export default App;
