import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useForm } from "react-hook-form";
import { Form, Col, Button } from "react-bootstrap";
import Select from "react-select";
import { RHFInput } from "react-hook-form-input";
import {
  getAreaListByDivisionEntry,
  GetDisPointListByTerritory,
  getDivisionListByZoneEntry,
  getTerritoryEntry,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { currentdate, numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import { cleanTargetSalesData, GetTargetVsAchDispointWisePrimarySales, GetPrimarySalesTargetVsAchivementDistribPointWiseForDownload } from "../../_redux/actions/Report/ReportAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import * as XLSX from "xlsx/xlsx.mjs";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { FaDownload } from "react-icons/fa";

const TargetVsAcheivementDispointWisePrimarySales = () => {

  const targetDateList = useSelector((state) => state.target.DispointTargetSales);
  const isLoading = useSelector((state) => state.target.DisPointLoadingSales);
  console.log('targetDateList', targetDateList)

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const componentRef = useRef();
  const [skuDate, setSkuDate] = useState({
    fromDate: null,
    fromTime: "00:00",
    todate: null,
    toTime: "23:59"
  })

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});
  const [dispoint, setDisPointData] = useState([]);
  const [soPoint, setSelectSoPoint] = useState(null);

  const { register, setValue, } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanTargetSalesData());
    initialData();
  }, []);

  const initialData = async () => {
    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);
        GetDisPointList(territoryId);
        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();

    Setzone(zoneData.data);

  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };
        options.push(itemData);
      });
    }
    // setValue("divisionData", "");
    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }
  const GetDisPointList = async (id) => {
    let disData = await GetDisPointListByTerritory(id);
    setDisPointData(disData);
  }

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };


  const inputField = React.useRef(null);

  const handleChange = (e, name) => {
    const dateDetails = { ...skuDate };
    dateDetails[name] = e.target.value;
    setSkuDate(dateDetails);
  };
  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory) => {
    //let conFromDate = skuDate.fromDate + 'T' + skuDate.fromTime;
    //let conToDate = skuDate.todate + 'T' + skuDate.toTime;
    let conFromDate = skuDate.fromDate;
    let conToDate = skuDate.todate;
    // dispatch(getSkuDemandWiseTargetAction(fromDate, tDate, idZone, idDivision, idArea));
    dispatch(GetTargetVsAchDispointWisePrimarySales(conFromDate, conToDate, idZone, idDivision, idArea, idTerritory, soPoint));
  }

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const hanleExcelClick = async () => {
    setLoading(true);
    setError(null);

    try {

      let excelClick = await GetPrimarySalesTargetVsAchivementDistribPointWiseForDownload(
        skuDate.fromDate,
        skuDate.todate,
        selectZone,
        selectDivision,
        selectArea,
        selectTerritory
      );

      if (excelClick && excelClick.data && excelClick.data.length > 0) {
        const current = currentdate();
        const detailsReportData = excelClick.data;

        const flattenedData = [];

        detailsReportData.forEach((detailsRow) => {
          const itemReportData = detailsRow.itemReport;

          if (itemReportData && itemReportData.length > 0) {
            itemReportData.forEach((itemRow) => {
              const row = {
                "ZONE": detailsRow.zoneName,
                "DIVISION": detailsRow.divisionName,
                "REGION": detailsRow.areaName,
                "TERRITORY": detailsRow.territoryName,
                "DISPOINT ID": detailsRow.disPointID,
                "DISPOINT NAME": detailsRow.disName,
                "PRODUCT CATEGORIES": itemRow.prodCatagories || '',
                "PRODUCT ID": itemRow.prodID || '',
                "PRODUCT NAME": itemRow.prodName || '',
                "TARGET QUANTITY(CTN)": itemRow.targetQty || 0,
                "TARGET VALUE": itemRow.targetPrice || 0,
                "SALES QTY": itemRow.liftingQty || 0,
                "SALES VALUE": itemRow.liftingPrice || 0,
                "DIFFERENCE QUANTITY": itemRow.differentQty || 0,
                "DIFFERENCE VALUE": itemRow.differentPrice || 0,
                "TARGET VS ACH(IN TK %)": itemRow.tarVsAccPers || 0,
              };
              flattenedData.push(row);
            });
          } else {
            const row = {
              "ZONE": detailsRow.zoneName,
              "DIVISION": detailsRow.divisionName,
              "REGION": detailsRow.areaName,
              "TERRITORY": detailsRow.territoryName,
              "DISPOINT ID": detailsRow.disPointID,
              "DISPOINT NAME": detailsRow.disName,
              "PRODUCT CATEGORIES": '',
              "PRODUCT ID": '',
              "PRODUCT NAME": '',
              "TARGET QUANTITY(CTN)": detailsRow.totalReport.targetQty || 0,
              "TARGET VALUE": detailsRow.totalReport.targetPrice || 0,
              "SALES QTY": detailsRow.totalReport.liftingQty || 0,
              "SALES VALUE": detailsRow.totalReport.liftingPrice || 0,
              "DIFFERENCE QUANTITY": detailsRow.totalReport.differentQty || 0,
              "DIFFERENCE VALUE": detailsRow.totalReport.differentPrice || 0,
              "TARGET VS ACH(IN TK %)": 0,
            };
            flattenedData.push(row);
          }
        });

        const Heading = [

        ];
        const wb = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(flattenedData, { header: Heading });
        XLSX.utils.book_append_sheet(wb, worksheet, 'Details Report');
        XLSX.writeFile(wb, 'PRIMARY TARGET VS ACHIEVEMENT DISPOINT WISE ' + " " + current + '.xlsx');
      } else {
        console.error("No data to export to Excel");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Try Again..!");

    } finally {
      setLoading(false);
    }
  };

  const style = {
    container: {
      'background': 'f7f7f7',
      'overflowy': 'scroll'
    },
  }

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const dispointDropdownData = () => {
    let options = [];
    if (dispoint.data?.length > 0) {
      dispoint.data.forEach((item) => {
        let itemData = {
          value: item.disPointID,
          label: item.disPointName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">PRIMARY SALES TARGET VS ACHIVEMENT DISPOINT WISE</h3>
            </div>
          </div>

          <div className="col-md-3">
            <Button
              size="sm"
              onClick={hanleExcelClick}
              disabled={loading}
              className={loading ? 'bg-danger' : ''}
            >
              <FaDownload /> {loading ? 'Loading...' : 'Download'}
            </Button>

            {error && <div>Error: {error}</div>}
            <DownloadDropdown excelname='Primary Lifting vs Achievement Confirm Date' />
          </div>

        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromDate')}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Time</label>
              <Form.Control
                type="time"
                placeholder="Enter Deadweight"
                name="fromTime"
                value={`${skuDate.fromTime}`}
                className="fromStyle"
                onChange={(e) => handleChange(e, 'fromTime')}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={
                  (e) => {
                    handleChange(e, 'todate');
                  }
                }
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Time</label>
              <Form.Control
                type="time"
                placeholder="Enter Todate"
                name="toTime"
                className="fromStyle"
                value={`${skuDate.toTime}`}
                onChange={(e) => handleChange(e, 'toTime')}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={zoneDropdownData()}
                value={defultZone}
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setDefaultZone(option);
                  setdefultDivision(null);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                defaultValue={defultZone}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={divisionDropdownData()}
                value={defultDivision}
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setdefultDivision(option);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                defaultValue={defultDivision}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={areaDropdownData()}
                value={defaultArea}
                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setdefaultArea(option);
                  setdefultTerritory(null);
                  setValue("territory", "");
                }}
                defaultValue={defaultArea}
              />
            </Form.Group>
          </div>


          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                isClearable
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                  setdefultTerritory(option)
                  GetDisPointList(option?.value);


                }}
                defaultValue={defultTerritory}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Dis Point</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={dispointDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectSoPoint(option?.value);
                  setValue("territory", "");

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

        </div>
        <div className="col-lg-12">
          <button type="button" className="btn btn-success btn-sm mt-2 float-left" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, soPoint)}>
            Show Report
          </button>
        </div>

        {Object.keys(targetDateList).length > 0 && (
          selectZone !== 4 ? (
            <div className="container-fluid mb-3 mt-1 containerFixed" style={{ padding: "0 15px" }}>
              <div className="row" style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
                {/* Target Section */}
                <div className="col-xl-4 col-lg-4 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#f5e4e4", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
                  <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#007bff", fontSize: "14px", lineHeight: "10px" }}>TARGET</h5>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Biscuits Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(targetDateList.biscuitTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cookies Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(targetDateList.cookisTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cake Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(targetDateList.cakeTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Wafer Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(targetDateList.waferTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Snacks Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(targetDateList.snacksTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Chanachur Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(targetDateList.chanachurTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {numberFormatDecemal((
                        parseInt(targetDateList.biscuitTargetPrice) +
                        parseInt(targetDateList.cookisTargetPrice) +
                        parseFloat(targetDateList.cakeTargetPrice) +
                        parseFloat(targetDateList.waferTargetPrice) +
                        parseFloat(targetDateList.snacksTargetPrice) +
                        parseFloat(targetDateList.chanachurTargetPrice)
                      ).toFixed(2))}
                    </span>
                  </div>
                </div>

                {/* IMS Section */}
                <div className="col-xl-4 col-lg-4 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#e9f7f7", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
                  <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#28a745", fontSize: "14px", lineHeight: "10px" }}>PRIMARY</h5>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Biscuits SALES Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(targetDateList.biscuitLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cookies SALES Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(targetDateList.cookisLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cake SALES Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(targetDateList.cakeLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Wafer SALES Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(targetDateList.waferLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Snacks SALES Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(targetDateList.snacksLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Chanachur SALES Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(targetDateList.chanachurLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total SALES Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {numberFormatDecemal((
                        parseFloat(targetDateList.biscuitLiftingPrice) +
                        parseFloat(targetDateList.cookisLiftingPrice) +
                        parseFloat(targetDateList.cakeLiftingPrice) +
                        parseFloat(targetDateList.waferLiftingPrice) +
                        parseFloat(targetDateList.snacksLiftingPrice) +
                        parseFloat(targetDateList.chanachurLiftingPrice)
                      ).toFixed(2))}
                    </span>
                  </div>
                </div>

                {/* Achievement Section */}
                <div className="col-xl-4 col-lg-4 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#fff3cd", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
                  <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#ffc107", fontSize: "14px", lineHeight: "10px" }}>ACHIEVEMENT</h5>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Biscuits Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{parseFloat(((targetDateList.biscuitLiftingPrice) / (targetDateList.biscuitTargetPrice)) * 100).toFixed(2)}%</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cookies Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{parseFloat(((targetDateList.cookisLiftingPrice) / (targetDateList.cookisTargetPrice)) * 100).toFixed(2)}%</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cake Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{parseFloat(((targetDateList.cakeLiftingPrice) / (targetDateList.cakeTargetPrice)) * 100).toFixed(2)}%</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Wafer Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{parseFloat(((targetDateList.waferLiftingPrice) / (targetDateList.waferTargetPrice)) * 100).toFixed(2)}%</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Snacks Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {(() => {
                        const percentage = parseFloat(((targetDateList.snacksLiftingPrice) / (targetDateList.snacksTargetPrice)) * 100);
                        return isNaN(percentage) ? 0 + "%" : `${parseFloat(percentage).toFixed(2)}%`;
                      })()}
                    </span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Chanachur Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {(() => {
                        const percentage = parseFloat(((targetDateList.chanachurLiftingPrice) / (targetDateList.chanachurTargetPrice)) * 100);
                        return isNaN(percentage) ? 0 + "%" : `${parseFloat(percentage).toFixed(2)}%`;
                      })()}
                    </span>
                  </div>
                  <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {(
                        (
                          parseFloat(targetDateList.biscuitLiftingPrice) +
                          parseFloat(targetDateList.cookisLiftingPrice) +
                          parseFloat(targetDateList.cakeLiftingPrice) +
                          parseFloat(targetDateList.waferLiftingPrice) +
                          parseFloat(targetDateList.snacksLiftingPrice) +
                          parseFloat(targetDateList.chanachurLiftingPrice)
                        ) /
                        (
                          parseFloat(targetDateList.biscuitTargetPrice) +
                          parseFloat(targetDateList.cookisTargetPrice) +
                          parseFloat(targetDateList.cakeTargetPrice) +
                          parseFloat(targetDateList.waferTargetPrice) +
                          parseFloat(targetDateList.snacksTargetPrice) +
                          parseFloat(targetDateList.chanachurTargetPrice)
                        ) * 100
                      ).toFixed(2)}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="container-fluid mb-3 mt-3 containerFixed">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="paid__widget one">
                      <div className="widget__left">
                        <p className="widgetTitle">Target</p>
                        <div className="achiveLayer">
                          <span>Bread &Bun Target Value:  {numberFormatDecemal(parseInt(targetDateList?.breadbunTargetPrice))}</span>
                          <span>Fast Cake  Target Value:  {numberFormatDecemal(parseInt(targetDateList?.fastCakeTargetPrice))}</span>
                          <span>Wafer-2 Target Value:  {numberFormatDecemal(parseInt(targetDateList?.wafertwoTargetPrice))}</span>
                          <span>Biscuit-2 Target Value:  {numberFormatDecemal(parseInt(targetDateList?.biscuittwoTargetPrice))}</span>
                          <span>Cookies-(Fast) Target Value:  {numberFormatDecemal(parseInt(targetDateList?.fastCookiesTargetPrice))}</span>
                          <span>Total Target Value:  {numberFormatDecemal(parseInt(targetDateList?.breadbunTargetPrice) + parseInt(targetDateList?.fastCakeTargetPrice) + parseInt(targetDateList?.wafertwoTargetPrice) + parseInt(targetDateList?.biscuittwoTargetPrice) + parseInt(targetDateList?.fastCookiesTargetPrice))}</span>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="paid__widget two">
                      <div className="widget__left">
                        <p className="widgetTitle">Primary Sales</p>
                        <div className="achiveLayer">
                          <span>Bread &Bun Sales Value:  {numberFormatDecemal(parseInt(targetDateList?.breadbunLiftingPrice))}</span>
                          <span>Fast Cake  Sales Value:  {numberFormatDecemal(parseInt(targetDateList?.fastCakeLiftingPrice))}</span>
                          <span>Wafer-2 Sales Value:  {numberFormatDecemal(parseInt(targetDateList?.wafertwoLiftingPrice))}</span>
                          <span>Biscuit-2 Sales Value:  {numberFormatDecemal(parseInt(targetDateList?.biscuittwoLiftingPrice))}</span>
                          <span>Cookies-(Fast) Sales Value:  {numberFormatDecemal(parseInt(targetDateList?.fastCakeLiftingPrice))}</span>
                          <span>Total Sales Value:  {numberFormatDecemal(parseInt(targetDateList?.totalLiftingPrice))}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="paid__widget three">
                      <div className="widget__left">
                        <p className="widgetTitle">Achivement</p>
                        <div className="achiveLayer">
                          <span> Bread &Bun Ach (%):  {parseInt(((targetDateList?.breadbunLiftingPrice) / targetDateList?.breadbunTargetPrice) * 100)}</span>
                          <span>Fast Cake Ach (%):  {parseInt(((targetDateList?.fastCakeLiftingPrice) / targetDateList?.fastCakeTargetPrice) * 100)}</span>
                          <span>Wafer-2 Ach (%):  {parseInt(((targetDateList?.wafertwoLiftingPrice) / targetDateList?.wafertwoTargetPrice) * 100)}</span>
                          <span>Biscuit-2 Ach (%):  {parseInt(((targetDateList?.biscuittwoLiftingPrice) / targetDateList?.biscuittwoTargetPrice) * 100)}</span>
                          <span>Cookies-(Fast) Ach (%):  {parseInt(((targetDateList?.fastCakeLiftingPrice) / targetDateList?.fastCookiesTargetPrice) * 100)}</span>
                          <span>Total Ach (%):
                            {parseInt(((targetDateList?.totalLiftingPrice) /
                              (parseInt(targetDateList?.breadbunTargetPrice) + parseInt(targetDateList?.fastCakeTargetPrice) + parseInt(targetDateList?.wafertwoTargetPrice) + parseInt(targetDateList?.biscuittwoTargetPrice) + parseInt(targetDateList?.fastCookiesTargetPrice))
                            ) * 100)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}






        {/* {Object.keys(targetDateList).length > 0 &&



          <div className="container-fluid mb-3 mt-3 containerFixed">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget one">
                  <div className="widget__left">
                    <p className="widgetTitle">Target</p>
                    <div className="achiveLayer">
                      <span>Biscuit Target Value:  {parseInt(targetDateList?.biscuitTargetPrice)}</span>
                      <span>Cookies  Target Value:  {parseInt(targetDateList?.cookisTargetPrice)}</span>
                      <span>Cake Target Value:  {parseInt(targetDateList?.cakeTargetPrice)}</span>
                      <span>Wafer Target Value:  {parseInt(targetDateList?.waferTargetPrice)}</span>
                      <span>Snacks Target Value:  {parseInt(targetDateList?.snacksTargetPrice)}</span>
                      <span>Total Target Value:  {parseInt(targetDateList?.biscuitTargetPrice) + parseInt(targetDateList?.cookisTargetPrice) + parseInt(targetDateList?.cakeTargetPrice) + parseInt(targetDateList?.waferTargetPrice) + parseInt(targetDateList?.snacksTargetPrice)}</span>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget two">
                  <div className="widget__left">
                    <p className="widgetTitle">Primary Sales</p>
                    <div className="achiveLayer">
                      <span>Biscuit Sales Value:  {parseInt(targetDateList?.biscuitLiftingPrice)}</span>
                      <span>Cookies  Sales Value:  {parseInt(targetDateList?.cookisLiftingPrice)}</span>
                      <span>Cake Sales Value:  {parseInt(targetDateList?.cakeLiftingPrice)}</span>
                      <span>Wafer Sales Value:  {parseInt(targetDateList?.waferLiftingPrice)}</span>
                      <span>Snacks Sales Value:  {parseInt(targetDateList?.snacksLiftingPrice)}</span>
                      <span>Total Sales Value:  {parseInt(targetDateList?.totalLiftingPrice)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="paid__widget three">
                  <div className="widget__left">
                    <p className="widgetTitle">Achivement</p>
                    <div className="achiveLayer">
                      <span>Biscuit Ach (%):  {parseInt(((targetDateList?.biscuitLiftingPrice) / targetDateList?.biscuitTargetPrice) * 100)}</span>
                      <span>Cookies Ach (%):  {parseInt(((targetDateList?.cookisLiftingPrice) / targetDateList?.cookisTargetPrice) * 100)}</span>
                      <span>Cake Ach (%):  {parseInt(((targetDateList?.cakeLiftingPrice) / targetDateList?.cakeTargetPrice) * 100)}</span>
                      <span>Wafer Ach (%):  {parseInt(((targetDateList?.waferLiftingPrice) / targetDateList?.waferTargetPrice) * 100)}</span>
                      <span>Snacks Ach (%):  {parseInt(((targetDateList?.snacksLiftingPrice) / targetDateList?.snacksTargetPrice) * 100)}</span>
                      <span>Total Ach (%):
                        {parseInt(((targetDateList?.totalLiftingPrice) /
                          (parseInt(targetDateList?.biscuitTargetPrice) + parseInt(targetDateList?.cookisTargetPrice) + parseInt(targetDateList?.cakeTargetPrice) + parseInt(targetDateList?.waferTargetPrice) + parseInt(targetDateList?.snacksTargetPrice))
                        ) * 100)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        } */}
        <div className="row" id="id" ref={inputField}>
          {/* <table className="table mt-5 voyageTable table-responsive"> */}
          {isLoading && <LoadingSpinner text="Loading Items List..." />}
          <div className="react-bootstrap-table table-responsive border-0 pl-5 " style={style.container}>

            <div
              className="stickyTable"
              style={{ height: Object.keys(targetDateList).length > 0 ? 800 + "px" : 0 }}
            >
              {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef} > */}
              <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                <thead>
                  <tr>
                    <th scope="col">ZONE</th>
                    <th scope="col">DIVISION</th>
                    <th scope="col">REGION</th>
                    <th scope="col">TERRITORY</th>
                    <th scope="col">DiSPOINT ID</th>
                    <th scope="col">DISPOINT NAME</th>
                    <th scope="col">PRODUCT CATEGORIES</th>
                    <th scope="col">PRODUCT ID</th>
                    <th scope="col">PRODUCT NAME</th>
                    <th scope="col">TARGET QUANTITY(CTN)</th>
                    <th scope="col">TARGET VALUE</th>
                    <th scope="col">SALES QTY</th>
                    <th scope="col">SALES VALUE</th>
                    <th scope="col">DIFFERECE QUANTITY</th>
                    <th scope="col">DIFFERECE VALUE</th>
                    <th scope="col">TARGET VS ACH(IN TK %)</th>
                  </tr>
                </thead>
                <tbody>
                  {targetDateList?.detailsReport?.length > 0 &&

                    targetDateList?.detailsReport?.map((item, index) => (
                      <>
                        <tr className="alert bg-secondary">
                          {/* <td>{item.orderId}</td> */}
                          <td>{item.zoneName}</td>
                          <td>{item.divisionName}</td>
                          <td>{item.areaName}</td>
                          <td>{item.territoryName}</td>
                          <td>{item.disPointID}</td>
                          <td>{item.disName}</td>
                          <td>{item.prodCatagories}</td>
                          <td />
                          <td />
                          <td>{parseFloat(item?.totalReport.targetQty).toFixed(2)}</td>
                          <td>{parseFloat(item?.totalReport.targetPrice).toFixed(2)}</td>
                          <td>{parseFloat(item?.totalReport.liftingQty).toFixed(2)}</td>
                          <td>{parseFloat(item?.totalReport.liftingPrice).toFixed(2)}</td>
                          <td>{parseFloat(item?.totalReport.targetQty).toFixed(2)}</td>
                          <td>{parseFloat(item?.totalReport.differentPrice).toFixed(2)}</td>
                          <td>{parseFloat(item?.totalReport.tarVsAccPers).toFixed(2)}</td>

                        </tr>
                        {
                          item?.itemReport?.map((product, subindex) => (
                            <tr>
                              <td>{item.zoneName}</td>
                              <td>{item.divisionName}</td>
                              <td>{item.areaName}</td>
                              <td>{item.territoryName}</td>
                              <td>{item.disPointID}</td>
                              <td>{item.disName}</td>
                              <td>{product.prodCatagories}</td>
                              <td>{product.prodID}</td>
                              <td>{product.prodName}</td>
                              <td>{parseFloat(product.targetQty).toFixed(2)}</td>
                              <td>{parseFloat(product.targetPrice).toFixed(2)}</td>
                              <td>{parseFloat(product.liftingQty).toFixed(2)}</td>
                              <td>{parseFloat(product.liftingPrice).toFixed(2)}</td>
                              <td>{parseFloat(product.differentQty).toFixed(2)}</td>
                              <td>{parseFloat(product.differentPrice).toFixed(2)}</td>
                              <td>{parseFloat(product.tarVsAccPers).toFixed(2)}</td>
                            </tr>
                          ))
                        }
                      </>
                    ))}
                </tbody>
              </table>
            </div>

            {!isLoading && targetDateList.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! List Not Found.
              </div>
            )}

          </div>
        </div>





      </div>
    </>
  );
};

export default TargetVsAcheivementDispointWisePrimarySales;