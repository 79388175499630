import React, { useEffect, useState } from "react";
import RemoteSalesAdd from "../../../information/components/RemoteSales/RemoteSalesAdd";

const RemoteSalesAddContainer = () => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [eventTriggered, setEventTriggered] = useState(false);

  const calculateTimeLeft = () => {
    const now = new Date();
    const currentTime = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();

    const startTime = 10 * 3600 + 0 * 60;
    const endTime = 17 * 3600 + 0 * 60;

    if (currentTime >= startTime && currentTime <= endTime) {
      if (!eventTriggered) {
        setEventTriggered(true);
      }
      setTimeLeft(endTime - currentTime);
    } else if (currentTime < startTime) {
      const timeToStart = startTime - currentTime;
      setTimeLeft(timeToStart);
    } else {
      setTimeLeft(0); 
      setEventTriggered(false);
    }
  };

  useEffect(() => {
    calculateTimeLeft();

    const intervalId = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [eventTriggered]);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return { hours, minutes, seconds };
  };

  const { hours, minutes, seconds } = formatTime(timeLeft);

  return (
    <div className="container-fluid">
      {/* <div>
        <div className="timer-container mb-2 mt-2">
          <span className="timer-label">Time Left : </span>
          <div className="timer-text">
            <div className="timer-segment hours">
              <span>{hours.toString().padStart(2, "0")}</span>
            </div>
            <span>:</span>
            <div className="timer-segment minutes">
              <span>{minutes.toString().padStart(2, "0")}</span>
            </div>
            <span>:</span>
            <div className="timer-segment seconds">
              <span>{seconds.toString().padStart(2, "0")}</span>
            </div>
          </div>
        </div>

        {eventTriggered && <RemoteSalesAdd />}
      </div> */}
        <RemoteSalesAdd />
    </div>
  );
};

export default RemoteSalesAddContainer;
