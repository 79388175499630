import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import * as XLSX from "xlsx/xlsx.mjs";
import {
    getAreaListByDivisionEntry,
    GetDisPointListByTerritory,
    getDivisionListByZoneEntry,
    getTerritoryEntry,
    getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import {
    cleanSecondarySalesData
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import {
    currentdate
} from "../../../../../domains/CCO/utils/DateHelper";
import {
    getSecondaryStockForExcel,
    getSecondaryStockForShowModified,
} from "../../_redux/actions/Delivery/DeliveryAction";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { FaSearch, FaDownload } from 'react-icons/fa';

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';


const SecondarySalesStockModified = () => {

    const productReport = useSelector((state) => state.delivery.stockReportModified);
    const isLoading = useSelector((state) => state.delivery.stockModifiedLoading);
    const totals = useSelector((state) => state.delivery.totals);

    const [zone, Setzone] = useState([]);
    const [division, SetDivision] = useState([]);
    const [selectZone, setSelectZone] = useState(null);
    const [area, setArea] = useState([]);

    const [selectDivision, setSelectDivision] = useState(null);
    const [selectArea, setSelectArea] = useState(null);
    const [salesType, setSalesType] = useState(0);
    const [distribType, setDistribType] = useState(null);
    const [startDate, setStartDate] = useState(null);

    const [defultZone, setDefaultZone] = useState({});
    const [defultDivision, setdefultDivision] = useState({});
    const [defaultArea, setdefaultArea] = useState({});
    const [defultTerritory, setdefultTerritory] = useState({});

    const [dispoint, setDisPointData] = useState([]);
    const [soPoint, setSelectSoPoint] = useState(null);


    const [reportType, setReportType] = useState([
        {
            id: 1,
            name: "Distributor",
        },
        {
            id: 2,
            name: "Point Wise",
        },
    ]);
    const componentRef = useRef();

    const { register, setValue } = useForm();

   
    const dispatch = useDispatch();

    // items list with pagination
    useEffect(() => {
        // dispatch(getSalesList());
        const userData = localStorage.getItem("userData");
        const user = JSON.parse(userData);
        setSalesType(user.employeeType);
        setDistribType(user.employeeType);
        dispatch(cleanSecondarySalesData());
        initialData();

    }, []);

    const initialData = async () => {
       

        var permissionwise = await GetPermissionDropDown()
            .then((res) => {
                console.log('res data', res);
                const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, selectGeoType } = res.data;

                setDefaultZone({ label: zoneName, value: zoneId });
                setdefultDivision({ label: divisionName, value: divisionId });
                setdefaultArea({ label: areaName, value: araId });
                setdefultTerritory({ label: territoryName, value: territoryId });

                setSelectZone(zoneId);
                setSelectDivision(divisionId);
                setSelectArea(araId);
                setSelectTerritory(territoryId);
                GetDisPointList(territoryId);
                divisionList(zoneId);
                getAreaData(divisionId);
                getTerritoryData(araId);

            });
        let zoneData = await getZoneList();
        Setzone(zoneData.data);
    }

    const zoneDropdownData = () => {
        let options = [];
        if (zone?.length > 0) {
            zone.forEach((item) => {
                let itemData = {
                    value: item.zoneID,
                    label: item.zoneName,
                };

                options.push(itemData);
            });
        }
        // setValue("divisionData", "");

        return options;
    };
    const reportTypeDropdownData = () => {
        let options = [];
        if (reportType?.length > 0) {
            reportType.forEach((item) => {
                let itemData = {
                    value: item.id,
                    label: item.name,
                };

                options.push(itemData);
            });
        }

        return options;
    };

    const divisionList = async (id) => {
        let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
        SetDivision(divisionData?.data);
    }
    const getAreaData = async (id) => {
        let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
        setArea(areaData?.data);
    }
    const getTerritoryData = async (id) => {
        let territoryData = await getTerritoryEntry(id, selectGeoType);
        setTerritory(territoryData?.data);
    }

    const divisionDropdownData = () => {
        let options = [];
        if (division?.length > 0) {
            division.forEach((item) => {
                let itemData = {
                    value: item.divisionID,
                    label: item.divisionName,
                };

                options.push(itemData);
            });
        }

        return options;
    };
    const areaDropdownData = () => {
        let options = [];
        if (area?.length > 0) {
            area.forEach((item) => {
                let itemData = {
                    value: item.areaID,
                    label: item.areaName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

 
    const inputField = React.useRef(null);

    const handleChange = ({ currentTarget: input }) => {
        setStartDate(input.value);
    };
    const handleListAction = (
        fromDate,
        idZone,
        idDivision,
        idArea,
        territoryId
    ) => {

        dispatch(
            getSecondaryStockForShowModified(
                fromDate,
                idZone,
                idDivision,
                idArea,
                territoryId,
                3,
                soPoint
            )
        );
    };

    const [selectGeoType, setSelectGeoType] = useState(2);

    const [terriory, setTerritory] = useState([]);
    const [selectTerritory, setSelectTerritory] = useState(null);


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const hanleExcelClick = async () => {
        setLoading(true);
        setError(null);
      
        try {
            let excelClick = await getSecondaryStockForExcel(
                startDate,
                selectZone,
                selectDivision,
                selectArea,
                selectTerritory,
                3,
                0
            );
      
          const current = new Date().toISOString().slice(0, 10);
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Sheet1');
      
          worksheet.columns = [
            { header: 'Zone ID', key: 'zoneID', width: 10 },
            { header: 'Zone Name', key: 'zoneName', width: 20 },
            { header: 'Division ID', key: 'divisionID', width: 10 },
            { header: 'Division Name', key: 'divisionName', width: 20 },
            { header: 'Region ID', key: 'regionId', width: 10 },
            { header: 'Region Name', key: 'regionName', width: 20 },
            { header: 'Territory ID', key: 'territoryId', width: 10 },
            { header: 'Territory Name', key: 'territoryName', width: 20 },
            { header: 'Dis Point ID', key: 'disPointID', width: 10 },
            { header: 'Dis Point Name', key: 'disPointName', width: 20 },
            { header: 'Product ID', key: 'productID', width: 20 },
            { header: 'Product Name', key: 'productName', width: 40 },
            { header: 'Prod Cat ID', key: 'prodCatID', width: 10 },
            { header: 'Prod Catagories', key: 'prodCatName', width: 20 },
            { header: 'Group Name', key: 'salesGroup', width: 20 },
            { header: 'Brand Name', key: 'brandName', width: 20 },
            { header: 'Pack Type Name', key: 'packTypeName', width: 20 },
            { header: 'Uom', key: 'productUom', width: 20 },
            { header: 'Price', key: 'productPrice', width: 20 },
           
            { header: 'Opening Qty', key: 'productOpeningPCQty', width: 15 },
            { header: 'Opening Ctn Qty', key: 'productOpeningCTQty', width: 15 },
            { header: 'Opening Value', key: 'productOpeningPrice', width: 15 },

            { header: 'Lifting Qty', key: 'productLiftingPCQty', width: 15 },
            { header: 'Lifting Ctn Qty', key: 'productLiftingCTQty', width: 15 },
            { header: 'Lifting Value', key: 'productLiftingPrice', width: 15 },

            { header: 'IMS Pcs Qty', key: 'productIMS_PCQty', width: 15 },
            { header: 'IMS Ctn Qty', key: 'productIMS_CTQty', width: 15 },
            { header: 'IMS Value', key: 'productIMSPrice', width: 15 },

            { header: 'Clocing Qty', key: 'productClosingPCQty', width: 15 },
            { header: 'Clocing Ctn Qty', key: 'productClosingCTQty', width: 15 },
            { header: 'Clocing Value', key: 'productClosingPrice', width: 15 },

            { header: 'Damage Qty', key: 'productDMG_PCQty', width: 15 },
            { header: 'Damage Ctn Qty', key: 'productDMG_CTQty', width: 15 },
            { header: 'Damage Value', key: 'productDMGPrice', width: 15 },

            { header: 'Net Stock Qty', key: 'productNet_PCQty', width: 15 },
            { header: 'Net Stock Ctn', key: 'productNet_CTQty', width: 15 },
            { header: 'Net Stock Value', key: 'productNetPrice', width: 15 },

           
          ];
      
          worksheet.addRows(excelClick?.data || []);
    
          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
      
          saveAs(
            blob,
            `Product Stock Report - ${startDate} - Download Date - ${current}.xlsx`
          );
        } catch (error) {
          console.error('Error:', error);
          setError('Try Again..!');
        } finally {
          setLoading(false);
        }
      };











    const territoryDropdownData = () => {
        let options = [];
        if (terriory?.length > 0) {
            terriory.forEach((item) => {
                let itemData = {
                    value: item.territoryId,
                    label: item.territoryName,
                };

                options.push(itemData);
            });
        }
        return options;
    };

    const dispointDropdownData = () => {
        let options = [];
        if (dispoint.data?.length > 0) {
          dispoint.data.forEach((item) => {
            let itemData = {
              value: item.disPointID,
              label: item.disPointName,
            };
    
            options.push(itemData);
          });
        }
        return options;
      };

      const GetDisPointList = async (id) => {
        let disData = await GetDisPointListByTerritory(id);
        setDisPointData(disData);
      }

    return (
        <>
            <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
                <div className="row mt-5">
                    <div className="col-lg-9">
                        <div className="mt-2 p-2">
                            <span className="mb-0 pb-0 pageheadingText">Product Stock(Secondary)</span>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="mt-2 p-2 float-right">
                            <Button
                                size="sm"
                                onClick={hanleExcelClick}
                                disabled={loading}
                                className={loading ? 'bg-danger' : ''}
                            >
                                <FaDownload /> {loading ? 'Loading...' : 'Download'}
                            </Button>
                            {error && <div>Error: {error}</div>}
                            <DownloadDropdown excelname='Product Stock(Secondary)' />
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="form-group row">
                    <div className="col-md-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Select Date</label>
                            <Form.Control
                                type="date"
                                placeholder="Enter Deadweight"
                                name="fromDate"
                                value={startDate}
                                className="fromStyle"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Zone</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={zoneDropdownData()}
                                value={defultZone}
                                onChange={(option) => {
                                    divisionList(option?.value);
                                    setSelectZone(option?.value);
                                    setDefaultZone(option);
                                    setdefultDivision(null);
                                    setdefaultArea(null);
                                    setdefultTerritory(null);
                                    setValue("divisionData", "");
                                    setValue("areaData", "");
                                    setValue("territory", "");

                                }}
                                defaultValue={defultZone}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Division</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={divisionDropdownData()}
                                value={defultDivision}
                                onChange={(option) => {
                                    getAreaData(option?.value);
                                    setSelectDivision(option?.value);
                                    setdefultDivision(option);
                                    setdefaultArea(null);
                                    setdefultTerritory(null);
                                    setValue("areaData", "");
                                    setValue("territory", "");
                                }}
                                defaultValue={defultDivision}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3 mt-0">
                        <label className="form-label ml-4">Select Region</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={areaDropdownData()}
                                value={defaultArea}
                                onChange={(option) => {
                                    getTerritoryData(option?.value);
                                    setSelectArea(option?.value);
                                    setdefaultArea(option);
                                    setdefultTerritory(null);
                                    setValue("territory", "");
                                }}
                                defaultValue={defaultArea}
                            />
                        </Form.Group>
                    </div>


                    <div className="col-lg-3">
                        <label className="form-label ml-4">Select Territory</label>
                        <Form.Group as={Col} controlId="formGridState">
                            <Select
                                options={territoryDropdownData()}
                                value={defultTerritory}
                                onChange={(option) => {
                                    //getTerritoryData(option?.value);
                                    setSelectTerritory(option?.value);
                                    setdefultTerritory(option)
                                    GetDisPointList(option?.value);
                                }}
                                defaultValue={defultTerritory}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Dis Point</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={dispointDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectSoPoint(option?.value);
                  setValue("territory", "");

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
                    <div className="col-lg-3 mt-5 mb-5 ">
                        <Button size="sm" onClick={() =>
                            handleListAction(
                                startDate,
                                selectZone,
                                selectDivision,
                                selectArea,
                                selectTerritory
                            )} > <FaSearch /> Show Report</Button>

                    </div>

                </div>
                <div>

                    <div>
                        {productReport?.length > 0 && (
                            <div className="container-fluid mb-3 mt-3 containerFixed">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-3">
                                        <div className="paid__widget four">
                                            <div className="widget__left">
                                                <p className="balanceleft">Opening Qty Pcs</p>
                                                <h4 className="balanceleft">
                                                    {Math.round(totals?.totalProductOpeningPCQty)}
                                                </h4>
                                            </div>
                                            <div className="widget__right">
                                                <div>
                                                    {/* <h4 className="balanceleft">৳ {Math.round(totals.t_ProductOpeningPCQty)}</h4> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-3">
                                        <div className="paid__widget three">
                                            <div className="widget__left">
                                                <p className="balanceleft">Lifting Pcs Qty</p>
                                                <h4 className="balanceleft">
                                                    {Math.round(totals?.totalproductLiftingPCQty)}
                                                </h4>
                                            </div>
                                            <div className="widget__right">
                                                <div>
                                                    {/* <h4 className="balanceleft">৳ {Math.round(totals.t_ProductLiftingPCQty)}</h4> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3">
                                        <div className="paid__widget two">
                                            <div className="widget__left">
                                                <p className="balanceleft">Ims PCS Qty</p>
                                            </div>

                                            <div>
                                                <h4 className="balanceleft">
                                                    {Math.round(totals?.totalproductIMS_PCQty)}
                                                </h4>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-3">
                                        <div className="paid__widget one">
                                            <div className="widget__left">
                                                <p className="balanceleft">Closing PCS Qty</p>
                                                <h4 className="balanceleft">
                                                    {Math.round(totals?.totalproductClosingPCQty)}
                                                </h4>
                                            </div>
                                            <div className="widget__right">
                                                <div className="overlay-layer bg-transparent"></div>
                                                <div>
                                                    {/* <h4 className="balanceleft">৳ {Math.round(totals.t_ProductClosingCTQty)}</h4> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}



                    </div>




                    <div className="row" id="id" ref={inputField}>

                        <div className="table-responsive pl-5 pr-5 pb-5 ">
                            {isLoading ? (
                                <LoadingSpinner text="Loading Items List..." />
                            ) : productReport?.length > 0 ? (
                                <div
                                    className="stickyTable"
                                    style={{ height: productReport?.length > 0 ? 800 + "px" : 0 }}
                                >
                                    <table className="printtablenew1" id="table-to-xls" ref={componentRef}>
                                        <thead>
                                            <tr >
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">ZONE</th>
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">DIVISION</th>
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">REGION</th>
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">TERRITORY</th>
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">DISTRIBUTOR POINT ID</th>
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">DISTRIBUTOR POINT</th>
                                                
                                                {/* <th scope="col">PRODUCT ID</th> */}
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">PRODUCT NAME</th>
                                                {/* <th scope="col">PRODUCT CATEGORY</th>
                                                <th scope="col">GROUP NAME</th>
                                                <th scope="col">BRAND NAME</th>
                                                <th scope="col">PACK NAME</th>
                                                <th scope="col">PRODUCT UOM</th>
                                                <th scope="col">OPENING(PCS)</th> */}
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">OPENING(CTN)</th>
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">OPENING(VALUE)</th>
                                                {/* <th scope="col">LIFING (PCS)</th> */}
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">LIFING (CTN)</th>
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">LIFING (VALUE)</th>
                                                {/* <th scope="col">IMS (PCS)</th> */}
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">IMS (CTN)</th>
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">IMS (VALUE)</th>

                                                {/* <th scope="col">CLOSING (PCS)</th> */}
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">CLOSING(CTN)</th>
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">CLOSING(VALUE)</th>

                                                {/* <th scope="col">DAMAGE(PCS)</th> */}
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">DAMAGE(CTN)</th>
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">DAMAGE(VALUE)</th>

                                                {/* <th scope="col">Net Stock(PCS)</th> */}
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">Net Stock(CTN)</th>
                                                <th style={{ color: 'black', backgroundColor: '#CCCCCC', textTransform: 'uppercase'}} scope="col">Net Stock(VALUE)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productReport?.length > 0 &&
                                                productReport?.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr className="alert bg-secondary">
                                                            <td>{item?.zoneName}</td>
                                                            <td>{item?.divisionName}</td>
                                                            <td>{item?.regionName}</td>
                                                            <td>{item?.territoryName}</td>
                                                            <td>{item?.disPointID}</td>
                                                            <td>{item?.disPointName}</td>
                                                            {/* <td /> */}
                                                            <td />
                                                            {/* <td />
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td>{Math.round(item?.distributorSums.productOpeningPCQty)}</td> */}
                                                            <td>{Math.round(item?.distributorSums.productOpeningCTQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productOpeningPrice)}</td>

                                                            {/* <td>{Math.round(item?.distributorSums.productLiftingPCQty)}</td> */}
                                                            <td>{Math.round(item?.distributorSums.productLiftingCTQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productLiftingPrice)}</td>

                                                            {/* <td>{Math.round(item?.distributorSums.productIMS_PCQty)}</td> */}
                                                            <td>{Math.round(item?.distributorSums.productIMS_CTQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productIMSPrice)}</td>

                                                            {/* <td>{Math.round(item?.distributorSums.productClosingPCQty)}</td> */}
                                                            <td>{Math.round(item?.distributorSums.productClosingCTQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productClosingPrice)}</td>

                                                            {/* <td>{Math.round(item?.distributorSums.productDMG_PCQty)}</td> */}
                                                            <td>{Math.round(item?.distributorSums.productDMG_CTQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productDMGPrice)}</td>

                                                            {/* <td>{Math.round(item?.distributorSums.productNet_PCQty)}</td> */}
                                                            <td>{Math.round(item?.distributorSums.productNet_CTQty)}</td>
                                                            <td>{Math.round(item?.distributorSums.productNetPrice)}</td>


                                                        </tr>
                                                        {item?.Details?.length > 0 &&
                                                            item?.Details?.map((detail, subindex) => (
                                                                <tr key={subindex}>
                                                                    <td>{detail?.zoneName}</td>
                                                                    <td>{detail?.divisionName}</td>
                                                                    <td>{detail?.regionName}</td>
                                                                    <td>{detail?.territoryName}</td>
                                                                    <td>{detail?.disPointID}</td>
                                                                    <td>{detail?.disPointName}</td>
                                                                    {/* <td>{detail?.productID}</td> */}
                                                                    <td>{detail?.productName}</td>
                                                                    {/* <td>{detail?.prodCatName}</td>
                                                                    <td>{detail?.prodGroup}</td>
                                                                    <td>{detail?.brandName}</td>
                                                                    <td>{detail?.packTypeName}</td>
                                                                    <td>{detail?.productUom}</td>
                                                                    <td>
                                                                        {Math.round(detail?.productOpeningPCQty)}
                                                                    </td> */}
                                                                    <td>
                                                                        {Math.round(detail?.productOpeningCTQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productOpeningPrice)}
                                                                    </td>

                                                                    {/* <td>
                                                                        {Math.round(detail?.productLiftingPCQty)}
                                                                    </td> */}
                                                                    <td>
                                                                        {Math.round(detail?.productLiftingCTQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productLiftingPrice)}
                                                                    </td>



                                                                    {/* <td>
                                                                        {Math.round(detail?.productIMS_PCQty)}
                                                                    </td> */}
                                                                    <td>
                                                                        {Math.round(detail?.productIMS_CTQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productIMSPrice)}
                                                                    </td>
                                                                    {/* <td>
                                                                        {Math.round(detail?.productClosingPCQty)}
                                                                    </td> */}
                                                                    <td>
                                                                        {Math.round(detail?.productClosingCTQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productClosingPrice)}
                                                                    </td>

                                                                    {/* <td>
                                                                        {Math.round(detail?.productDMG_PCQty)}
                                                                    </td> */}
                                                                    <td>
                                                                        {Math.round(detail?.productDMG_CTQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productDMGPrice)}
                                                                    </td>

                                                                    {/* <td>
                                                                        {Math.round(detail?.productNet_PCQty)}
                                                                    </td> */}
                                                                    <td>
                                                                        {Math.round(detail?.productNet_CTQty)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.round(detail?.productNetPrice)}
                                                                    </td>



                                                                </tr>
                                                            ))}
                                                    </React.Fragment>
                                                ))}
                                        </tbody>
                                    </table>


                                </div>
                            ) : (
                                <div className="text-center p-10">No data available</div>
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default SecondarySalesStockModified;
