import { indexOf } from "lodash";
import * as Types from "../../types/Types";

const initialState = {
  selectArea: null,
  distributorReport: [],
  isLoading: false,
  disBalance: 0,
  disBRBalance: 0,
  disTotalBalance: 0,
  distributorBrReport: [],
  disBrTotalBalance: 0,
};
const DistributorReportReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {

    case Types.GET_DISTRIBUTOR_ZONE_LIST:
      return {
        ...state,
        distributorList: getZoneDrodownData(action.payload.data),
      };
    case Types.GET_DISTRIBUTOR_DAILY_BALANCE:
      let sumBalnce = 0;
      let totalBalance = 0;

      for (let i = 0; i < action.payload.data.length; i++) {

        sumBalnce += action.payload.data[i].distrDailyBalance;
        totalBalance += action.payload.data[i].disTotalBalance;

      }
      return {
        ...state,
        distributorReport: action.payload.data,
        isLoading: action.payload.isLoading,
        disBalance: sumBalnce,
        disTotalBalance: totalBalance,
      };
      // case Types.GET_DISTRIBUTOR_DAILY_BR_BALANCE:
      //   let sumBrBalnce = 0;
      //   let totalBrBalance = 0;
    
      //   const filteredData = action.payload.data.filter(item => item.bankReceive > 0);
      //   sumBrBalnce = filteredData.reduce((sum, item) => sum + item.bankReceive, 0);
      //   totalBrBalance = action.payload.data.reduce((sum, item) => sum + item.netBalance, 0);
      
      //   return {
      //     ...state,
      //     distributorBrReport: filteredData,
      //     isLoading: action.payload.isLoading,
      //     disBrBalance: sumBrBalnce,
      //     disBrTotalBalance: totalBrBalance,
      //   };

      case Types.GET_DISTRIBUTOR_DAILY_BR_BALANCE:
  let sumBrBalnce = 0;
  let totalBrBalance = 0;
  let totalBrBalance1 = 0;
  let totalBrBalance2 = 0;

  const filteredData1 = action.payload.data.filter(item => item.bankReceive > 0);
  const filteredData2 = action.payload.data.filter(item => item.netBalance > 0);
  const filteredData3 = action.payload.data.filter(item => item.netBalance < 0); // Filter out items where netBalance is greater than 0
  sumBrBalnce = filteredData1.reduce((sum, item) => sum + item.bankReceive, 0);
  totalBrBalance1 = filteredData2.reduce((sum, item) => sum + item.netBalance, 0); // Sum only for filtered data
  totalBrBalance2 = filteredData3.reduce((sum, item) => sum + item.netBalance, 0);
  
  totalBrBalance = totalBrBalance1 +  totalBrBalance2

  return {
    ...state,
    distributorBrReport: action.payload.data,
    isLoading: action.payload.isLoading,
    disBrBalance: sumBrBalnce,
    disBrTotalBalance: totalBrBalance,
  };

      case Types.GET_DISTRIBUTOR_BALANCE_DEMAND_DELIVERY:
  let sumBrBalnceNew = 0;
  let totalBrBalanceNew = 0;
  let totalBrBalance1New = 0;
  let totalBrBalance2New = 0;

  const filteredData1New = action.payload.data.filter(item => item.bankReceive > 0);
  const filteredData2New = action.payload.data.filter(item => item.netBalance > 0);
  const filteredData3New = action.payload.data.filter(item => item.netBalance < 0); 
  sumBrBalnceNew = filteredData1New.reduce((sum, item) => sum + item.bankReceive, 0);
  totalBrBalance1New = filteredData2New.reduce((sum, item) => sum + item.netBalance, 0);
  totalBrBalance2New = filteredData3New.reduce((sum, item) => sum + item.netBalance, 0);
  
  totalBrBalanceNew = totalBrBalance1New +  totalBrBalance2New

  return {
    ...state,
    distributorBrReport: action.payload.data,
    isLoading: action.payload.isLoading,
    disBrBalance: sumBrBalnceNew,
    disBrTotalBalance: totalBrBalanceNew,
  };

      
    case Types.CHANGE_AREA_SELECT: ;
      return {
        ...state,
        selectArea: action.payload.isLoading,
      };
    default:
      break;
  }
  return newState;
};

export default DistributorReportReducer;

const getZoneDrodownData = (data) => {

  let options = [];
  if (data) {
    data.forEach((item) => {

      let itemData = {
        value: item.intcusid,
        label: item.strname,
      };
      options.push(itemData);
    });
  }
  return options;
};
