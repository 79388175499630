import * as Types from "./DashboardType";
const initialState = {
  monthtomonthLoading: false,
  locationLoading: false,
  toCardLoading: false,
  donutLoading: false,
  userInfo: {},
  employeeSearch: [],
  ddlUnit: [],
  dashboardData: null,
  ablData: [],
  targetQty: [],
  deliveryPrice: [],
  secondaryPrice: [],
  Primaryachivement: 0,
  Secondaryachivement: 0,
  ablprice: [],
  ablpricepercentage: [],
  abldate: [],
  currDays: 0,
  ttldays: 0,
  ddcategorytype: [],
  ddproducttype: [],
  cumulativeRev: [],
  anualRev: [],
  ddnsmtype: [],
  dddsmtype: [],
  ddrmtype: [],
  ddtsmtype: [],
  anualablprice: [],
  anualabldate: [],
  anualabldata: [],
  anualgrowth: [],
  cumulativeMonth: [],
  linetype: [],
  cumSecondarydaryDiff: [],
  productGroupType: [],
  TrendValue: [],
  TrendValuePercentage: [],
  damagedata: [],
  damageMonthData: [],
  LocationWisePieData: [],
  zoneData: [],
  zoneDataForDonut: [],
  donutData: [],
  productionLine: [],
  total: 0,
  proLinebyPro: [],
  proListbyGroup: [],
  totalForDonut: 0,
  itemDataInput: {
    ZoneID: null,
    DivisionID: null,
    AreaID: null,
    Territory: null,
    targetyear: null,
    targetMonth: null,
    Fdate: null,
    Tdate: null,
    measurement: null,
  },
  reportData: {
    ZoneID: " ",
    DivisionID: " ",
    AreaID: " ",
    Territory: " ",
    targetyear: " ",
    targetMonth: " ",
    Fdate: " ",
    Tdate: " ",
    measurement: 1,
    categoryName: "",
    lineName: "",
  },
  itemDataInput: {
    itemName: "",
    quantity: "",
    intDistributorId: "",
    distributorName: "",
    submitProductList: [],
  },
  chartData: [],
  chartData2: [],
  locationTotalQty: 0,
  workingDayListCount: 0,
  achievementStatus: {},
  imsNotification:[],
  AllProductList:[]
};
const DashboardCartReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.CHANGE_PRODUCT_INPUT:
      const itemDataInput = { ...state.itemDataInput };
      itemDataInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        itemDataInput,
      };

    case Types.CHANGE_HANDLE_DATE_MANAGE_FOR_DASHBOARD:
      var getYear = action.payload.name.substring(0, 4);
      var getMonth = action.payload.name.substring(5, 7);
      var lstday = new Date(getYear, getMonth, 0).getDate();
      var fromDate = `${action.payload.name}` + "-" + "01";
      var toDate = `${action.payload.name}` + "-" + lstday;
      const getDate = { ...state.reportData };
      getDate.fromDate = fromDate;
      getDate.toDate = toDate;
      getDate.targetMonth = parseInt(getMonth);
      getDate.targetyear = getYear;
      return {
        ...state,
        reportData: getDate,
      };

    case Types.GET_DASHBOARD_DATA:
      var conArray = [];
      var secondarySalesListArray = [];
      // var primaryachivementCal =
      //   (action.payload.data.totalDeliveryValue /
      //     action.payload.data.totalPrimaryTargetValue) *
      //   100;
      var primaryachivementCal =
      (action.payload.data?.totalPrimaryValue /
        action.payload.data?.totalPrimaryTargetValue) *
      100;
      
      var secondaryachivementCal =
        (action.payload.data?.totalSecondaryValue /
          action.payload.data?.totalSecondaryTargetValue) *
        100;

      const dateWisedeliveryList = action.payload.data?.deliveryList;
      

      for (let i = 0; i < dateWisedeliveryList?.length; i++) {
        conArray.push(
          state.reportData.measurement == 1
            ? dateWisedeliveryList[i].prodPrice
            : dateWisedeliveryList[i].prodQty
        );
        // conArray.push(dateWisedeliveryList[i].prodPrice);
      }
      const dateWisesecondarySalesList = action.payload.data?.secondarySalesList;
      for (let i = 0; i < dateWisesecondarySalesList?.length; i++) {
        secondarySalesListArray.push(
          state.reportData.measurement == 1
            ? dateWisesecondarySalesList[i].prodPrice
            : dateWisesecondarySalesList[i].prodQty
        );
        //secondarySalesListArray.push(dateWisesecondarySalesList[i].prodPrice);
      }
      //    const convertArray = Object.values(dateWisesecondarySalesList);

      return {
        ...state,
        toCardLoading: action.payload.isLoading,
        dashboardData: action.payload.data,
        deliveryPrice: conArray,
        targetQty: conArray,
        secondaryPrice: secondarySalesListArray,
        Primaryachivement: Math.floor(parseFloat(primaryachivementCal) + 0.5),
        Secondaryachivement: Math.floor(parseFloat(secondaryachivementCal) + 0.5),
        currDays: action.payload.data?.currentDays,
        ttldays: action.payload.data?.totalDays,
      };
    // case Types.ABL_MONTH_WISE_DATA_FOR_DASHBOARD:
    //     var ablMonthPriceArray = []
    //     const ablMonthWiseList = action.payload.data?.secondaryList;
    //     for (let i = 0; i < ablMonthWiseList.length; i++) {
    //         ablMonthPriceArray.push(parseInt(ablMonthWiseList[i].prodPrice));
    //         //ablMonthPriceArray.push(ablMonthWiseList[i].prodPrice);

    //     }

    //     //cumulative sum
    //     var firstIndex = action.payload.data.secondaryList[0] != undefined ? parseInt(action.payload.data.secondaryList[0].cumulativePrice) : 0
    //     //const cumulativeSum = arr => {
    //     let result = [firstIndex];
    //     for (let i = 1; i < action.payload.data?.secondaryList.length; i++) {
    //         result.push(parseInt(action.payload.data?.secondaryList[i].cumulativePrice + result[i - 1]));
    //     }

    //     //}

    //     var ablMonthDateArray = [];
    //     const monthNames = ["", "January", "February", "March", "April", "May", "June",
    //         "July", "August", "September", "October", "November", "December"
    //     ];
    //     const ablMonthList = action.payload.data.secondaryList;
    //     for (let i = 0; i < ablMonthList.length; i++) {
    //         ablMonthDateArray.push(monthNames[ablMonthList[i].doDate]);

    //     }

    //     return {
    //         ...state,
    //         toCardLoading: action.payload.status,
    //         ablData: action.payload.data,
    //         ablprice: ablMonthPriceArray,
    //         abldate: ablMonthDateArray,
    //         cumulativeRev: result
    //     };

    case Types.ABL_SALES_SINCE_INCEPTION_FOR_DASHBOARD:
      // Month To Month Secondary Sales Trend start
      let tredndingIndex = 0;
      let TrendingValueStore = [0];
      if (state.reportData.measurement == 1) {
        tredndingIndex =
          action.payload.data?.monthToMonth?.length > 0
            ? action.payload.data?.monthToMonth != undefined
              ? action.payload.data?.monthToMonth[0].cumulativePrice
              : 0
            : 0;
      } else {
        tredndingIndex =
          action.payload.data?.monthToMonth?.length > 0
            ? action.payload.data?.monthToMonth != undefined
              ? action.payload.data?.monthToMonth[0].prodQty
              : 0
            : 0;
      }


      var ablMonthPriceArrayPercentage = [0];
      const ablMonthWiseList = action.payload.data?.monthToMonth;

      var trendResult = [0];
      var trendResultPercentage = [0];

      var ablMonthPriceArray = [];

      for (let i = 1; i < ablMonthWiseList?.length; i++) {
        ablMonthPriceArray.push(
          state.reportData.measurement == 1
            ? ablMonthWiseList[i].prodPrice
            : ablMonthWiseList[i].prodQty
        );
       
        // ablMonthPriceArrayPercentage.push((state.reportData.measurement == 1 ? ablMonthWiseList[i].prodPrice/100 : ablMonthWiseList[i].prodQty/100));
        ablMonthPriceArrayPercentage.push(
          parseInt(
            ((ablMonthPriceArray[i] - ablMonthPriceArray[i - 1]) /
              ablMonthPriceArray[i - 1]) *
            100
          )
        );
      }

      let firstIndex = 0;
      if (state.reportData.measurement == 1) {
        firstIndex =
          action.payload.data?.cumulativeRevenueLastTwelve?.length > 0
            ? action.payload.data?.cumulativeRevenueLastTwelve != undefined
              ? action.payload.data?.cumulativeRevenueLastTwelve[0]
                .cumulativePrickie
              : 0
            : 0;
      } else {
        firstIndex =
          action.payload.data?.cumulativeRevenueLastTwelve?.length > 0
            ? action.payload.data?.cumulativeRevenueLastTwelve != undefined
              ? action.payload.data?.cumulativeRevenueLastTwelve[0].prodQty
              : 0
            : 0;
      }
      //cumulative sum
      var firstIndexCum = action.payload.data?.cumulativeRevenueLastTwelve?.length > 0 ? action.payload.data?.cumulativeRevenueLastTwelve != undefined ? action.payload.data?.cumulativeRevenueLastTwelve[1].cumulativePrice : 0 : 0;
      //const cumulativeSum = arr => {
      let result = [firstIndexCum];
      let cumSecondarydaryDiff = [0];

      let cumRe =
        action.payload.data?.cumulativeRevenueLastTwelve?.cumulativePrice;
      for (
        let i = 2;
        i < action.payload.data?.cumulativeRevenueLastTwelve?.length;
        i++
      ) {
        //result.push(parseInt(action.payload.data?.cumulativeRevenueLastTwelve[i]?.cumulativePrice + result[i - 1]));
        result.push(
          state.reportData.measurement == 1
            ? action.payload.data?.cumulativeRevenueLastTwelve[i]
              ?.cumulativePrice + result[i - 2]
            : action.payload.data?.cumulativeRevenueLastTwelve[i]?.prodQty +
            result[i - 2]
        );
        cumSecondarydaryDiff.push(
          parseInt(((result[i] - result[i - 1]) / result[i - 1]) * 100)
        );
        // console.log('trendResult', trendResult)
        // trendResult.push(
        //   (ablMonthPriceArray[i] - ablMonthPriceArray[i - 1]).toFixed(2)
        // );
        // trendResult.push(
        //   parseInt(ablMonthPriceArray[i] - ablMonthPriceArray[i - 1])
        // );
        // trendResultPercentage.push(
        //   parseInt(
        //     ((ablMonthPriceArray[i] - ablMonthPriceArray[i - 1]) /
        //       ablMonthPriceArray[i - 1]) *
        //     100
        //   )
        // );
      }
      for (
        let i = 1;
        i < action.payload.data?.cumulativeRevenueLastTwelve?.length;
        i++
      ) {
        console.log('trendResult', trendResult)
        trendResult.push(
          (ablMonthPriceArray[i] - ablMonthPriceArray[i - 1]).toFixed(2)
        );
        // trendResult.push(
        //   parseInt(ablMonthPriceArray[i] - ablMonthPriceArray[i - 1])
        // );
        trendResultPercentage.push(
          parseInt(
            ((ablMonthPriceArray[i] - ablMonthPriceArray[i - 1]) /
              ablMonthPriceArray[i - 1]) *
            100
          )
        );
      }
      //}
      var ablMonthDateArray = [];
      const monthNames = [
        "",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const ablMonthList = action.payload.data?.monthToMonth;
      for (let i = 1; i < ablMonthList?.length; i++) {
        ablMonthDateArray.push(monthNames[ablMonthList[i]?.doDate]);
      }
      //cumulative month
      var ablcummonth = [];
      const ablmonthlistforcum =
        action.payload.data?.cumulativeRevenueLastTwelve;
      for (let i = 0; i < ablmonthlistforcum?.length; i++) {
        ablcummonth.push(monthNames[ablmonthlistforcum[i]?.doDate]);
      }

      // Month To Month Secondary Sales Trend end

      // Annual Sales Since Inception Start
      var ablanualprice = [];
      const ablanualList = action.payload.data?.anualSalesSinceInception;
      for (let i = 0; i < ablanualList?.length; i++) {
        //ablanualprice.push(parseInt(ablanualList[i].prodPrice));
        ablanualprice.push(
          parseInt(
            state.reportData.measurement == 1
              ? ablanualList[i].prodPrice
              : ablanualList[i].prodQty
          )
        );
      }
      var ablyearListArray = [];
      const ablyearList = action.payload.data?.anualSalesSinceInception;
      for (let i = 0; i < ablyearList?.length; i++) {
        ablyearListArray.push(ablyearList[i]?.doDate);
      }

      var ablGrowthArray = [0];
      const ablGrowthList = action.payload.data?.anualSalesSinceInception;
      for (let i = 1; i < ablanualprice?.length; i++) {
        ablGrowthArray.push(
          parseInt(
            ((ablanualprice[i] - ablanualprice[i - 1]) / ablanualprice[i - 1]) *
            100
          )
        );
      }

      return {
        ...state,
        //month to month start
        monthtomonthLoading: action.payload.isLoading,
        ablData: action.payload.data,
        ablprice: ablMonthPriceArray,
        ablpricepercentage: ablMonthPriceArrayPercentage,
        abldate: ablMonthDateArray,
        //cumulative
        cumulativeRev: result,
        cumulativeMonth: ablcummonth,
        //anual sales start
        anualabldata: action.payload.data,
        anualablprice: ablanualprice,
        anualabldate: ablyearListArray,
        anualgrowth: ablGrowthArray,
        cumSecondarydaryDiff,
        TrendValue: trendResult,
        TrendValuePercentage: trendResultPercentage,
      };

    case Types.CHANGE_PRODUCT_INPUT_REPORT:
      const reportData = { ...state.reportData };
      reportData[action.payload.name] = action.payload.value;
      return {
        ...state,
        reportData,
      };
    case Types.GET_CATEGORY_TYPE:
      return {
        ...state,

        ddcategorytype: getCategory(action.payload.data),
      };
    case Types.GET_PRODUCT_TYPE:
      return {
        ...state,
        ddproducttype: getProduct(action.payload.data),
      };
    case Types.GET_NSM_TYPE:
      return {
        ...state,
        ddnsmtype: getNSM(action.payload.data),
      };
    case Types.GET_DSM_TYPE:
      return {
        ...state,
        dddsmtype: getDSM(action.payload.data),
      };
    case Types.GET_RM_TYPE:
      return {
        ...state,
        ddrmtype: getRM(action.payload.data),
      };
    case Types.GET_TSM_TYPE:
      return {
        ...state,
        ddtsmtype: getTSM(action.payload.data),
      };
    case Types.GET_LINE_TYPE:
      return {
        ...state,
        linetype: getLINE(action.payload.data),
      };
    case Types.GET_PRODUCT_GROUP_TYPE:
      return {
        ...state,
        productGroupType: getProductGroup(action.payload.data),
      };
      case Types.GET_ALL_PRODUCT_LIST:
      return {
        ...state,
        AllProductList: getAllProductList(action.payload.data),
      };
    case Types.GET_PRODUCTION_LINE:
      return {
        ...state,
        productionLine: getProductionLine(action.payload.data),
      };
    case Types.GET_PRODUCTION_LINE_BY_PRODUCTION:
      return {
        ...state,
        proLinebyPro: getProLineByPro(action.payload.data),
      };
    case Types.GET_PRODUCT_LIST_BY_GROUP:
      return {
        ...state,
        proListbyGroup: getProListByGroup(action.payload.data),
      };

    //damage chart
    case Types.GET_DAMAGE_DATA:
      var ablDamageArray = [];
      const ablDamageData = action.payload.data;
      for (let i = 0; i < ablDamageData?.length; i++) {
        ablDamageArray.push(
          state.reportData.measurement == 1
            ? ablDamageData[i].prodPrice
            : ablDamageData[i].prodQty
        );
      }
      var damageMonth = [];
      const monthNamesForDamage = [
        "",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const ablmonthlistforDamage = action.payload.data;
      for (let i = 0; i < ablmonthlistforDamage?.length; i++) {
        damageMonth.push(monthNamesForDamage[ablmonthlistforDamage[i]?.doDate]);
      }

      return {
        ...state,
        damagedata: ablDamageArray,
        damageMonthData: damageMonth,
      };
    case Types.GET_LOCATION_WISE_DATA:
      const chartData = [];
      let chartData2 = [];
      const chrtDateGet = action.payload.data.allData;
      const total = action.payload.data.total;
      const totalQty = action.payload.data.totalQty;
      const allPrice = action.payload.data?.price;
      const allQuantity = action.payload.data?.quantity;
      const zone = action.payload.data?.zoneName;
      allPrice.unshift(total);
      allQuantity.unshift(totalQty);
      zone.unshift('Total');
      if (chrtDateGet) {
        chrtDateGet.forEach((item) => {
          chartData2.push(
            state.reportData.measurement == 1
              ? [item.zoneName, item.prodPrice]
              : [item.zoneName, item.prodQty]
          );
        });
      }
      chartData2.unshift(
        state.reportData.measurement == 1
          ? ["Total Price", total]
          : ["Total Quantity", totalQty]
      );
      // chartData2=[...chartData];
      // chartData.unshift(["zone", "price"]);
      // console.log("chartDataGet", chartData);

      return {
        ...state,
        // LocationWisePieData:
        //   state.reportData.measurement == 1
        //     ? action.payload.data?.price
        //     : action.payload.data?.quantity,
        LocationWisePieData:
          state.reportData.measurement == 1
            ? allPrice
            : allQuantity,
        zoneData: action.payload.data?.zoneName,
        total: action.payload.data?.total,
        locationTotalQtyForD: action.payload.data?.totalQty,
        locationLoading: action.payload.isLoading,
        chartData,
        chartData2
      };
    case Types.GET_LOCATION_WISE_DATA_FOR_DONUT:
      const allPriceForD = action.payload.data?.price;
      const allQuantityForD = action.payload.data?.quantity;
      console.log('location action.payload.data', action.payload.data);
      return {
        ...state,
        zoneDataForDonut: action.payload.data?.zoneName,
        totalForDonut: action.payload.data?.total,
        locationTotalQty: action.payload.data?.totalQty,
        donutData: state.reportData.measurement == 1
          ? allPriceForD
          : allQuantityForD,
        donutLoading: action.payload.status,
      };
    case Types.CLEAR_LIST:
      return {
        ...state,
        ddcategorytype: [],
        ddproducttype: [],
        ddnsmtype: [],
        dddsmtype: [],
        ddrmtype: [],
        ddtsmtype: [],
      };

      case Types.GET_WORKING_DAY_LIST:
  
      return {
        ...state,
        workingDayList: action.payload.data?.dateList,
        workingDayListCount: action.payload.data?.dateCount
      };

      case Types.GET_PREVIOUS_WORKING_DAY_LIST:
        
      return {
        ...state,
        PworkingDayList: action.payload.data?.dateList,
        PworkingDayListCount: action.payload.data?.dateCount,
        lastWorkingDay: action.payload.data?.lastDay
      };

      case Types.GET_ACHIEVEMENT_STATUS:
        
        return {
          ...state,
          achievementStatus: action.payload.data?.result,
        };

        case Types.GET_IMS_NOTIFICATION:
        
          return {
            ...state,
            imsNotification: action.payload.data,
          };


    default:
      break;
  }
  return newState;
};

export default DashboardCartReducer;

const getCategory = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.productCatID,
        label: item.productCatName,
      };
      options.push(itemData);
    });
  }
  return options;
};
const getProduct = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.productId,
        label: item.productName,
      };
      options.push(itemData);
    });
  }
  return options;
};
const getNSM = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.empId,
        label: item.name,
      };
      options.push(itemData);
    });
  }
  return options;
};
const getDSM = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.empId,
        label: item.name,
      };
      options.push(itemData);
    });
  }
  return options;
};
const getRM = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.empId,
        label: item.name,
      };
      options.push(itemData);
    });
  }
  return options;
};
const getTSM = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.empId,
        label: item.name,
      };
      options.push(itemData);
    });
  }
  return options;
};
const getLINE = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.lineID,
        label: item.lineName,
      };
      options.push(itemData);
    });
  }
  return options;
};
const getProductGroup = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.salesGroupId,
        label: item.salesGroupName,
      };
      options.push(itemData);
    });
  }
  return options;
};
const getAllProductList = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.productId,
        label: item.productName,
      };
      options.push(itemData);
    });
  }
  return options;
};
const getProductionLine = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.productionLineId,
        label: item.productionLineName,
      };
      options.push(itemData);
    });
  }
  return options;
};
const getProLineByPro = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.productId,
        label: item.productName,
      };
      options.push(itemData);
    });
  }
  return options;
};
const getProListByGroup = (data) => {
  let options = [];
  if (data) {
    data.forEach((item) => {
      let itemData = {
        value: item.productId,
        label: item.productName,
      };
      options.push(itemData);
    });
  }
  return options;
};

