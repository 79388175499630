import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import {
  getAreaListByDivisionEntry,
  GetDelPointListByTerritory,
  getDivisionListByZoneEntry,
  getTerritoryEntry,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import * as XLSX from "xlsx/xlsx.mjs";
import {
  cleanSecondarySalesData,
  getSowiseAchivement,
  getSowiseAchivementForExcel
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { currentdate, numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { FaSearch, FaDownload } from 'react-icons/fa';

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const SecondarySalesSoAchivement = () => {
  const { register, setValue } = useForm();

  const isProductLoading = useSelector((state) => state.secondarySales.skuLoading);
  const productReport = useSelector((state) => state.secondarySales.soWiseAchivementProduct);
  const totalTarget = useSelector((state) => state.secondarySales.skuCumalative);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [soPoint, setSelectSoPoint] = useState(null);
  const componentRef = useRef();

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {

    var permissionwise = await GetPermissionDropDown()
      .then((res) => {

        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, selectGeoType } = res.data;

        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);
        GetDelPointList(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  }

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }
  const GetDelPointList = async (id) => {
    let delData = await GetDelPointListByTerritory(id);
    setDelPointData(delData);
  }

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const delpointDropdownData = () => {
    let options = [];
    if (delpoint.data?.length > 0) {
      delpoint.data.forEach((item) => {
        let itemData = {
          value: item.delPointID,
          label: item.delPointName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const inputField = React.useRef(null);

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };

  const showValidation = () => {
    if (startDate == null || startDate == "") {
      alert("Please Select From Date");
      return false;
    }
    else if (toDate == null || toDate == "") {
      alert("Please select To Date");
      return false;
    }
    else {
      return true;
    }
  }

  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory) => {
    const validation = showValidation();
    if (validation) {
      dispatch(getSowiseAchivement(fromDate, tDate, idZone, idDivision, idArea, idTerritory, soPoint));
    }
  }

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [delpoint, setDelPointData] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleExcelClick = async () => {
    setLoading(true);
    setError(null);
  
    try {
      const excelClick = await getSowiseAchivementForExcel(
        startDate,
        toDate,
        selectZone,
        selectDivision,
        selectArea,
        selectTerritory
      );
      console.log('excelClick', excelClick)
  
      const current = new Date().toISOString().slice(0, 10);
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet1');
  
      worksheet.columns = [
        { header: 'Zone ID', key: 'zoneID', width: 10 },
        { header: 'Zone Name', key: 'zoneName', width: 20 },
        { header: 'Division ID', key: 'divisionID', width: 10 },
        { header: 'Division Name', key: 'divisionName', width: 20 },
        { header: 'Region ID', key: 'areaID', width: 10 },
        { header: 'Region Name', key: 'areaName', width: 20 },
        { header: 'Territory ID', key: 'territoryID', width: 10 },
        { header: 'Territory Name', key: 'territoryName', width: 20 },
        { header: 'So Point ID', key: 'soPoint', width: 10 },
        { header: 'So Point Name', key: 'soPointName', width: 20 },
        { header: 'Prod Cat ID', key: 'prodCatID', width: 10 },
        { header: 'Prod Catagories', key: 'prodCatagories', width: 20 },
        { header: 'Group Name', key: 'salesGroup', width: 20 },
        { header: 'Brand Name', key: 'brandName', width: 20 },
        { header: 'Pack Type Name', key: 'packTypeName', width: 20 },
        { header: 'Product ID', key: 'prodID', width: 20 },
        { header: 'Product Name', key: 'prodName', width: 40 },
        { header: 'Target Ctn', key: 'targetQty', width: 15 },
        { header: 'Ims Ctn', key: 'imsQty', width: 15 },
        { header: 'Different Ctn', key: 'differentQty', width: 15 },
        { header: 'Target Value', key: 'targetPrice', width: 15 },
        { header: 'Ims Value', key: 'imsPrice', width: 15 },
        { header: 'Different Value', key: 'differentPrice', width: 15 },
        { header: 'Tar Vs Ach%', key: 'tarVsAccPers', width: 15 },
      ];
  
      worksheet.addRows(excelClick?.data || []);

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
  
      saveAs(blob, `Target Vs Achievement(SO Point Wise) ${current}.xlsx`);
    } catch (error) {
      console.error('Error:', error);
      setError('Try Again');
    } finally {
      setLoading(false);
    }
  };

  const labelStyle = {
    color: '#050f26',
    fontWeight: '500',
    marginRight: '5px',
  };

  const valueStyle = {
    color: 'yellow',
  };

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1px',
  };



  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Target Vs Achievement(SO Point Wise)</h3>
            </div>
          </div>
          <div className="col-md-3">
            <Button
              size="sm"
              onClick={handleExcelClick}
              disabled={loading}
              className={loading ? 'bg-danger' : ''}
            >
              <FaDownload /> {loading ? 'Loading...' : 'Download'}
            </Button>
            {error && <div>Error: {error}</div>}
            <DownloadDropdown excelname='Target Vs Achievement(SO Point Wise)' />
          </div>

        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={zoneDropdownData()}
                value={defultZone}
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setDefaultZone(option);
                  setdefultDivision(null);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                defaultValue={defultZone}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={divisionDropdownData()}
                value={defultDivision}
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setdefultDivision(option);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                defaultValue={defultDivision}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={areaDropdownData()}
                value={defaultArea}
                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setdefaultArea(option);
                  setdefultTerritory(null);
                  setValue("territory", "");
                }}
                defaultValue={defaultArea}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                onChange={(option) => {
                  //getTerritoryData(option?.value);
                  GetDelPointList(option?.value);
                  setSelectTerritory(option?.value);
                  setdefultTerritory(option)
                }}
                defaultValue={defultTerritory}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select SO Point</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={delpointDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                onChange={(option) => {
                  setSelectSoPoint(option?.value);
                  setValue("territory", "");

                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-2 mt-4 mb-5">
            <Button size="sm" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory)}><FaSearch /> Show Report</Button>
          </div>
        </div>
        <div>
          {productReport?.length > 0 &&

            <div className="container-fluid mb-3 mt-1 containerFixed" style={{ padding: "0 15px" }}>
              <div className="row" style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
                {/* Target Section */}
                <div className="col-xl-4 col-lg-4 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#f5e4e4", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
                  <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#007bff", fontSize: "14px", lineHeight: "10px" }}>TARGET</h5>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Biscuits Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.biscuitTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cookies Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.cookisTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cake Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.cakeTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Wafer Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.waferTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Snacks Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.snacksTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Chanachur Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.chanachurTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {numberFormatDecemal((
                        parseFloat(totalTarget.biscuitTargetPrice) +
                        parseFloat(totalTarget.cookisTargetPrice) +
                        parseFloat(totalTarget.cakeTargetPrice) +
                        parseFloat(totalTarget.waferTargetPrice) +
                        parseFloat(totalTarget.snacksTargetPrice) +
                        parseFloat(totalTarget.chanachurTargetPrice)
                      ).toFixed(2))}
                    </span>
                  </div>
                </div>

                {/* IMS Section */}
                <div className="col-xl-4 col-lg-4 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#e9f7f7", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
                  <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#28a745", fontSize: "14px", lineHeight: "10px" }}>IMS</h5>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Biscuits IMS Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.biscuitLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cookies IMS Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.cookisLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cake IMS Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.cakeLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Wafer IMS Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.waferLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Snacks IMS Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.snacksLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Chanachur IMS Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.chanachurLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total IMS Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {numberFormatDecemal((
                        parseFloat(totalTarget.biscuitLiftingPrice) +
                        parseFloat(totalTarget.cookisLiftingPrice) +
                        parseFloat(totalTarget.cakeLiftingPrice) +
                        parseFloat(totalTarget.waferLiftingPrice) +
                        parseFloat(totalTarget.snacksLiftingPrice) +
                        parseFloat(totalTarget.chanachurLiftingPrice)
                      ).toFixed(2))}
                    </span>
                  </div>
                </div>

                {/* Achievement Section */}
                <div className="col-xl-4 col-lg-4 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#fff3cd", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
                  <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#ffc107", fontSize: "14px", lineHeight: "10px" }}>ACHIEVEMENT</h5>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Biscuits Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{parseFloat(((totalTarget.biscuitLiftingPrice) / (totalTarget.biscuitTargetPrice)) * 100).toFixed(2)}%</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cookies Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{parseFloat(((totalTarget.cookisLiftingPrice) / (totalTarget.cookisTargetPrice)) * 100).toFixed(2)}%</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cake Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{parseFloat(((totalTarget.cakeLiftingPrice) / (totalTarget.cakeTargetPrice)) * 100).toFixed(2)}%</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Wafer Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{parseFloat(((totalTarget.waferLiftingPrice) / (totalTarget.waferTargetPrice)) * 100).toFixed(2)}%</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Snacks Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {(() => {
                        const percentage = parseFloat(((totalTarget.snacksLiftingPrice) / (totalTarget.snacksTargetPrice)) * 100);
                        return isNaN(percentage) ? 0 + "%" : `${parseFloat(percentage).toFixed(2)}%`;
                      })()}
                    </span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Chanachur Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {(() => {
                        const percentage = parseFloat(((totalTarget.chanachurLiftingPrice) / (totalTarget.chanachurTargetPrice)) * 100);
                        return isNaN(percentage) ? 0 + "%" : `${parseFloat(percentage).toFixed(2)}%`;
                      })()}
                    </span>
                  </div>
                  <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {(
                        (
                          parseFloat(totalTarget.biscuitLiftingPrice) +
                          parseFloat(totalTarget.cookisLiftingPrice) +
                          parseFloat(totalTarget.cakeLiftingPrice) +
                          parseFloat(totalTarget.waferLiftingPrice) +
                          parseFloat(totalTarget.snacksLiftingPrice) +
                          parseFloat(totalTarget.chanachurLiftingPrice)
                        ) /
                        (
                          parseFloat(totalTarget.biscuitTargetPrice) +
                          parseFloat(totalTarget.cookisTargetPrice) +
                          parseFloat(totalTarget.cakeTargetPrice) +
                          parseFloat(totalTarget.waferTargetPrice) +
                          parseFloat(totalTarget.snacksTargetPrice) +
                          parseFloat(totalTarget.chanachurTargetPrice)
                        ) * 100
                      ).toFixed(2)}%
                    </span>
                  </div>
                </div>

              </div>
            </div>


          }
          <div className="row mt-1">
            {/* <table className="table mt-5 voyageTable table-responsive"> */}
            {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
            <div className=" border-0 ">

              <div
                className="stickyTable_SO"
                style={{ height: productReport?.length > 0 ? 600 + "px" : 0 }}
              >
                {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}> */}
                <table className="">
                  <thead>
                    <tr>
                      {/* <th scope="col">ZONE</th>
                      <th scope="col">DIVISION</th>
                      <th scope="col">REGION</th> */}
                      <th style={{ width: '70px' }} scope="col">TERRITORY</th>
                      {/* <th scope="col">SO ID</th>
                      <th scope="col">SO NAME</th> */}
                      <th style={{ width: '70px' }} scope="col">SO POINT</th>
                      {/* <th scope="col">PRODUCT CATEGORIES</th>
                      <th scope="col">PRODUCT ID</th> */}
                      <th style={{ width: '130px' }} scope="col">PRODUCT NAME</th>
                      {/* <th scope="col">GROUP NAME</th> */}
                      <th style={{ width: '40px' }} scope="col">TARGET QTY (CTN)</th>
                      <th style={{ width: '40px' }} scope="col">TARGET VALUE</th>
                      <th style={{ width: '40px' }} scope="col">IMS QTY</th>
                      <th style={{ width: '40px' }} scope="col">IMS VALUE</th>
                      {/* <th scope="col">DIFFERECE QUANTITY</th>
                      <th scope="col">DIFFERECE VALUE</th> */}
                      <th style={{ width: '40px' }} scope="col">TARGET VS ACH (IN TK %)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productReport?.length > 0 &&
                      productReport?.map((item, index) => (
                        <>
                          <tr className="alert bg-secondary">
                            {/* <td>{item.orderId}</td> */}
                            {/* <td>{item.zoneName}</td>
                            <td>{item.divisionName}</td>
                            <td>{item.areaName}</td> */}
                            <td>{item.territoryName}</td>
                            {/* <td>{item.soEnroll}</td>
                            <td>{item.soName}</td> */}
                            <td>{item.soPointName}</td>
                            {/* <td>{item.prodCatagories}</td>
                            <td /> */}
                            <td />
                            {/* <td /> */}
                            <td>{numberFormatDecemal(Math.round(item?.totalReport.targetQty))}</td>
                            <td>{numberFormatDecemal(Math.round(item?.totalReport.targetPrice))}</td>
                            <td>{numberFormatDecemal(Math.round(item?.totalReport.liftingQty))}</td>
                            <td>{numberFormatDecemal(Math.round(item?.totalReport.liftingPrice))}</td>
                            {/* <td>{Math.round(item?.totalReport.differentQty)}</td>
                            <td>{Math.round(item?.totalReport.differentPrice)}</td> */}
                            <td>{Math.round(item?.totalReport.tarVsAccPers)}</td>

                          </tr>
                          {
                            item?.itemReport.map((product, subindex) => (
                              <tr>
                                {/* <td>{item.zoneName}</td>
                                <td>{item.divisionName}</td>
                                <td>{item.areaName}</td> */}
                                <td>{item.territoryName}</td>
                                {/* <td>{item.soEnroll}</td>
                                <td>{item.soName}</td> */}
                                <td>{item.soPointName}</td>
                                {/* <td>{product.prodCatagories}</td>
                                <td>{product.prodID}</td> */}
                                <td>{product.prodName}</td>
                                {/* <td>{product.salesGroup}</td> */}
                                <td>{numberFormatDecemal(Math.round(product.targetQty))}</td>
                                <td>{numberFormatDecemal(Math.round(product.targetPrice))}</td>
                                <td>{numberFormatDecemal(Math.round(product.liftingQty))}</td>
                                <td>{numberFormatDecemal(Math.round(product.liftingPrice))}</td>
                                {/* <td>{Math.round(product.differentQty)}</td>
                                <td>{Math.round(product.differentPrice)}</td> */}
                                <td>{Math.round(product.tarVsAccPers)}</td>
                              </tr>
                            ))
                          }
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
              {/* {!isProductLoading && productReport?productReport[0].allReport.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )} */}
            </div>
          </div>

        </div>




        <div className="notshow">
          {productReport?.length > 0 &&

            <div className="container-fluid containerFixed">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="paid__widget one">
                    <div className="widget__left">
                      <span className="paid__widget_New one text-center text-bold target-label">Target</span>
                      <div className="achiveLayer">
                        <span style={containerStyle}>
                          <span style={labelStyle}>Biscuits Target Value:</span>
                          <span style={valueStyle}>{numberFormatDecemal(parseFloat(totalTarget.biscuitTargetPrice).toFixed(2))}</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Cookies Target Value:</span>
                          <span style={valueStyle}>{numberFormatDecemal(parseFloat(totalTarget.cookisTargetPrice).toFixed(2))}</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Cake Target Value:</span>
                          <span style={valueStyle}>{numberFormatDecemal(parseFloat(totalTarget.cakeTargetPrice).toFixed(2))}</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Wafer Target Value:</span>
                          <span style={valueStyle}>{numberFormatDecemal(parseFloat(totalTarget.waferTargetPrice).toFixed(2))}</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Snacks Target Value:</span>
                          <span style={valueStyle}>{numberFormatDecemal(parseFloat(totalTarget.snacksTargetPrice).toFixed(2))}</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Total Target:</span>
                          <span style={valueStyle}>
                            {numberFormatDecemal((
                              parseFloat(totalTarget.biscuitTargetPrice) +
                              parseFloat(totalTarget.cookisTargetPrice) +
                              parseFloat(totalTarget.cakeTargetPrice) +
                              parseFloat(totalTarget.waferTargetPrice) +
                              parseFloat(totalTarget.snacksTargetPrice)
                            ).toFixed(2))}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="paid__widget two">
                    <div className="widget__left">
                      <span className="paid__widget_New two text-center text-bold target-label">IMS</span>
                      <div className="achiveLayer">
                        <span style={containerStyle}>
                          <span style={labelStyle}>Biscuits IMS Value:</span>
                          <span style={valueStyle}>{numberFormatDecemal(parseFloat(totalTarget.biscuitLiftingPrice).toFixed(2))}</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Cookies IMS Value:</span>
                          <span style={valueStyle}>{numberFormatDecemal(parseFloat(totalTarget.cookisLiftingPrice).toFixed(2))}</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Cake IMS Value:</span>
                          <span style={valueStyle}>{numberFormatDecemal(parseFloat(totalTarget.cakeLiftingPrice).toFixed(2))}</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Wafer IMS Value:</span>
                          <span style={valueStyle}>{numberFormatDecemal(parseFloat(totalTarget.waferLiftingPrice).toFixed(2))}</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Snacks IMS Value:</span>
                          <span style={valueStyle}>{numberFormatDecemal(parseFloat(totalTarget.snacksLiftingPrice).toFixed(2))}</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Total IMS:</span>
                          <span style={valueStyle}>
                            {numberFormatDecemal((
                              parseFloat(totalTarget.biscuitLiftingPrice) +
                              parseFloat(totalTarget.cookisLiftingPrice) +
                              parseFloat(totalTarget.cakeLiftingPrice) +
                              parseFloat(totalTarget.waferLiftingPrice) +
                              parseFloat(totalTarget.snacksLiftingPrice)
                            ).toFixed(2))}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="paid__widget three">
                    <div className="widget__left">
                      <span className="paid__widget_New two text-center text-bold target-label">IMS</span>
                      <div className="achiveLayer">
                        <span style={containerStyle}>
                          <span style={labelStyle}>Biscuits Ach:</span>
                          <span style={valueStyle}>{parseFloat(((totalTarget.biscuitLiftingPrice) / (totalTarget.biscuitTargetPrice)) * 100).toFixed(2)}%</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Cookies Ach:</span>
                          <span style={valueStyle}>{parseFloat(((totalTarget.cookisLiftingPrice) / (totalTarget.cookisTargetPrice)) * 100).toFixed(2)}%</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Cake Ach:</span>
                          <span style={valueStyle}>{parseFloat(((totalTarget.cakeLiftingPrice) / (totalTarget.cakeTargetPrice)) * 100).toFixed(2)}%</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Wafer Ach:</span>
                          <span style={valueStyle}>{parseFloat(((totalTarget.waferLiftingPrice) / (totalTarget.waferTargetPrice)) * 100).toFixed(2)}%</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Snacks Ach:</span>
                          <span style={valueStyle}>{(() => {
                            const percentage = parseFloat((totalTarget.snacksLiftingPrice / totalTarget.snacksTargetPrice) * 100);
                            return isNaN(percentage) ? 0 + "%" : `${percentage}%`;
                          })()}</span>
                        </span>
                        <span style={containerStyle}>
                          <span style={labelStyle}>Total Ach:{" "}</span>
                          <span style={valueStyle}>
                            {(
                              (
                                parseFloat(totalTarget.biscuitLiftingPrice) +
                                parseFloat(totalTarget.cookisLiftingPrice) +
                                parseFloat(totalTarget.cakeLiftingPrice) +
                                parseFloat(totalTarget.waferLiftingPrice) +
                                parseFloat(totalTarget.snacksLiftingPrice)
                              ) /
                              (
                                parseFloat(totalTarget.biscuitTargetPrice) +
                                parseFloat(totalTarget.cookisTargetPrice) +
                                parseFloat(totalTarget.cakeTargetPrice) +
                                parseFloat(totalTarget.waferTargetPrice) +
                                parseFloat(totalTarget.snacksTargetPrice)
                              ) * 100
                            ).toFixed(2)}%                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          }

          <div className="row" id="id" ref={inputField}>
            {/* <table className="table mt-5 voyageTable table-responsive"> */}
            {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
            <div className="react-bootstrap-table table-responsive border-0 pl-5 ">

              <div
                className="stickyTable"
                style={{ height: productReport?.length > 0 ? 800 + "px" : 0 }}
              >
                {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}> */}
                <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                  <thead>
                    <tr>
                      <th scope="col">ZONE</th>
                      <th scope="col">DIVISION</th>
                      <th scope="col">REGION</th>
                      <th scope="col">TERRITORY</th>
                      {/* <th scope="col">SO ID</th>
                      <th scope="col">SO NAME</th> */}
                      <th scope="col">SO POINT</th>
                      <th scope="col">PRODUCT CATEGORIES</th>
                      <th scope="col">PRODUCT ID</th>
                      <th scope="col">PRODUCT NAME</th>
                      <th scope="col">GROUP NAME</th>
                      <th scope="col">TARGET QUANTITY(CTN)</th>
                      <th scope="col">TARGET VALUE</th>
                      <th scope="col">IMS QTY</th>
                      <th scope="col">IMS VALUE</th>
                      <th scope="col">DIFFERECE QUANTITY</th>
                      <th scope="col">DIFFERECE VALUE</th>
                      <th scope="col">TARGET VS ACH(IN TK %)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productReport?.length > 0 &&
                      productReport?.map((item, index) => (
                        <>
                          <tr className="alert bg-secondary">
                            {/* <td>{item.orderId}</td> */}
                            <td>{item.zoneName}</td>
                            <td>{item.divisionName}</td>
                            <td>{item.areaName}</td>
                            <td>{item.territoryName}</td>
                            {/* <td>{item.soEnroll}</td>
                            <td>{item.soName}</td> */}
                            <td>{item.soPointName}</td>
                            <td>{item.prodCatagories}</td>
                            <td />
                            <td />
                            <td />
                            <td>{Math.round(item?.totalReport.targetQty)}</td>
                            <td>{Math.round(item?.totalReport.targetPrice)}</td>
                            <td>{Math.round(item?.totalReport.liftingQty)}</td>
                            <td>{Math.round(item?.totalReport.liftingPrice)}</td>
                            <td>{Math.round(item?.totalReport.differentQty)}</td>
                            <td>{Math.round(item?.totalReport.differentPrice)}</td>
                            <td>{Math.round(item?.totalReport.tarVsAccPers)}</td>

                          </tr>
                          {
                            item?.itemReport.map((product, subindex) => (
                              <tr>
                                <td>{item.zoneName}</td>
                                <td>{item.divisionName}</td>
                                <td>{item.areaName}</td>
                                <td>{item.territoryName}</td>
                                {/* <td>{item.soEnroll}</td>
                                <td>{item.soName}</td> */}
                                <td>{item.soPointName}</td>
                                <td>{product.prodCatagories}</td>
                                <td>{product.prodID}</td>
                                <td>{product.prodName}</td>
                                <td>{product.salesGroup}</td>
                                <td>{Math.round(product.targetQty)}</td>
                                <td>{Math.round(product.targetPrice)}</td>
                                <td>{Math.round(product.liftingQty)}</td>
                                <td>{Math.round(product.liftingPrice)}</td>
                                <td>{Math.round(product.differentQty)}</td>
                                <td>{Math.round(product.differentPrice)}</td>
                                <td>{Math.round(product.tarVsAccPers)}</td>
                              </tr>
                            ))
                          }
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
              {/* {!isProductLoading && productReport?productReport[0].allReport.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )} */}
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default SecondarySalesSoAchivement;
