import axios from "axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../master/utils/ToastHelper";
import * as Types from "../../types/Types";


export const itemAddInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };

  dispatch({ type: Types.GET_FUND_INPUT, payload: formData });
};
// Assuming you have `getFundList` as an action creator
export const handleFundSubmit = (startDate, selectZone = 0, selectDivision = 0, selectArea = 0, selectTerritory = 0, AllAreas) => {
  return async (dispatch) => {
    const filteredAreas = AllAreas.filter(area => area.fundValue > 0 || area.orderValue > 0);
    console.log('filteredAreas', filteredAreas);

    if (filteredAreas.length === 0) {
      showToast("error", "No areas with valid fund or order values.");
      return;
    }

    try {
      const postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertFundCollection?ZoneID=${selectZone}&DivisionID=${selectDivision}&AreaID=${selectArea}&TerritoryId=${selectTerritory}&fundDate=${startDate}`;

      const response = await axios.post(postUrl, filteredAreas);
      console.log('response :>> ', response);

      const { code, message, status } = response.data;
      if (code === 200) {
        showToast("success", message);

        // After successful fund submission, dispatch the getFundList action
        dispatch(getFundList(selectZone, selectDivision, selectArea, selectTerritory, startDate, startDate));
      } else if (code === 400) {
        showToast("error", message);
      } else {
        showToast("error", message || "Unknown error");
      }
    } catch (error) {
      console.error("Error during fund submission:", error);
      showToast("error", "Something went wrong! Please fill all inputs and try again.");
    }
  };
};


export const handleWorkingDaysSubmit = (dateArray) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };


  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertWorkingDay`;

    axios
      .post(postUrl, dateArray)
      .then(function (response) {
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }


};


export const InsertIMSFocudProduct = (ProductList) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };


  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertIMSFocudProduct`;

    axios
      .post(postUrl, ProductList)
      .then(function (response) {
        console.log('response', response)
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.data}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", `${response.data.data}`);
        }
      })
      .catch(function (error) {
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }


};

export const getFundList = (zoneId = 0, divisionId = 0, areaId = 0, territoryId = 0, fromDate, toDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,

  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetFundCollecton?`;
  if (zoneId !== null) {

    url += `&ZoneID=${zoneId}`
  }
  if (divisionId !== null) {

    url += `&DivisionID=${divisionId}`
  }
  if (areaId !== null) {
    url += `&AreaID=${areaId}`
  }
  if (territoryId !== null) {
    url += `&TerritoryId=${territoryId}`
  }
  if (fromDate !== null) {

    url += `&fromDate=${fromDate}`
  }
  if (toDate !== null) {

    url += `&toDate=${toDate}`
  }

  await axios.get(url).then((res) => {
    console.log('fundres', res)
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_FUND_LIST, payload: responseList });
};


export const GetFundCollectonDateWiseReport = (zoneId = 0, divisionId = 0, areaId = 0, territoryId = 0, fromDate, toDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,

  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetFundCollectonDateWiseReport?`;
  if (zoneId !== null) {

    url += `&ZoneID=${zoneId}`
  }
  if (divisionId !== null) {

    url += `&DivisionID=${divisionId}`
  }
  if (areaId !== null) {
    url += `&AreaID=${areaId}`
  }
  if (territoryId !== null) {
    url += `&TerritoryId=${territoryId}`
  }
  if (fromDate !== null) {

    url += `&fromDate=${fromDate}`
  }
  if (toDate !== null) {

    url += `&toDate=${toDate}`
  }

  await axios.get(url).then((res) => {
    console.log('fundres', res)
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_FUND_LIST, payload: responseList });
};


export const GetRegionListForFund = (zoneId = 0, divisionId = 0, areaId = 0, territoryId = 0) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,

  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetRegionListForFund?`;
  if (zoneId !== null) {

    url += `&ZoneID=${zoneId}`
  }
  if (divisionId !== null) {

    url += `&DivisionID=${divisionId}`
  }
  if (areaId !== null) {
    url += `&AreaID=${areaId}`
  }
  if (territoryId !== null) {
    url += `&TerritoryId=${territoryId}`
  }


  await axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_AREA_LIST_FOR_FUND, payload: responseList });
};

export const getWorkingDayList = (fromDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,

  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetWorkingDay?fundDate=${fromDate}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_WORKING_DAY_LIST, payload: responseList });
};


export const fundDataDelete = (id, selectZone, selectDivision, selectArea, selectTerritory, toDate) => (dispatch) => {

  let isLoading = true;
  dispatch({ type: Types.DELETE_FUND_ITEM, payload: isLoading })
  axios.delete(`${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/FundDelete?fundId=${id}`)
    .then((res) => {

      if (res.data.status) {
        const data = res.data;
        showToast('success', data.message);
        dispatch({ type: Types.DELETE_FUND_ITEM, payload: false });
        dispatch(getFundList(selectZone, selectDivision, selectArea, selectTerritory, toDate, toDate));

      }
    })
}
