import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";

import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import SecondarySalesDetails from "./SecondarySalesDetails";
import { fundDataDelete, GetFundCollectonDateWiseReport, getFundList } from "../../_redux/actions/SecondarySales/FundAction";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { FaFileExcel, FaSearch } from "react-icons/fa";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import DownloadDropdown from "../../Master/DownloadDropdown";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";

const DateWiseFundList = () => {
  const history = useHistory();
  const isLoading = useSelector((state) => state.itemList.isLoading);
  const Fundlist = useSelector((state) => state.fund.fundList);
  console.log('Fundlist', Fundlist)

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);

  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    initialData();
  }, []);

  const initialData = async () => {
    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName } = res.data;
        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();

    Setzone(zoneData.data);
  };

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };


  let ref = React.createRef();
  const inputField = React.useRef(null);


  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  }

  const showReport = () => {
    dispatch(GetFundCollectonDateWiseReport(selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate));
  }



  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-12">
            <div className="mt-2 p-2 d-flex justify-content-between align-items-center">
              <h3 className="mb-0 pb-0">Date Wise Fund List</h3>
              <DownloadDropdown excelname='Fund List' />
            </div>
          </div>
        </div>


        <hr></hr>

        <div className="form-group row">
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> Collection Date</label>

              <Form.Control
                type="date"
                placeholder="Enter startDate"
                name="todate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>

          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> Collection Date</label>

              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                value={toDate}
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2 mt-0">
            <label className="form-label">Select Zone</label>
            <Select
              options={zoneDropdownData()}
              value={defultZone}
              onChange={(option) => {
                divisionList(option?.value);
                setSelectZone(option?.value);
                setDefaultZone(option)
                setValue("divisionData", "");
                setValue("areaData", "");
                setValue("territory", "");
              }}
              defaultValue={defultZone}
            />
          </div>
          <div className="col-lg-2 mt-0">
            <label className="form-label">Select Division</label>
            <Select
              options={divisionDropdownData()}
              value={defultDivision}
              isClearable
              onChange={(option) => {
                getAreaData(option?.value);
                setSelectDivision(option?.value);
                setdefultDivision(option)
                setValue("areaData", "");
                setValue("territory", "");

              }}
              defaultValue={defultDivision}
            />
          </div>
          <div className="col-lg-2 mt-0 ml-4">

            <label className="form-label">Select Region</label>
            <Select
              options={areaDropdownData()}
              value={defaultArea}
              isClearable
              onChange={(option) => {
                getTerritoryData(option?.value);
                setSelectArea(option?.value);
                setdefaultArea(option)
                setValue("territory", "");
              }}
              defaultValue={defaultArea}
            />
          </div>


          <div className="col mt-6 mb-5">
            <Button size="sm" onClick={() => showReport()}><FaSearch /> Show Report</Button>
          </div>
        </div>

        <div className="row" ref={inputField} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <div className="table-responsive pl-5 mb-5 text-center mb-10">
            <table id="table-to-xls" className="printtablenew1" style={{ borderCollapse: 'collapse', margin: '0 auto' }}>
              <thead>
                <tr>
                  <th rowSpan="2" style={{ padding: '12px', textAlign: 'left', backgroundColor: '#00C2C4' }}>Name of the Division</th>
                  <th rowSpan="2" style={{ padding: '12px', textAlign: 'left', backgroundColor: '#00C2C4' }}>Area Name</th>

                  {/* Dynamically create date columns */}
                  {Fundlist?.length > 0 &&
                    Fundlist[0].areas?.[0]?.fundDataByDate?.map((fundData, index) => {
                      // Alternating colors for dates (only two colors)
                      const colors = ['#f7b7b7', '#d0f7d0'];  // Only two color choices
                      const colorIndex = index % 2; // Use modulo to alternate between two colors
                      return (
                        <th key={index} colSpan="2" style={{ padding: '12px', textAlign: 'center', backgroundColor: colors[colorIndex] }}>
                          {new Date(fundData.fundDate).toLocaleDateString()}
                        </th>
                      );
                    })
                  }
                </tr>
                <tr>
                  {/* Sub-headers for Order Value and Fund Value */}
                  {Fundlist?.length > 0 &&
                    Fundlist[0].areas?.[0]?.fundDataByDate?.map((fundData, index) => {
                      // Alternating colors for sub-headers (only two colors)
                      const colors = ['#f7b7b7', '#d0f7d0'];  // Only two color choices
                      const colorIndex = index % 2; // Use modulo to alternate between two colors
                      return (
                        <React.Fragment key={`subheader-${index}`}>
                          <th style={{ padding: '12px', backgroundColor: colors[colorIndex] }}>Order Value</th>
                          <th style={{ padding: '12px', backgroundColor: colors[colorIndex] }}>Fund Value</th>
                        </React.Fragment>
                      );
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {Fundlist?.length > 0 &&
                  Fundlist.map((item, index) => {
                    // Alternating color for Division and Area Name based on Division index
                    const divisionColors = ['#B0E0E6', '#E6E6FA'];  // Two color choices for divisions
                    const divisionColorIndex = index % 2; // Use modulo to alternate colors for each division
                    return (
                      <>
                        {/* Render Areas */}
                        {item.areas?.map((area, areaIndex) => {
                          // The same color as division for area
                          return (
                            <tr key={`area-${areaIndex}`} style={{ borderBottom: '1px solid #ddd' }}>
                              {/* Render Division Name and Area Name with the same color */}
                              <td style={{ padding: '12px', backgroundColor: divisionColors[divisionColorIndex] }}>
                                {item.divisionName}
                              </td> {/* Division Name */}
                              <td style={{ padding: '12px', backgroundColor: divisionColors[divisionColorIndex] }}>
                                {area.areaName}
                              </td> {/* Area Name */}

                              {/* Render Fund Data for each date */}
                              {area.fundDataByDate?.map((fundData, fundDataIndex) => {
                                // Alternating colors for data cells (only two colors)
                                const fundColors = ['#f7b7b7', '#d0f7d0'];  // Only two color choices for fund data cells
                                const colorIndex = fundDataIndex % 2; // Use modulo to alternate between two colors
                                return (
                                  <React.Fragment key={`fundData-${fundDataIndex}`}>
                                    <td style={{ padding: '12px', backgroundColor: fundColors[colorIndex] }}>
                                      {(parseFloat(fundData.orderValue).toFixed(2))}
                                    </td>
                                    <td style={{ padding: '12px', backgroundColor: fundColors[colorIndex] }}>
                                      {(parseFloat(fundData.fundAmount).toFixed(2))}
                                    </td>
                                  </React.Fragment>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </>
                    );
                  })
                }
              </tbody>
            </table>

            {!isLoading && Fundlist.length === 0 && (
              <div className="alert alert-warning" style={{ marginTop: '20px' }}>
                Sorry! Fund List Not Found.
              </div>
            )}
          </div>
        </div>




        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <SecondarySalesDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>

        {/* <CustomPagination
        data={productList}
        itemsPerPage={5}
       /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default DateWiseFundList;
