import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SimpleModal from "../../../../master/components/Modal/SimpleModal";
import { useForm } from "react-hook-form";
import {
  EditDateGet,
  salesDelete,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import RemoteSalesDetails from "../RemoteSales/RemoteSalesDetails";
import {
  InputHandle,
} from "../../_redux/actions/SecondarySales/SecondaryListAction";
import { Form, Button, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";

import {
  getAreaListByDivision,
  getDivisionListByZone,
  getTerritory,
  getZoneList,
} from "../../_redux/actions/RemoteSales/DistributorReportAction";
import * as XLSX from "xlsx/xlsx.mjs";

import {
  cleanSecondarySalesData,
  geSalesPersonTA,
  getSowiseAchivement,
  getSowiseAchivementForExcel
} from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import DownloadDropdown from "../../Master/DownloadDropdown";
import LoadingSpinner from "../../../../master/spinner/LoadingSpinner";
import { currentdate, numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";

const SalesPersonWiseTA = () => {
  const history = useHistory();
  const isProductLoading = useSelector((state) => state.secondarySales.skuLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const productReport = useSelector((state) => state.secondarySales.soWiseAchivementProduct);
  console.log('productReport :>> ', productReport);
  const totalTarget = useSelector((state) => state.secondarySales.skuCumalative);
  console.log('totalTarget :>> ', totalTarget);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(null);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const componentRef = useRef();

  const [defultZone, setDefaultZone] = useState({});
  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  // const dispatch = useDispatch();
  // const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const dispatch = useDispatch();

  // items list with pagination
  useEffect(() => {
    // dispatch(getSalesList());
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user.employeeType);
    setDistribType(user.employeeType);
    dispatch(cleanSecondarySalesData());
    initialData();
  }, []);

  const initialData = async () => {
    var d = new Date();
    var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
    var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";

    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('res data', res);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, selectGeoType } = res.data;

        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  }

  // delete issuing authority list
  const confirmDelete = (id) => {
    dispatch(salesDelete(id, selectArea));
    // setTimeout(() => {history.go(0)}, 3000);
    // history.go(0);
  };

  const handleDeleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: `Are you sure to delete..?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZone(id, selectGeoType);
    SetDivision(divisionData.data);
    setValue("divisionData", "");
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivision(id, selectGeoType);
    setArea(areaData.data);
  };

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const [itemDetailShow, setItemDetailShow] = useState(false);
  const [itemID, setItemID] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const [paginateData, setPaginateData] = useState([]);


  const itemDetail = (item) => {
    setItemifo(item);
    setItemID(item.orderId);
    setItemDetailShow(true);
  };
  let ref = React.createRef();
  const inputField = React.useRef(null);

  const printFilter = (filter) => {
    inputField.current.value = filter;
  };
  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };
  const gotoEditPage = (item) => {
    dispatch(EditDateGet(item));
    history.push(`/remoteSales/edit/${item.orderId}/${item.custName}/${item.orderCode}`);
  }

  const getSalesValueType = (name, value) => {
    setSalesType(value);
  }

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };
  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
    // dispatch(getSOSkuProduct(startDate, input.value, selectZone, selectDivision, selectArea));
  };

  const showValidation = () => {
    if (startDate == null || startDate == "") {
      alert("Please Select From Date");
      return false;
    }
    else if (toDate == null || toDate == "") {
      alert("Please select To Date");
      return false;
    }
    else {
      return true;
    }
  }


  const handleListAction = (fromDate, tDate, idZone, idDivision, idArea, idTerritory) => {
    const validation = showValidation();
    if (validation) {
      dispatch(geSalesPersonTA(fromDate, tDate, idZone, idDivision, idArea, idTerritory));
    }
  }


  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const getTerritoryData = async (id) => {
    let territoryData = await getTerritory(id, selectGeoType);
    setTerritory(territoryData.data);
  }

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  // const hanleExcelClick = async () => {
  //   let excelClick = await getSowiseAchivementForExcel(
  //     startDate,
  //     toDate,
  //     selectZone,
  //     selectDivision,
  //     selectArea,
  //     selectTerritory
  //   );
  //   const current = currentdate();

  //   const worksheet = XLSX.utils.json_to_sheet(excelClick.data);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, 'Target Vs Achievement(SO Point Wise)' + " " + current + '.xlsx');

  // };


  const hanleExcelClick = async () => {
    let excelClick = await getSowiseAchivementForExcel(
      startDate,
      toDate,
      selectZone,
      selectDivision,
      selectArea,
      selectTerritory
    );
    const current = currentdate();
    let Heading = [[
      'ZoneID',
      'Zone',
      'DivisionID',
      'Division',
      'RegionID',
      'Region',
      'TerritoryId',
      'Territory',
      'SoPointId',
      'SoPoint',
      'ProdCatID',
      'ProdCatagories',
      'ProdID',
      'BrandName',
      'PackTypeName',
      'ProdCode',
      'ProdName',
      'TargetCtn',
      'ImsCtn',
      'DifferentCtn',
      'TargetValue',
      'ImsValue',
      'DifferentValue',
      'TarVsAch%',

    ]];

    //Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, Heading);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(worksheet, excelClick.data, { origin: 'A2', skipHeader: true });
    const check = XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
    XLSX.writeFile(wb, 'Target Vs Achievement(SO Point Wise)' + " " + current + '.xlsx');
  };


  return (
    <>
      <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="mt-2 p-2">
              <h3 className="mb-0 pb-0">Target Vs Achievement(Sales Person Wise)</h3>
            </div>
          </div>
          <div className="col-md-3">
            {/* <Button size="sm" onClick={() => hanleExcelClick()} >Download</Button> */}
            <DownloadDropdown excelname='Target Vs Achievement(Sales Person Wise)' />
          </div>

        </div>
        <hr></hr>
        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>

          <div className="col-md-3 mt-5 mb-5">
            <Button size="sm" onClick={() => handleListAction(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory)}>Show Report</Button>
          </div>
          {/* <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Distribution   Point</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  // setSelectDivision(option.value);
                  // dispatch(getDistributorList(option.value))
                  setSelectArea(option.value);
                  dispatch(getSalesList(option.value, salesType));
                  handleListAction(startDate, toDate, selectZone, selectDivision, option.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div> */}
        </div>
        <div>
          {productReport?.length > 0 &&

            <div className="container-fluid mb-3 mt-1 containerFixed" style={{ padding: "0 15px" }}>
              <div className="row" style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
                {/* Target Section */}
                <div className="col-xl-4 col-lg-4 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#f5e4e4", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
                  <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#007bff", fontSize: "14px", lineHeight: "10px" }}>TARGET</h5>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Biscuits Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.biscuitTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cookies Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.cookisTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cake Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.cakeTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Wafer Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.waferTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Snacks Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.snacksTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Chanachur Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.chanachurTargetPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Target Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {numberFormatDecemal((
                        parseFloat(totalTarget.biscuitTargetPrice) +
                        parseFloat(totalTarget.cookisTargetPrice) +
                        parseFloat(totalTarget.cakeTargetPrice) +
                        parseFloat(totalTarget.waferTargetPrice) +
                        parseFloat(totalTarget.snacksTargetPrice) +
                        parseFloat(totalTarget.chanachurTargetPrice)
                      ).toFixed(2))}
                    </span>
                  </div>
                </div>

                {/* IMS Section */}
                <div className="col-xl-4 col-lg-4 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#e9f7f7", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
                  <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#28a745", fontSize: "14px", lineHeight: "10px" }}>IMS</h5>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Biscuits IMS Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.biscuitLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cookies IMS Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.cookisLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cake IMS Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.cakeLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Wafer IMS Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.waferLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Snacks IMS Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.snacksLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Chanachur IMS Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{numberFormatDecemal(parseFloat(totalTarget.chanachurLiftingPrice).toFixed(2))}</span>
                  </div>
                  <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total IMS Value</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {numberFormatDecemal((
                        parseFloat(totalTarget.biscuitLiftingPrice) +
                        parseFloat(totalTarget.cookisLiftingPrice) +
                        parseFloat(totalTarget.cakeLiftingPrice) +
                        parseFloat(totalTarget.waferLiftingPrice) +
                        parseFloat(totalTarget.snacksLiftingPrice) +
                        parseFloat(totalTarget.chanachurLiftingPrice)
                      ).toFixed(2))}
                    </span>
                  </div>
                </div>

                {/* Achievement Section */}
                <div className="col-xl-4 col-lg-4 col-md-6" style={{ flex: "1", minWidth: "250px", backgroundColor: "#fff3cd", padding: "12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
                  <h5 style={{ textAlign: "center", fontWeight: "bold", color: "#ffc107", fontSize: "14px", lineHeight: "10px" }}>ACHIEVEMENT</h5>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Biscuits Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{parseFloat(((totalTarget.biscuitLiftingPrice) / (totalTarget.biscuitTargetPrice)) * 100).toFixed(2)}%</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cookies Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{parseFloat(((totalTarget.cookisLiftingPrice) / (totalTarget.cookisTargetPrice)) * 100).toFixed(2)}%</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Cake Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{parseFloat(((totalTarget.cakeLiftingPrice) / (totalTarget.cakeTargetPrice)) * 100).toFixed(2)}%</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Wafer Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>{parseFloat(((totalTarget.waferLiftingPrice) / (totalTarget.waferTargetPrice)) * 100).toFixed(2)}%</span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Snacks Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {(() => {
                        const percentage = parseFloat(((totalTarget.snacksLiftingPrice) / (totalTarget.snacksTargetPrice)) * 100);
                        return isNaN(percentage) ? 0 + "%" : `${parseFloat(percentage).toFixed(2)}%`;
                      })()}
                    </span>
                  </div>
                  <div style={{ marginBottom: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Chanachur Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {(() => {
                        const percentage = parseFloat(((totalTarget.chanachurLiftingPrice) / (totalTarget.chanachurTargetPrice)) * 100);
                        return isNaN(percentage) ? 0 + "%" : `${parseFloat(percentage).toFixed(2)}%`;
                      })()}
                    </span>
                  </div>
                  <div style={{ marginTop: "12px", borderTop: "1px solid #ddd", paddingTop: "8px", lineHeight: "10px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "13px" }}>Total Achievement</span>
                    <span style={{ float: "right", fontSize: "13px", color: '#050f26', fontWeight: '500' }}>
                      {(
                        (
                          parseFloat(totalTarget.biscuitLiftingPrice) +
                          parseFloat(totalTarget.cookisLiftingPrice) +
                          parseFloat(totalTarget.cakeLiftingPrice) +
                          parseFloat(totalTarget.waferLiftingPrice) +
                          parseFloat(totalTarget.snacksLiftingPrice) +
                          parseFloat(totalTarget.chanachurLiftingPrice)
                        ) /
                        (
                          parseFloat(totalTarget.biscuitTargetPrice) +
                          parseFloat(totalTarget.cookisTargetPrice) +
                          parseFloat(totalTarget.cakeTargetPrice) +
                          parseFloat(totalTarget.waferTargetPrice) +
                          parseFloat(totalTarget.snacksTargetPrice) +
                          parseFloat(totalTarget.chanachurTargetPrice)
                        ) * 100
                      ).toFixed(2)}%
                    </span>
                  </div>
                </div>

              </div>
            </div>

          }
          <div className="row" id="id" ref={inputField}>
            {/* <table className="table mt-5 voyageTable table-responsive"> */}
            {isProductLoading && <LoadingSpinner text="Loading Items List..." />}
            <div className="react-bootstrap-table table-responsive border-0 pl-5 ">

              <div
                className="stickyTable"
                style={{ height: productReport?.length > 0 ? 800 + "px" : 0 }}
              >
                {/* <table className="table table-head-custom table-vertical-center  item-add-table" id="table-to-xls" ref={componentRef}> */}
                <table className="table table-head-custom table-vertical-center  item-add-table borderTableims" id="table-to-xls" ref={componentRef}>
                  <thead>
                    <tr>
                      <th scope="col">ZONE</th>
                      <th scope="col">DIVISION</th>
                      <th scope="col">REGION</th>
                      <th scope="col">TERRITORY</th>
                      {/* <th scope="col">SO ID</th>
                      <th scope="col">SO NAME</th> */}
                      <th scope="col">SO POINT</th>
                      <th scope="col">PRODUCT CATEGORIES</th>
                      <th scope="col">PRODUCT ID</th>
                      <th scope="col">PRODUCT NAME</th>
                      <th scope="col">TARGET QUANTITY(CTN)</th>
                      <th scope="col">TARGET VALUE</th>
                      <th scope="col">IMS QTY</th>
                      <th scope="col">IMS VALUE</th>
                      <th scope="col">DIFFERECE QUANTITY</th>
                      <th scope="col">DIFFERECE VALUE</th>
                      <th scope="col">TARGET VS ACH(IN TK %)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productReport?.length > 0 &&
                      productReport?.map((item, index) => (
                        <>
                          <tr className="alert bg-secondary">
                            {/* <td>{item.orderId}</td> */}
                            <td>{item.zoneName}</td>
                            <td>{item.divisionName}</td>
                            <td>{item.areaName}</td>
                            <td>{item.territoryName}</td>
                            {/* <td>{item.soEnroll}</td>
                            <td>{item.soName}</td> */}
                            <td>{item.soPointName}</td>
                            <td>{item.prodCatagories}</td>
                            <td />
                            <td />
                            <td>{Math.round(item?.totalReport.targetQty)}</td>
                            <td>{Math.round(item?.totalReport.targetPrice)}</td>
                            <td>{Math.round(item?.totalReport.liftingQty)}</td>
                            <td>{Math.round(item?.totalReport.liftingPrice)}</td>
                            <td>{Math.round(item?.totalReport.differentQty)}</td>
                            <td>{Math.round(item?.totalReport.differentPrice)}</td>
                            <td>{Math.round(item?.totalReport.tarVsAccPers)}%</td>

                          </tr>
                          {
                            item?.itemReport.map((product, subindex) => (
                              <tr>
                                <td>{item.zoneName}</td>
                                <td>{item.divisionName}</td>
                                <td>{item.areaName}</td>
                                <td>{item.territoryName}</td>
                                {/* <td>{item.soEnroll}</td>
                                <td>{item.soName}</td> */}
                                <td>{item.soPointName}</td>
                                <td>{product.prodCatagories}</td>
                                <td>{product.prodID}</td>
                                <td>{product.prodName}</td>
                                <td>{Math.round(product.targetQty)}</td>
                                <td>{Math.round(product.targetPrice)}</td>
                                <td>{Math.round(product.liftingQty)}</td>
                                <td>{Math.round(product.liftingPrice)}</td>
                                <td>{Math.round(product.differentQty)}</td>
                                <td>{Math.round(product.differentPrice)}</td>
                                <td>{Math.round(product.tarVsAccPers)}%</td>
                              </tr>
                            ))
                          }
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
              {/* {!isProductLoading && productReport?productReport[0].allReport.length === 0 && (
              <div className="alert alert-warning mt-5">
                Sorry ! Sales List Not Found.
              </div>
            )} */}
            </div>
          </div>

        </div>



        <SimpleModal
          size="xl"
          show={itemDetailShow}
          handleClose={() => setItemDetailShow(false)}
          handleShow={() => setItemDetailShow(true)}
          modalTitle={"Item Details"}
        >
          <RemoteSalesDetails
            handleClose={() => setItemDetailShow(false)}
            itemID={itemID}
            itemDetailnIfo={itemifo}
          />
        </SimpleModal>
      </div>
    </>
  );
};

export default SalesPersonWiseTA;
