import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { Chatbot } from "../../../../modules/Sales/information/components/Dashboard/Chatbot";
import { ContentRoute } from "../../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Login from "./Login";

// AuthPage component
export function AuthPage() {
  useEffect(() => {
    const userData = localStorage.getItem("userData");

    if (typeof userData != "undefined" && userData != null && userData != "null" && userData != "") {
      const user = JSON.parse(userData);
      if (user.intEmployeeId != null) {
        window.location.href = "/dashboard";
      }
    }
  }, []);

  
  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}  >
      <div
        className="d-flex flex-column flex-root"
        style={{ flexGrow: 1 }}
      >
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid  order-xl-first order-lg-first order-sm-last "
          id="kt_login"
          style={{
            height: '100%', display: 'flex', flexDirection: 'column',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundColor: 'rgb(26, 32, 56)'

            // backgroundImage: `url(${toAbsoluteUrl("/media/bg/flower.avif")})`
          }}
        >
          <div className="mt-5 p-5" style={{ flexGrow: 1 }}>
            
            <div className="d-flex flex-column-fluid flex-center " style={{ flexGrow: 1 }}>
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
              </Switch>
            </div>

            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5 ">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 mt-20 my-2">
                &copy; 2025 Akij Insaf IT.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Bottom Navigation Bar */}
      <div
        style={{
          backgroundColor: '#f8f9fa',
          padding: '10px',
          borderTop: '1px solid #e7e7e7',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}

        className="BottomNavigationBar"
      >
        {/* Left side: Logo and Company Name */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <img
            src={toAbsoluteUrl("/media/logos/Insaf.png")}
            alt="Akij Insaf Ltd"
            style={{ height: '40px', marginRight: '10px' }}
          />|
          <img
            src={toAbsoluteUrl("/media/logos/fantastic-biscuit.png")}
            alt="Funtastic Biscuit"
            style={{ height: '40px', marginRight: '10px' }}
          />|
          <img
            src={toAbsoluteUrl("/media/logos/fantasticcake.png")}
            alt="Funtastic Cake"
            style={{ height: '40px', marginRight: '10px' }}
          />|
          <img
            src={toAbsoluteUrl("/media/logos/bakemans.png")}
            alt="Bakeman's"
            style={{ height: '40px', marginRight: '10px' }}
          />|
          
          <img
            src={toAbsoluteUrl("/media/logos/yum.png")}
            alt="Yum"
            style={{ height: '40px', marginRight: '10px' }}
          />|
          <img
            src={toAbsoluteUrl("/media/logos/fast.png")}
            alt="Fast"
            style={{ height: '40px', marginRight: '10px' }}
          />|
          <img
            src={toAbsoluteUrl("/media/logos/hi5.png")}
            alt="hi5"
            style={{ height: '40px', marginRight: '10px' }}
          /> */}
          {/* <span style={{ fontWeight: 'bold' }}>Akij Insaf Ltd.</span> */}
        </div>
        {/* <Chatbot /> */}

        {/* Right side: Some Information */}
        <div>
          &copy; 2025 Akij Insaf IT.
        </div>
        


      </div>
    </div>
  );
}
