import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FaTable, FaChartBar } from 'react-icons/fa';
import { GetImsMenuTrackingSystem } from '../../../modules/Sales/information/_redux/actions/SecondarySales/SecondaryAction';

const AdminWelcome = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        getLocation();
    }, []);

    const getLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&addressdetails=1`;

                fetch(url)
                    .then(response => {
                        if (!response.ok) {
                            dispatch(GetImsMenuTrackingSystem("Welcome Dashboard", "Login", "mac", "0", "0", "0", "Location Not Allowed"));
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        console.log('Location Data:', data?.display_name);
                        dispatch(GetImsMenuTrackingSystem("Welcome Dashboard", "Login", "mac", "0", latitude, longitude, data?.display_name));
                    })
                    .catch(error => {
                        console.error('There was a problem with the fetch operation:', error);
                    });
            }, (error) => {
                console.error("Error getting geolocation:", error);
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    };

    const backgroundStyle = {
        backgroundImage: "url('/media/bg/sales.avif')",
        backgroundSize: "cover", 
        backgroundPosition: "center", 
        backgroundRepeat: "no-repeat", 
        minHeight: "87vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", 
        alignItems: "center",
        textAlign: "center",
        color: "#FFD700",
        fontFamily: "Arial, sans-serif",
    };

    const headingStyle = {
        fontSize: "3.0rem", 
        fontWeight: "700",
        marginBottom: "10px",
        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    };

    const subheadingStyle = {
        fontSize: "1.9rem",
        fontWeight: "400",
        marginBottom: "30px", // Space below subheading
        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    };

    const buttonContainerStyle = {
        width: "100%", // Ensure container takes full width for centering
        display: "flex", // Use flexbox to center
        justifyContent: "center", // Horizontally center the button
        alignItems: "center", // Vertically center the button
    };

    const buttonStyle = {
        width: "100%", // Set button width relative to its container
        maxWidth: "400px", // Limit max width for large screens
        padding: "15px", // Larger padding for better clickability
        fontSize: "1.2rem", // Larger text for visibility
        backgroundColor: "#28a745", // Green button color
        border: "none", // Remove border for a clean look
        borderRadius: "5px", // Slightly rounded corners
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow effect
        transition: "all 0.3s ease", // Smooth transition for hover effect
    };

    const buttonHoverStyle = {
        ...buttonStyle,
        backgroundColor: "#218838", // Darker green when hovered
        cursor: "pointer", // Indicating that it's clickable
    };

    return (
        <div style={backgroundStyle}>
            <div>
                <h1 style={headingStyle}>Welcome to the ABL Sales Dashboard</h1>
                <p style={subheadingStyle}>Get real-time insights and analytics.</p>
                <div style={buttonContainerStyle}>
                    <Link to="/SPerformanceDashboard">
                        <Button
                            size="lg"
                            className="btn btn-success"
                            style={buttonStyle}
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                        >
                            <FaChartBar style={{ marginRight: '10px' }} />
                            View Dashboard
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default AdminWelcome;
