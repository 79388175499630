import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { GetAllPendingProductList } from "../../_redux/actions/RemoteSales/RemoteSalesAction";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import NewLoadind from "../../../../master/spinner/NewLoadind";

const RemoteSalesAddPendingAllListShow = ({ itemDetailnIfo1 }) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();

  const productList = useSelector((state) => state.remoteSales.allPendingList);
  const isLoading = useSelector((state) => state.remoteSales.allPendingListLoading);

  console.log('productList :>> ', productList);



  useEffect(() => {

    dispatch(GetAllPendingProductList(itemDetailnIfo1));

  }, []);


  return (
    <>

      <div className="card card-custom gutter-b pl-5 pr-5 card-top-border p-1 ">

        <div className="row">
          <div className="col-md-9">
            <div className="mt-2 p-2">

              <h3 className="mb-0 pb-0">Distributor Pending Product List</h3>
            </div>
          </div>



        </div>

        <hr></hr>


        <div >
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div >
                <span ><NewLoadind /></span>
              </div>
            </div>
          ) : productList.length > 0 ? (
            <div className="react-bootstrap-table table-responsive">
              <table className="table table-head-custom table-vertical-center">
                <thead>
                  <tr>
                    <th className="bg-secondary">SL</th>
                    {/* <th className="bg-secondary">Distributor ID</th>
                    <th className="bg-secondary">Distributor Name</th> */}
                    <th className="bg-secondary">Item ID</th>
                    <th className="bg-secondary">Item Name</th>
                    <th className="bg-secondary">Pending Qty</th>
                    <th className="bg-secondary">Pending Value</th>
                  </tr>
                </thead>
                <tbody>
                  {productList.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      {/* <td>{item.distributorID}</td>
                      <td>{item.distributorName}</td> */}
                      <td>{item.productId}</td>
                      <td>{item.productName}</td>
                      <td className="text-right">{numberFormatDecemal(item.pendingQty)}</td>
                      <td className="text-right">{numberFormatDecemal(item.pendingValue)}</td>
                    </tr>
                  ))}

                  <tr>
                    <td colSpan="3" className="text-right font-weight-bold">Total</td>
                    <td className="font-weight-bold text-right">
                      {numberFormatDecemal(productList.reduce((acc, item) => acc + item.pendingQty, 0))}
                    </td>
                    <td className="font-weight-bold text-right">
                      {numberFormatDecemal(parseFloat(productList.reduce((acc, item) => acc + item.pendingValue, 0)).toFixed(2))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center mt-5">
              <p>No data available</p>
            </div>
          )}
        </div>


      </div>





    </>
  );
};

export default RemoteSalesAddPendingAllListShow;
