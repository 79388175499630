import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getSalesApprovedDetails
} from "../../../_redux/actions/RemoteSales/RemoteSalesAction";
import ReactToPrint from "react-to-print";
import { Dropdown } from "react-bootstrap";
import { numberFormatDecemal, setDateFromOldDate } from "../../../../../../domains/CCO/utils/DateHelper";

const SalesApprovedReportDetailsForFeild = ({
  handleClose,
  itemID,
  itemDetailnIfo,
}) => {
  const ItemDetails = useSelector((state) => state.remoteSales.salesApprovedDetails);
  console.log('ItemDetails :>> ', ItemDetails);

  const ref = React.createRef();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSalesApprovedDetails(itemID));
  }, []);

  return (
    <>
      {ItemDetails && (
        <div className="container">
          <div className="col-md-12">
            <div className="invoice">
              <div className="export text-right">
                <Dropdown className="d-inline">
                  <Dropdown.Toggle
                    className="btn btn-sm"
                    variant="light text-primary"
                    id="dropdown-basic"
                  >
                    Export
                  </Dropdown.Toggle>

                  <Dropdown.Menu>

                    <ReactToPrint
                      trigger={() => (
                        <Dropdown.Item href="#/action-1" onClick={window.print}>
                          <i class="fas fa-print"></i>
                          <span className="ml-2">Print</span>
                        </Dropdown.Item>
                      )}
                      content={() => ref.current}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div ref={ref} id="printDiv">

                <div>
                  <div className="invoice-to">
                    <address className="m-t-5 m-b-5">
                      <strong className="text-inverse">
                        {itemDetailnIfo?.distName}
                      </strong>
                      <br />
                      Invoice Code : <strong>{itemDetailnIfo?.invCode}</strong>
                      <br />
                      Date: {setDateFromOldDate(itemDetailnIfo?.invDate)}
                      <br />

                    </address>
                  </div>
                  <div className="invoice-date">
                    <div className="invoice-detail">
                      Invoice No:
                      {itemDetailnIfo?.invCode}
                      Date: {setDateFromOldDate(itemDetailnIfo.invDate)}
                    </div>
                  </div>

                  <div className="invoice-content item-add-table">
                    <table className="table table-responsive">
                      <table
                        class="table table-bordered bg-warning-o-10"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th>Item Id</th>
                            <th>Item Name</th>
                            <th>Order Quantity</th>
                            <th>Order Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ItemDetails.map((item) => (
                            <tr key={item.itemID}>
                              <td style={{ width: "10%" }}>{item.itemID}</td>
                              <td className="text-left" style={{ width: "60%" }}>
                                {item.itemName}
                              </td>
                              <td className="text-left" style={{ width: "30%" }}>
                                {numberFormatDecemal(item.orderQty)}
                              </td>
                              <td className="text-left" style={{ width: "30%" }}>
                                {numberFormatDecemal(item.orderValue)}
                              </td>
                            </tr>
                          ))}

                          {/* Calculate totals */}
                          <tr>
                            <td colSpan="2" style={{ textAlign: "right" }}>
                              <strong>Total</strong>
                            </td>
                            <td className="text-left">
                              {/* Total Quantity */}
                              <strong>{numberFormatDecemal(parseFloat((ItemDetails.reduce((total, item) => total + item.orderQty, 0))).toFixed(2))}</strong>

                            </td>
                            <td className="text-left">
                              {/* Total Value */}
                              <strong>{numberFormatDecemal(parseFloat(ItemDetails.reduce((total, item) => total + item.orderValue, 0)).toFixed(2))}</strong>

                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </table>


                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      )}
      <Button
        variant="secondary"
        classNameNameName="float-right"
        onClick={handleClose}
      >
        Close
      </Button>
    </>
  );
};

export default SalesApprovedReportDetailsForFeild;
