import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from "./_metronic/layout";
import { MetronicI18nProvider } from "./_metronic/i18n";

// Importing BrowserRouter
import { BrowserRouter } from 'react-router-dom'; // <-- Import BrowserRouter

import "./_metronic/_assets/css/default-style.css";
import "./_metronic/_assets/css/responsive.css";
import "./_metronic/_assets/css/default-style-2.css";
import "./styles/global-style.css";

// Base URL of the website
const { PUBLIC_URL } = process.env;

// Import axios.js so that it can inject token in every request
require('./services/axios');

// Wrap App with BrowserRouter
ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          {/* Wrap App with BrowserRouter */}
          <BrowserRouter basename={PUBLIC_URL}>  {/* <-- Here */}
            <App store={store} persistor={persistor} basename={PUBLIC_URL} />
          </BrowserRouter>
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);
