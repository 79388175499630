import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, Button, Col } from "react-bootstrap";
import { GetMonthWiseSoImsSKUReportValue } from "../../_redux/actions/SecondarySales/SecondaryCumaltiveAction";
import { numberFormatDecemal } from "../../../../../domains/CCO/utils/DateHelper";
import * as XLSX from "xlsx";
import { FaFileExcel, FaSearch } from "react-icons/fa";
import { GetPermissionDropDown } from "../Dashboard/DashboardCartAction";
import { getAreaListByDivisionEntry, getDivisionListByZoneEntry, getTerritoryEntry, getZoneList } from "../../_redux/actions/RemoteSales/DistributorReportAction";
import { useRef } from "react";
import Select from "react-select";
import DownloadDropdown from "../../Master/DownloadDropdown";

const PAGE_SIZE = 20;

const MonthWiseSoImsSKUReportValueV2 = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.secondarySales.MonthWiseSoSalesLoading);
  const DateWiseSoSales = useSelector((state) => state.secondarySales.MonthWiseSoSales);
  console.log('DateWiseSoSales', DateWiseSoSales)

  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);

  // useEffect(() => {
  //   if (DateWiseSoSales.length > 0) {
  //     const startIndex = (currentPage - 1) * PAGE_SIZE;
  //     const currentData = DateWiseSoSales.slice(startIndex, startIndex + PAGE_SIZE);
  //     setPaginatedData(currentData);
  //   }
  // }, [DateWiseSoSales, currentPage]);

  const handlePageChange = (page) => {
    if (page < 1 || page > Math.ceil(DateWiseSoSales.length / PAGE_SIZE)) return;
    setCurrentPage(page);
  };

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(0);
  const [area, setArea] = useState([]);

  const [selectDivision, setSelectDivision] = useState(null);
  const [selectArea, setSelectArea] = useState(null);
  const [salesType, setSalesType] = useState(0);
  const [distribType, setDistribType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [defultZone, setDefaultZone] = useState({});

  const [defultDivision, setdefultDivision] = useState({});
  const [defaultArea, setdefaultArea] = useState({});
  const [defultTerritory, setdefultTerritory] = useState({});

  const componentRef = useRef();

  const { register, handleSubmit, errors, setValue, setFocus } = useForm();


  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);
    setSalesType(user?.employeeType);
    setDistribType(user?.employeeType);
    initialData();
  }, []);

  const initialData = async () => {
    var permissionwise = await GetPermissionDropDown()
      .then((res) => {
        console.log('res data', res);
        const { zoneId, zoneName, divisionId, divisionName, araId, areaName, territoryId, territoryName, selectGeoType } = res.data;

        setDefaultZone({ label: zoneName, value: zoneId });
        setdefultDivision({ label: divisionName, value: divisionId });
        setdefaultArea({ label: areaName, value: araId });
        setdefultTerritory({ label: territoryName, value: territoryId });

        setSelectZone(zoneId);
        setSelectDivision(divisionId);
        setSelectArea(araId);
        setSelectTerritory(territoryId);

        divisionList(zoneId);
        getAreaData(divisionId);
        getTerritoryData(araId);

      });
    let zoneData = await getZoneList();
    Setzone(zoneData.data);
  }


  const zoneDropdownData = () => {

    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }

    return options;
  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData?.data);
  }
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData?.data);
  }
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData?.data);
  }

  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }

    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const handleChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const handleChangeTodate = (input) => {
    setToDate(input);
  };
  const handleDateFilter = (option) => {
    handleChangeTodate(option);
  }

  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(null);

  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const showReport = () => {
    dispatch(GetMonthWiseSoImsSKUReportValue(startDate, toDate, selectZone, selectDivision, selectArea, selectTerritory, selectGeoType));
  }

  const getSortedMonths = () => {
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const monthOrder = monthNames.reduce((acc, month, index) => {
      acc[month] = index + 1; // Map month name to its chronological order (1 to 12)
      return acc;
    }, {});

    // Get the months from DateWiseSoSales and sort them
    const allMonths = Object.keys(DateWiseSoSales[0]?.monthlySales || {}).map(salesDate => salesDate);

    return Array.from(new Set(allMonths)) // Remove duplicates
      .sort((a, b) => {
        const [monthA, yearA] = a.split('-');
        const [monthB, yearB] = b.split('-');

        // Compare by year first, then by month
        if (yearA !== yearB) return yearA - yearB;
        return monthOrder[monthA] - monthOrder[monthB];  // Sort by month order
      });
  };

  const renderTable = () => {
    const sortedMonths = getSortedMonths();

    return (
      <div className="report-section">
        <div className="report-table-wrapper">
          <table className="report-table" id="table-to-xls">
            {DateWiseSoSales?.length > 0 && (
              <thead>
                <tr>
                  {/* Table headers */}
                  <th scope="col" className="report-table-header">Zone</th>
                  <th scope="col" className="report-table-header">Division</th>
                  <th scope="col" className="report-table-header">Region</th>
                  <th scope="col" className="report-table-header">Territory</th>
                  <th scope="col" className="report-table-header">SO Enroll</th>
                  <th scope="col" className="report-table-header">SO Name</th>
                  <th scope="col" className="report-table-header">SO Point ID</th>
                  <th scope="col" className="report-table-header">SO Point Name</th>

                  {/* Render months dynamically, sorted */}
                  {sortedMonths.map((month, idx) => (
                    <React.Fragment key={`fragment-${month}-${idx}`}>
                      {/* <th scope="col" className="report-table-header">{month} - CTN</th> */}
                      <th scope="col" className="report-table-header">{month} - Amount</th>
                    </React.Fragment>
                  ))}

                  {/* Add Total header */}
                  {/* <th scope="col" className="report-table-header">Total - CTN</th> */}
                  <th scope="col" className="report-table-header">Total - Amount</th>
                </tr>
              </thead>
            )}

            <tbody>
              { DateWiseSoSales?.length > 0 && DateWiseSoSales.map((item, index) => {
                // Calculate the totals for CTN and Amount
                // let totalCTN = 0;
                let totalAmount = 0;

                // Calculate total CTN and total amount for this row
                sortedMonths.forEach(monthYear => {
                  const salesData = item?.monthlySales[monthYear] || {};  // Use the monthYear directly
                  // const ctn = parseInt(salesData?.totalSalesCTN || 0, 10);  // Ensure it's a valid number
                  const amount = parseFloat(salesData?.totalSalesAmount || 0).toFixed(2);  // Ensure it's a valid number and format it

                  // totalCTN += ctn;
                  totalAmount += parseFloat(amount);  // Add the formatted value to totalAmount
                });

                return (
                  <tr key={index}>
                    {/* Table rows for each item */}
                    <td className="report-table-cell">{item.zoneName}</td>
                    <td className="report-table-cell">{item.divisionName}</td>
                    <td className="report-table-cell">{item.areaName}</td>
                    <td className="report-table-cell">{item.territoryName}</td>
                    <td className="report-table-cell">{item.soId}</td>
                    <td className="report-table-cell">{item.soName || "Vacant"}</td>
                    <td className="report-table-cell">{item.delPointID}</td>
                    <td className="report-table-cell">{item.delPointName}</td>

                    {/* Render monthly sales data */}
                    {sortedMonths.map((month, idx) => (
                      <React.Fragment key={`sales-${month}-${idx}`}>
                        {/* <td className="report-table-cell">
                          {numberFormatDecemal(parseInt(item?.monthlySales[month]?.totalSalesCTN || 0))}
                        </td> */}
                        <td className="report-table-cell">
                          {(parseFloat(item?.monthlySales[month]?.totalSalesAmount || 0).toFixed(2))}
                        </td>
                      </React.Fragment>
                    ))}

                    {/* Render Total row */}
                    {/* <td className="report-table-cell">{numberFormatDecemal(totalCTN)}</td> */}
                    <td className="report-table-cell">{parseFloat(totalAmount.toFixed(2))}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };


  return (
    <div className="card card-custom gutter-b pl-5 pr-5 mb-5 card-top-border mb-5">
      <div className="row mt-5">
        <div className="col-lg-9">
          <div className="mt-2 p-2">
            <span className="mb-0 pb-0 pageheadingText">SO MONTH WISE IMS REPORT (VALUE)</span>
          </div>

        </div>
  

        <div className="col-md-3">
            <DownloadDropdown excelname='SO MONTH WISE IMS REPORT (VALUE)' />
          </div>



        <div className="form-group row">
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">From Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="fromStyle"
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">To Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                className="fromStyle"
                onChange={(event) => {
                  handleDateFilter(event.target.value)


                }}

              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Zone</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={zoneDropdownData()}
                value={defultZone}
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setDefaultZone(option);
                  setdefultDivision(null);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");

                }}
                defaultValue={defultZone}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Division</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={divisionDropdownData()}
                value={defultDivision}
                onChange={(option) => {
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setdefultDivision(option);
                  setdefaultArea(null);
                  setdefultTerritory(null);
                  setValue("areaData", "");
                  setValue("territory", "");
                }}
                defaultValue={defultDivision}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Region</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={areaDropdownData()}
                value={defaultArea}
                onChange={(option) => {
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  setdefaultArea(option);
                  setdefultTerritory(null);
                  setValue("territory", "");
                }}
                defaultValue={defaultArea}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mt-0">
            <label className="form-label ml-4">Select Territory</label>
            <Form.Group as={Col} controlId="formGridState">
              <Select
                options={territoryDropdownData()}
                value={defultTerritory}
                onChange={(option) => {

                  setSelectTerritory(option?.value);
                  setdefultTerritory(option)
                }}
                defaultValue={defultTerritory}
              />
            </Form.Group>
          </div>


          <div className=" col-lg-3 mt-4 ml-5">
            <Button
              size="sm"
              onClick={() => showReport()}
            >
              <FaSearch className="mr-1" /> Show Report
            </Button>
          </div>

        </div>

      </div>

      {/* {DateWiseSoSales.length > 0 && (
        <div className="col mt-5 ml-5 text-right">
          <Button size="sm" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            Show Previous Page
          </Button>
          <Button size="sm" onClick={() => handlePageChange(currentPage + 1)} className="ml-2" disabled={currentPage === Math.ceil(DateWiseSoSales.length / PAGE_SIZE)}>
            Show Next Page
          </Button>
        </div>
      )} */}

      <div className="row">

        {!isLoading && DateWiseSoSales?.length === 0 && (
          <div className="alert alert-warning mt-3">
            Sorry! Sales List Not Found.
          </div>
        )}{
          DateWiseSoSales.length > 0 &&
          <span><i>{DateWiseSoSales && (numberFormatDecemal(DateWiseSoSales?.length) + ' Records Found')}</i></span>
        }

        {DateWiseSoSales && renderTable()}
        {isLoading && <span className="text-center text-black p-3">Loading.......</span>}
      </div>
    </div>
  );
};

export default MonthWiseSoImsSKUReportValueV2;
